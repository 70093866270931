// @file Add a global reference to the tldraw editor object.
import window from '@@/bits/global'
import type { Editor } from 'tldraw'
function setEditorObject(editor: Editor): void {
  window.$tldrawEditor = editor
}

function getEditorObject(): Editor | null {
  return window.$tldrawEditor ?? null
}

export { getEditorObject, setEditorObject }
