/**
 * @file Helper for downloading files.
 */

/**
 * Triggers a download with a specified url.
 * This might be a better alternative than using window.open(url) to start a download
 * for the user, as pop-up blockers might block such windows from opening.
 * @param url - The url of the resource to be downloaded.
 */
const triggerDownload = (url: string): void => {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export { triggerDownload }
