// @file helper functions related to posts/wishes

import { getHyphenatedCurrentLocale } from '@@/bits/current_locale'
import { __ } from '@@/bits/intl'
import type { AttachmentProps } from '@@/types'
import type { Tweet, TweetDisplayAttributes } from '@@/types/surface'

export function isTweet(attachmentProps: AttachmentProps | null | undefined): boolean {
  return attachmentProps?.type?.toLowerCase() === 'tweet'
}

export function getTweet(attachmentProps?: AttachmentProps): Tweet {
  return {
    canDisplay: attachmentProps?.can_display ?? true,
    text: attachmentProps?.text,
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    author: attachmentProps?.author?.name || attachmentProps?.author?.username,
    createdAt: attachmentProps?.created_at,
    attachments: attachmentProps?.attachments,
    entities: attachmentProps?.entities,
  }
}

export function getTweetDisplayAttributes(
  tweet: Tweet,
  options: { monthFormat: 'short' | 'long' } = { monthFormat: 'long' },
): TweetDisplayAttributes {
  const canDisplay = tweet.canDisplay
  if (!canDisplay) {
    return {
      text: __('Tweet cannot be displayed'),
      author: tweet.author,
    }
  }

  const date = new Date(tweet.createdAt)
  const createdAtDateString = date.toLocaleString(getHyphenatedCurrentLocale(), {
    year: 'numeric',
    month: options.monthFormat,
    day: 'numeric',
  })
  return {
    text: tweet.text,
    author: tweet.author,
    createdAtDateString,
  }
}

export type PostInteraction = 'expand' | 'link' | 'download' | 'padlet' | 'play'
