// @file Surface remake link store

import { trackEvent } from '@@/bits/analytics'
import { CONFIRM_TURN_OFF } from '@@/bits/confirmation_dialog'
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import PromiseQueue from '@@/bits/promise_queue'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import PadletApi from '@@/surface/padlet_api'
import type { WallCamelCase, WallRemakeLink } from '@@/types'

import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum RemakeLinkPanelStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Error = 'Error',
}

export enum RemakeLinkCopyPostsOptions {
  All = 'all',
  None = 'none',
  AdminOnly = 'admin_only',
}

export const useSurfaceRemakeLinkStore = defineStore('surfaceRemakeLinkStore', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()
  const surfaceStore = useSurfaceStore()
  const remakeLinkStatus = ref(RemakeLinkPanelStatus.Completed)

  const isRemakeLinkLoading = computed(() => remakeLinkStatus.value === RemakeLinkPanelStatus.Loading)

  // Remake link attributes
  const remakeLinkUrl = ref<string | null>(null)
  const remakeLinkCopyPosts = ref<string | null>(null)
  const remakeLinkCopyPostAuthorship = ref<boolean | null>(null)
  const remakeLinkCopyWallpaper = ref<boolean | null>(null)
  const remakeLinkAddAdmins = ref<boolean | null>(null)
  const remakeLinkLimitOneRemakePerUser = ref<boolean | null>(null)

  const isRemakeLinkEnabled = computed(
    () =>
      remakeLinkCopyPosts.value !== null &&
      remakeLinkCopyPostAuthorship.value !== null &&
      remakeLinkAddAdmins.value !== null &&
      remakeLinkCopyWallpaper.value !== null &&
      remakeLinkLimitOneRemakePerUser.value !== null,
  )

  // Walls remade
  const wallsRemade = ref<WallCamelCase[]>([])
  const wallsRemadeStatus = ref(RemakeLinkPanelStatus.Completed)
  const isWallsRemadeLoading = computed(() => wallsRemadeStatus.value === RemakeLinkPanelStatus.Loading)

  const hasWallsRemade = computed(() => wallsRemade.value.length > 0)

  const queue = new PromiseQueue()

  async function fetchRemakeLink(): Promise<void> {
    try {
      const attributes = await PadletApi.RemakeLink.fetch(surfaceStore.wallId)
      // If we fetch undefined, it means remake link is not enabled
      if (attributes !== undefined) {
        setRemakeLinkAttributes(attributes)
        // Fetch walls remade only if remake link is enabled
        await fetchWallsRemade()
      } else {
        resetRemakeLinkAttributes()
      }
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelRemakeLinkFetch' })
    }
  }

  async function fetchWallsRemade(): Promise<void> {
    wallsRemadeStatus.value = RemakeLinkPanelStatus.Loading
    try {
      const wallsRemadeData = await PadletApi.RemakeLink.fetchWallsRemade(surfaceStore.wallId)
      wallsRemade.value = wallsRemadeData as WallCamelCase[]
      wallsRemadeStatus.value = RemakeLinkPanelStatus.Completed
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelRemakeLinkFetchWallsRemade' })
      wallsRemadeStatus.value = RemakeLinkPanelStatus.Error
    }
  }

  async function updateRemakeLink(changedProps: Partial<WallRemakeLink> = {}): Promise<void> {
    // We can only update remake link if it is enabled
    if (!isRemakeLinkEnabled.value) return
    try {
      await PadletApi.RemakeLink.update(surfaceStore.wallId, changedProps)
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelRemakeLinkUpdate' })
      throw e
    }
  }

  async function updateCopyPostsProp(value: RemakeLinkCopyPostsOptions): Promise<void> {
    const oldValue = remakeLinkCopyPosts.value
    // Return if value is the same
    if (oldValue === value) return
    // Return if invalid value
    if (!Object.values(RemakeLinkCopyPostsOptions).includes(value)) return
    remakeLinkCopyPosts.value = value
    if (value === RemakeLinkCopyPostsOptions.None) {
      remakeLinkCopyPostAuthorship.value = false
    }
    void queue.enqueue('updateRemakeLinkProps', async () => {
      try {
        await updateRemakeLink({ copyPosts: value })
        trackEvent('AutoRemakeLink', 'Update Copy Posts', surfaceStore.wallId, value, { wall_id: surfaceStore.wallId })
      } catch (e) {
        remakeLinkCopyPosts.value = oldValue
      }
    })
  }

  async function toggleCopyPostsAuthorship(): Promise<void> {
    if (remakeLinkCopyPostAuthorship.value === null) return
    const oldValue = remakeLinkCopyPostAuthorship.value
    remakeLinkCopyPostAuthorship.value = !oldValue
    void queue.enqueue('updateRemakeLinkProps', async () => {
      try {
        await updateRemakeLink({ useOriginalPostsAuthorship: remakeLinkCopyPostAuthorship.value as boolean })
        trackEvent(
          'AutoRemakeLink',
          'Toggle Copy Posts Authorship',
          surfaceStore.wallId,
          remakeLinkCopyPostAuthorship.value,
          { wall_id: surfaceStore.wallId },
        )
      } catch (e) {
        remakeLinkCopyPostAuthorship.value = oldValue
      }
    })
  }

  async function toggleCopyWallpaper(): Promise<void> {
    if (remakeLinkCopyWallpaper.value === null) return
    const oldValue = remakeLinkCopyWallpaper.value
    remakeLinkCopyWallpaper.value = !oldValue
    void queue.enqueue('updateRemakeLinkProps', async () => {
      try {
        await updateRemakeLink({ useOriginalWallpaper: remakeLinkCopyWallpaper.value as boolean })
        trackEvent('AutoRemakeLink', 'Toggle Copy Wallpaper', surfaceStore.wallId, remakeLinkCopyWallpaper.value, {
          wall_id: surfaceStore.wallId,
        })
      } catch (e) {
        remakeLinkCopyWallpaper.value = oldValue
      }
    })
  }

  async function toggleAddAdminsAsCollaborator(): Promise<void> {
    if (remakeLinkAddAdmins.value === null) return
    const oldValue = remakeLinkAddAdmins.value
    remakeLinkAddAdmins.value = !oldValue
    void queue.enqueue('updateRemakeLinkProps', async () => {
      try {
        await updateRemakeLink({ addAdminsAsCollaborator: remakeLinkAddAdmins.value as boolean })
        trackEvent(
          'AutoRemakeLink',
          'Toggle Add Admins As Collaborator',
          surfaceStore.wallId,
          remakeLinkAddAdmins.value,
          { wall_id: surfaceStore.wallId },
        )
      } catch (e) {
        remakeLinkAddAdmins.value = oldValue
      }
    })
  }

  async function toggleLimitOneRemakePerUser(): Promise<void> {
    if (remakeLinkLimitOneRemakePerUser.value === null) return
    const oldValue = remakeLinkLimitOneRemakePerUser.value
    remakeLinkLimitOneRemakePerUser.value = !oldValue
    void queue.enqueue('updateRemakeLinkProps', async () => {
      try {
        await updateRemakeLink({ limitToOneRemakePerUser: remakeLinkLimitOneRemakePerUser.value as boolean })
        trackEvent(
          'AutoRemakeLink',
          'Toggle Limit One Remake Per User',
          surfaceStore.wallId,
          remakeLinkLimitOneRemakePerUser.value,
          { wall_id: surfaceStore.wallId },
        )
      } catch (e) {
        remakeLinkLimitOneRemakePerUser.value = oldValue
      }
    })
  }

  async function toggleRemakeLink(): Promise<void> {
    try {
      // Don't allow changes if mid-change
      if (isRemakeLinkLoading.value) return
      if (isRemakeLinkEnabled.value) {
        await askToDisableRemakeLink()
      } else {
        // Only show loading state if toggling to True
        remakeLinkStatus.value = RemakeLinkPanelStatus.Loading
        await enableRemakeLink()
        remakeLinkStatus.value = RemakeLinkPanelStatus.Completed
      }
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelRemakeLinkToggle' })
      remakeLinkStatus.value = RemakeLinkPanelStatus.Error
    }
  }

  function askToDisableRemakeLink(): void {
    void globalConfirmationDialogStore.openConfirmationDialog({
      ...CONFIRM_TURN_OFF,
      title: __('Turn off auto-remake link?'),
      body: __('Current link will no longer work. Turning link back on will create a new link.'),
      afterConfirmActions: [disableRemakeLink],
    })
  }

  function setRemakeLinkAttributes(attributes: WallRemakeLink): void {
    remakeLinkUrl.value = attributes.link
    remakeLinkCopyPosts.value = attributes.copyPosts
    remakeLinkCopyPostAuthorship.value = attributes.useOriginalPostsAuthorship
    remakeLinkCopyWallpaper.value = attributes.useOriginalWallpaper
    remakeLinkAddAdmins.value = attributes.addAdminsAsCollaborator
    remakeLinkLimitOneRemakePerUser.value = attributes.limitToOneRemakePerUser
  }

  function resetRemakeLinkAttributes(): void {
    remakeLinkUrl.value = null
    remakeLinkCopyPosts.value = null
    remakeLinkCopyPostAuthorship.value = null
    remakeLinkCopyWallpaper.value = null
    remakeLinkAddAdmins.value = null
    remakeLinkLimitOneRemakePerUser.value = null
  }

  async function enableRemakeLink(): Promise<void> {
    const linkAttributes = await PadletApi.RemakeLink.enable(surfaceStore.wallId)
    setRemakeLinkAttributes(linkAttributes)
    trackEvent('AutoRemakeLink', 'Enable Remake Link', surfaceStore.wallId, remakeLinkUrl.value, {
      wall_id: surfaceStore.wallId,
    })
  }

  async function disableRemakeLink(): Promise<void> {
    await PadletApi.RemakeLink.disable(surfaceStore.wallId)
    resetRemakeLinkAttributes()
    wallsRemade.value = []
    trackEvent('AutoRemakeLink', 'Disable Remake Link', surfaceStore.wallId, '', { wall_id: surfaceStore.wallId })
  }

  return {
    isRemakeLinkEnabled,
    isRemakeLinkLoading,

    remakeLinkUrl,
    remakeLinkCopyPosts,
    remakeLinkCopyPostAuthorship,
    remakeLinkCopyWallpaper,
    remakeLinkAddAdmins,
    remakeLinkLimitOneRemakePerUser,

    fetchRemakeLink,
    toggleRemakeLink,
    updateRemakeLink,

    updateCopyPostsProp,
    toggleCopyPostsAuthorship,
    toggleCopyWallpaper,
    toggleAddAdminsAsCollaborator,
    toggleLimitOneRemakePerUser,

    isWallsRemadeLoading,
    hasWallsRemade,
    wallsRemade,
  }
})
