// @file Surface share panel store
import device from '@@/bits/device'
import { triggerDownload } from '@@/bits/download'
import { ROCKET_EMOJI, WAVING_HAND_EMOJI } from '@@/bits/emoji'
import { captureException, captureFetchException, captureMessage } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import type { UrlOptions } from '@@/bits/location'
import { buildUrlFromPath } from '@@/bits/location'
import { getVuexStore } from '@@/bits/pinia'
import PromiseQueue from '@@/bits/promise_queue'
import { isRegistered } from '@@/bits/user_model'
import {
  ApiErrorCode,
  HttpCode,
  SnackbarNotificationType,
  WallAccessPrivacyType,
  WallAccessRole,
  WallCollaboratorStatus,
} from '@@/enums'
import { OzConfirmationDialogBoxButtonScheme } from '@@/library/v4/components/OzConfirmationDialogBox.vue'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useNativeAppStore } from '@@/pinia/native_app'
import { useScreenReaderNotificationsStore } from '@@/pinia/screen_reader_notifications'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfaceRemakeLinkStore } from '@@/pinia/surface_remake_link_store'
import type { WhiteboardPdfExportParams } from '@@/pinia/whiteboard_exports'
import { useWhiteboardRemakeLinkStore } from '@@/pinia/whiteboard_remake_link_store'
import PadletApi from '@@/surface/padlet_api'
import type {
  CanSetWallPrivacyOption,
  CanSetWallPrivacyOptions,
  Id,
  PrivacyPolicyId,
  UserGroupId,
  UserGroupWallCollaborator,
  UserId,
  UserWallCollaborator,
  WallAccessSettings,
} from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { makeElvisProxyUrl } from '@padlet/elvis-client'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum ShareSubpanel {
  Collaborator = 'collaborator',
  Embed = 'embed',
  Lti1_0 = 'lti1_0',
  ShareViaAnotherApp = 'share_via_another_app',
  PdfExport = 'pdf_export',
  ImageExport = 'image_export',
  SectionBreakout = 'section_breakout',
  SubmissionRequest = 'submission_request',
  RemakeLink = 'remake_link',
  WhiteboardRemakeLink = 'whiteboard_remake_links',
}

export enum ExportMethod {
  Screenshot = 'screenshot',
  ZipAttachments = 'zipAttachments',
  ZipWhiteboardPages = 'zipWhiteboardPages',
  Document = 'document',
  Csv = 'csv',
  Spreadsheet = 'spreadsheet',
  Print = 'print',
}

// The number represents how permisive the role is, with 0 being the least permissive and 5 being the most permissive.
const mapRoleToNumber = {
  [WallAccessRole.None]: 0,
  [WallAccessRole.Reader]: 1,
  [WallAccessRole.Commenter]: 2,
  [WallAccessRole.Writer]: 3,
  [WallAccessRole.Moderator]: 4,
  [WallAccessRole.Admin]: 5,
}

export const useSurfaceSharePanelStore = defineStore('surfaceSharePanel', () => {
  const surfaceVuexStore = getVuexStore<RootState>() // For gradual conversion to pinia
  const surfaceStore = useSurfaceStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const queue = new PromiseQueue()
  const surfaceRemakeLinkStore = useSurfaceRemakeLinkStore()
  const whiteboardRemakeLinkStore = useWhiteboardRemakeLinkStore()
  const screenReaderNotificationStore = useScreenReaderNotificationsStore()

  const allCollaboratorRoleOptions = computed(() => ({
    [WallAccessRole.Reader]: {
      title: __('Reader'),
      description: surfaceStore.isWhiteboard
        ? __('Can view this padlet')
        : __('Can access this padlet and read posts.'),
    },
    [WallAccessRole.Commenter]: {
      title: __('Commenter'),
      description: __('Can comment on posts and add reactions.'),
    },
    [WallAccessRole.Writer]: {
      title: __('Writer'),
      description: surfaceStore.isWhiteboard ? __('Can add new objects to cards') : __('Can write new posts.'),
    },
    [WallAccessRole.Moderator]: {
      title: surfaceStore.isWhiteboard ? __('Editor') : __('Moderator'),
      description: surfaceStore.isWhiteboard
        ? __('Can add new objects to cards and edit existing ones')
        : __('Can write, edit, and approve posts.'),
    },
    [WallAccessRole.Admin]: {
      title: __('Admin'),
      description: surfaceStore.isWhiteboard
        ? __('Can create, edit, and approve objects. Can invite collaborators and modify the padlet')
        : __('Can write, edit, and approve posts. Can invite collaborators and modify the padlet.'),
    },
  }))

  const standardPrivacyOptions = computed(() => ({
    [WallAccessPrivacyType.Secret]: {
      title: __('Secret'),
      description: surfaceStore.isWhiteboard
        ? __('This sandbox will be hidden from the public. Only people with the link can access it.')
        : __('This board will be hidden from the public. Only people with the link can access it.'),
    },
    [WallAccessPrivacyType.PasswordProtected]: {
      title: __('Secret - Password'),
      description: surfaceStore.isWhiteboard
        ? __('Only people with the link and password can access this sandbox.')
        : __('Only people with the link and password can access this board.'),
    },
    [WallAccessPrivacyType.LoggedInUsersOnly]: {
      title: __('Secret - Log in'),
      description: surfaceStore.isWhiteboard
        ? __('Only logged in visitors with the link can access this sandbox.')
        : __('Only logged in visitors with the link can access this board.'),
    },
    [WallAccessPrivacyType.Public]: {
      title: __('Public'),
      description: surfaceStore.isWhiteboard
        ? __('Anyone can access this sandbox. It will show up on your profile page and in Google search results.')
        : __('Anyone can access this board. It will show up on your profile page and in Google search results.'),
    },
  }))

  const allVisitorRoleOptions = computed(() => ({
    [WallAccessRole.None]: {
      title: __('No access'),
      description: surfaceStore.isWhiteboard
        ? __('Only collaborators can access this sandbox.')
        : __('Only collaborators can access this board.'),
    },
    [WallAccessRole.Reader]: {
      title: __('Reader'),
      description: surfaceStore.isWhiteboard
        ? __('Visitors can view this sandbox.')
        : __('Visitors can access this board and read posts.'),
    },
    // On web and newer mobile app version, you can set the visitor role to Commenter.
    // On older mobile app versions, Commenter is represented as Reader because Commenter doesn't exist on the UI for those versions.
    // The new Commenter is equivalent to the old Reader.
    [WallAccessRole.Commenter]: {
      title: __('Commenter'),
      description: __('Visitors can comment on posts and add reactions.'),
    },
    [WallAccessRole.Writer]: {
      title: __('Writer'),
      description: surfaceStore.isWhiteboard
        ? __('Visitors can add new objects to cards.')
        : __('Visitors can write new posts.'),
    },
    // On web and newer mobile app version, you can no longer set the visitor role to Moderator.
    // But you can still do so on older app versions, so we still need to display it on web and newer app versions.
    // But this option will be hidden on web and newer app versions.
    [WallAccessRole.Moderator]: {
      title: surfaceStore.isWhiteboard ? __('Editor') : __('Moderator'),
      description: surfaceStore.isWhiteboard
        ? __('Visitors can add new objects to cards and edit existing ones.')
        : __('Visitors can write, edit, and approve posts.'),
    },
    // On web and newer mobile app version, you can no longer set the visitor role to Administer.
    // But you can still do so on older app versions, so we still need to  display it on web and newer mobile app version.
    // But this option will be hidden on web and newer app versions.
    [WallAccessRole.Admin]: {
      title: __('Admin'),
      description: surfaceStore.isWhiteboard
        ? __('Visitors can create, edit, and approve objects. Can invite collaborators and modify the sandbox.')
        : __('Visitors can write, edit, and approve posts. Can invite collaborators and modify the board.'),
    },
  }))

  const orgPrivacyOptions = computed(() => {
    const title = __('Org only')
    const description = surfaceStore.isWhiteboard
      ? __('Only members of %{organization} can access this sandbox through the link.', {
          organization: surfaceStore.orgName,
        })
      : __('Only members of %{organization} can access this board through the link.', {
          organization: surfaceStore.orgName,
        })

    return {
      [WallAccessPrivacyType.OrgWideUnlisted]: {
        title,
        description,
      },
      [WallAccessPrivacyType.OrgWideListed]: {
        title,
        description,
      },
    }
  })
  const libraryPrivacyOptions = computed(() => {
    let title = __('Team only')
    if (surfaceVuexStore?.getters?.isClassroomLibrary as boolean) {
      title = __('Classroom only')
    } else if (surfaceVuexStore?.getters?.isSchoolLibrary as boolean) {
      title = __('School only')
    }

    const description = surfaceStore.isWhiteboard
      ? __('Only members of %{organization} can access this sandbox through the link.', {
          organization: surfaceStore.libraryName,
        })
      : __('Only members of %{organization} can access this board through the link.', {
          organization: surfaceStore.libraryName,
        })
    return {
      [WallAccessPrivacyType.LibraryWideUnlisted]: {
        title,
        description,
      },
      [WallAccessPrivacyType.LibraryWideListed]: {
        title,
        description,
      },
    }
  })
  const allPrivacyOptions = computed(() => {
    return { ...standardPrivacyOptions.value, ...orgPrivacyOptions.value, ...libraryPrivacyOptions.value }
  })

  // The warning tooltip to show on the Share section header if the privacy is Org or Library only
  const orgOnlyWarningTooltip = computed<string>(() => {
    if (surfaceVuexStore?.getters?.isOrgWall as boolean) {
      return surfaceStore.isWhiteboard
        ? __(
            'This sandbox is restricted to users in your organization. Visitors not in your organization will be denied access.',
          )
        : __(
            'This board is restricted to users in your organization. Visitors not in your organization will be denied access.',
          )
    }

    if (surfaceStore.isLibraryWall) {
      if (surfaceVuexStore?.getters?.isClassroomLibrary as boolean) {
        return surfaceStore.isWhiteboard
          ? __(
              'This sandbox is restricted to users in your classroom. Visitors not in your classroom will be denied access.',
            )
          : __(
              'This board is restricted to users in your classroom. Visitors not in your classroom will be denied access.',
            )
      }

      if (surfaceVuexStore?.getters?.isSchoolLibrary as boolean) {
        return surfaceStore.isWhiteboard
          ? __('This sandbox is restricted to users in your school. Visitors not in your school will be denied access.')
          : __('This board is restricted to users in your school. Visitors not in your school will be denied access.')
      }

      // else should be team library
      return surfaceStore.isWhiteboard
        ? __('This sandbox is restricted to users in your team. Visitors not in your team will be denied access.')
        : __('This board is restricted to users in your team. Visitors not in your team will be denied access.')
    }

    return ''
  })
  // State
  const xSurfaceSharePanel = ref(false)
  const isAwaitingSharePanelApiResponse = ref(true)
  const privacyType = ref(WallAccessPrivacyType.Public)
  const canSetWallPrivacyOptions = ref<CanSetWallPrivacyOption[]>([])
  const visitorRole = ref(WallAccessRole.None)
  const publicRole = ref(WallAccessRole.None)
  const libraryRole = ref(WallAccessRole.None)
  const tenantRole = ref(WallAccessRole.None)
  const password = ref<string | null>(null)
  const collaborators = ref<UserWallCollaborator[]>([])
  const isAwaitingCollaboratorSearchApiResponse = ref(false)
  const userWallCollaboratorsSearchResult = ref<UserWallCollaborator[]>([])
  const userGroupWallCollaboratorsSearchResult = ref<UserGroupWallCollaborator[]>([])
  const privacyPolicyId = ref<number | null>(null)
  const xQrModal = ref(false)
  const activePanel = ref<ShareSubpanel | null>(null)
  const snackbarUidByExportType = ref({})
  const isDownloadingFilesByExportType = ref({
    [ExportMethod.ZipAttachments]: false,
    [ExportMethod.ZipWhiteboardPages]: false,
    [ExportMethod.Document]: false,
    [ExportMethod.Spreadsheet]: false,
  })
  const isDownloadAllFilesProcessing = computed(() => isDownloadingFilesByExportType.value.zipAttachments ?? false)
  const isDownloadWhiteboardPagesProcessing = computed(
    () => isDownloadingFilesByExportType.value.zipWhiteboardPages ?? false,
  )
  const isDownloadDocumentProcessing = computed(() => isDownloadingFilesByExportType.value.document ?? false)
  const isDownloadXlsxProcessing = computed(() => isDownloadingFilesByExportType.value.spreadsheet ?? false)

  // Getters
  const surfaceExportsScreenshotStatusPath = computed<string>(
    () => surfaceVuexStore?.state.constants.surfaceExportsScreenshotStatusPath,
  )
  const surfaceExportsDocumentStatusPath = computed<string>(
    () => surfaceVuexStore?.state.constants.surfaceExportsDocumentStatusPath,
  )
  const allowedVisitorRoles = computed(() => {
    if (canSetPrivacyOption(WallAccessPrivacyType.Private)) {
      if (surfaceStore.isWhiteboard) {
        return [WallAccessRole.None, WallAccessRole.Reader, WallAccessRole.Writer, WallAccessRole.Moderator]
      }
      return [
        WallAccessRole.None,
        WallAccessRole.Reader,
        WallAccessRole.Commenter,
        WallAccessRole.Writer,
        WallAccessRole.Moderator,
      ]
    } else {
      if (surfaceStore.isWhiteboard) {
        return [WallAccessRole.Reader, WallAccessRole.Writer, WallAccessRole.Moderator]
      }
      return [WallAccessRole.Reader, WallAccessRole.Commenter, WallAccessRole.Writer, WallAccessRole.Moderator]
    }
  })
  const visitorRoleName = computed(() => {
    return allVisitorRoleOptions.value[visitorRole.value].title
  })
  const publicRoleName = computed(() => {
    return allVisitorRoleOptions.value[publicRole.value].title
  })
  const libraryRoleName = computed(() => {
    return allVisitorRoleOptions.value[libraryRole.value].title
  })
  const tenantRoleName = computed(() => {
    return allVisitorRoleOptions.value[tenantRole.value].title
  })
  const xLinkPrivacyRows = computed((): boolean => privacyType.value !== WallAccessPrivacyType.Private) // The private privacy is represented by no access on the visitor role dropdown
  const xPasswordInputRow = computed(
    (): boolean => privacyType.value === WallAccessPrivacyType.PasswordProtected && password.value != null, // When privacy is password-protected, the password that is automatically generated on the backend may not be available yet, wait for it
  )
  const xDashboardToggleRow = computed(
    (): boolean =>
      privacyType.value === WallAccessPrivacyType.OrgWideListed ||
      privacyType.value === WallAccessPrivacyType.OrgWideUnlisted ||
      privacyType.value === WallAccessPrivacyType.LibraryWideListed ||
      privacyType.value === WallAccessPrivacyType.LibraryWideUnlisted,
  )
  const hasCollaboratorSearchResults = computed(
    () => userWallCollaboratorsSearchResult.value.length > 0 || userGroupWallCollaboratorsSearchResult.value.length > 0,
  )
  const allowedOrgPrivacyOptions = computed(() => {
    const options = {}
    if (canSetPrivacyOption(WallAccessPrivacyType.Secret)) {
      options[WallAccessPrivacyType.Secret] = standardPrivacyOptions.value[WallAccessPrivacyType.Secret]
    }

    if (canSetPrivacyOption(WallAccessPrivacyType.PasswordProtected)) {
      options[WallAccessPrivacyType.PasswordProtected] =
        standardPrivacyOptions.value[WallAccessPrivacyType.PasswordProtected]
    }

    // No LoggedInUsersOnly because that is only for native tenant

    // If both OrgWideUnlisted and OrgWideListed are enabled, then we only list OrgWideUnlisted as a dropdown option for Link Privacy.
    // When the user selects OrgWideUnlisted, they can then toggle the "Show in dashboard" toggle from Off to On to get to OrgWideListed.
    //
    // If only OrgWideUnlisted or OrgWideListed is enabled, then we only list that enabled option.
    // When the user selects the enabled option, they cannot toggle the "Show in dashboard" toggle because the other OrgWide option is disabled.
    if (canSetPrivacyOption(WallAccessPrivacyType.OrgWideUnlisted)) {
      options[WallAccessPrivacyType.OrgWideUnlisted] = orgPrivacyOptions.value[WallAccessPrivacyType.OrgWideUnlisted]
    } else if (canSetPrivacyOption(WallAccessPrivacyType.OrgWideListed)) {
      options[WallAccessPrivacyType.OrgWideListed] = orgPrivacyOptions.value[WallAccessPrivacyType.OrgWideListed]
    }

    // No Public because that is only for native tenant

    return options
  })
  const allowedLibraryPrivacyOptions = computed(() => {
    const options = {}
    if (canSetPrivacyOption(WallAccessPrivacyType.Secret)) {
      options[WallAccessPrivacyType.Secret] = standardPrivacyOptions.value[WallAccessPrivacyType.Secret]
    }

    if (canSetPrivacyOption(WallAccessPrivacyType.PasswordProtected)) {
      options[WallAccessPrivacyType.PasswordProtected] =
        standardPrivacyOptions.value[WallAccessPrivacyType.PasswordProtected]
    }

    if (canSetPrivacyOption(WallAccessPrivacyType.LoggedInUsersOnly)) {
      options[WallAccessPrivacyType.LoggedInUsersOnly] =
        standardPrivacyOptions.value[WallAccessPrivacyType.LoggedInUsersOnly]
    }

    // If both LibraryWideUnlisted and LibraryWideListed are enabled, then we only list LibraryWideUnlisted as a dropdown option for Link Privacy.
    // When the user selects LibraryWideUnlisted, they can then toggle the "Show in dashboard" toggle from Off to On to get to LibraryWideListed.
    //
    // If only LibraryWideUnlisted or LibraryWideListed is enabled, then we only list that enabled option.
    // When the user selects the enabled option, they cannot toggle the "Show in dashboard" toggle because the other OrgWide option is disabled.
    if (canSetPrivacyOption(WallAccessPrivacyType.LibraryWideUnlisted)) {
      options[WallAccessPrivacyType.LibraryWideUnlisted] =
        libraryPrivacyOptions.value[WallAccessPrivacyType.LibraryWideUnlisted]
    } else if (canSetPrivacyOption(WallAccessPrivacyType.LibraryWideListed)) {
      options[WallAccessPrivacyType.LibraryWideListed] =
        libraryPrivacyOptions.value[WallAccessPrivacyType.LibraryWideListed]
    }

    if (canSetPrivacyOption(WallAccessPrivacyType.Public)) {
      options[WallAccessPrivacyType.Public] = standardPrivacyOptions.value[WallAccessPrivacyType.Public]
    }

    return options
  })
  const allowedPrivacyOptions = computed(() => {
    if (surfaceVuexStore?.getters?.isOrgWall as boolean) {
      return allowedOrgPrivacyOptions.value
    } else if (surfaceStore.isLibraryWall) {
      return allowedLibraryPrivacyOptions.value
    } else {
      return standardPrivacyOptions.value
    }
  })
  const privacyTypeName = computed(() => {
    if (privacyType.value === WallAccessPrivacyType.Private) return '' // You can no longer set the Private privacy type, you now set it implicitly when you set visitor role to No Access
    return allPrivacyOptions.value[privacyType.value].title
  })
  const selfSort = (a: UserWallCollaborator, b: UserWallCollaborator): number => {
    if (a.id === currentUserId.value) return -1
    if (b.id === currentUserId.value) return 1
    return 0
  }
  const statusSort = (a: UserWallCollaborator, b: UserWallCollaborator): number => {
    if (a.status === WallCollaboratorStatus.Approved && b.status === WallCollaboratorStatus.Invited) {
      return -1
    }

    if (a.status === WallCollaboratorStatus.Invited && b.status === WallCollaboratorStatus.Approved) {
      return 1
    }

    return 0
  }
  const roleSort = (a: UserWallCollaborator, b: UserWallCollaborator): number => {
    if (a.role === undefined || b.role === undefined) return 0
    return mapRoleToNumber[b.role] - mapRoleToNumber[a.role]
  }
  const displayNameSort = (a: UserWallCollaborator, b: UserWallCollaborator): number => {
    // Cannot use ?? because it will only fall-back to the next value if the left-hand side is null or undefined,
    // but we want it to fall-back to the right-hand side even if the left-hand side is an empty string
    const aDisplayName = a.name || a.username || a.email // eslint-disable-line @typescript-eslint/strict-boolean-expressions
    const bDisplayName = b.name || b.username || b.email // eslint-disable-line @typescript-eslint/strict-boolean-expressions
    return aDisplayName.localeCompare(bDisplayName)
  }

  // Sort collaborator list by status (approved then invited), role (greater power first) and then display name (alphabetical sort).
  // Additionally, your own collaborator row will always be at the top.
  const orderedCollaborators = computed<UserWallCollaborator[]>(() => {
    return collaborators.value.sort(displayNameSort).sort(roleSort).sort(statusSort).sort(selfSort)
  })

  const findCollaborator = (collaboratorIdToCheck: Id): UserWallCollaborator | undefined => {
    return collaborators.value.find((collaborator) => collaborator.id === collaboratorIdToCheck)
  }

  const isCollaboratorAlreadyAdded = (collaboratorIdToCheck: Id): boolean => {
    return findCollaborator(collaboratorIdToCheck) !== undefined
  }

  const isCollaboratorAlreadyAddedByEmail = (email: string): boolean => {
    return collaborators.value.find((collaborator) => collaborator?.email === email) !== undefined
  }

  const currentUserId = computed<UserId>(() => {
    return surfaceVuexStore?.state?.user?.id
  })
  const iosAppUrl = computed<string>(() => surfaceVuexStore?.state.constants.iosAppUrl)
  const androidAppUrl = computed<string>(() => surfaceVuexStore?.state.constants.androidAppUrl)
  const iTunesBadgeUrl = computed<string>(() => surfaceVuexStore?.state.constants.itunesBadgeUrl)
  const playStoreBadgeUrl = computed<string>(() => surfaceVuexStore?.state.constants.playStoreBadgeUrl)
  const padletEmbedCode = computed<string>(() => {
    const url = surfaceVuexStore?.state.wall.links?.embed
    const template = surfaceVuexStore?.state.constants.embedCodeUrlTemplate
    return template.replace('{embedUrl}', url)
  })
  const previewEmbedCode = computed<string>(() => {
    const url = surfaceVuexStore?.state.wall.links?.preview_embed
    const template = surfaceVuexStore?.state.constants.previewEmbedCodeUrlTemplate
    return template.replace('{previewEmbedUrl}', url)
  })
  // Display on dashboard toggle is only available for LibraryWideUnlisted, LibraryWideListed, OrgWideUnlisted, and OrgWideListed
  // Which are privacy options only available for library and tenanted walls
  const displayOnDashboardName = computed(() => {
    if (surfaceVuexStore?.getters?.isOrgWall as boolean) {
      return __('Display on org dashboard')
    } else if (surfaceVuexStore?.getters?.isClassroomLibrary as boolean) {
      return __('Display on classroom dashboard')
    } else if (surfaceVuexStore?.getters?.isSchoolLibrary as boolean) {
      return __('Display on school dashboard')
    } else {
      return __('Display on team dashboard')
    }
  })
  const isDisplayingOnDashboard = computed(() => {
    if (privacyType.value === WallAccessPrivacyType.LibraryWideListed) return true
    if (privacyType.value === WallAccessPrivacyType.OrgWideListed) return true
    return false
  })
  const toggleDisplayOnDashboardPrivacyMap = {
    [WallAccessPrivacyType.LibraryWideUnlisted]: WallAccessPrivacyType.LibraryWideListed,
    [WallAccessPrivacyType.LibraryWideListed]: WallAccessPrivacyType.LibraryWideUnlisted,
    [WallAccessPrivacyType.OrgWideUnlisted]: WallAccessPrivacyType.OrgWideListed,
    [WallAccessPrivacyType.OrgWideListed]: WallAccessPrivacyType.OrgWideUnlisted,
  }
  const canToggleDisplayOnDashboard = computed(() => {
    const toggleResult = toggleDisplayOnDashboardPrivacyMap[privacyType.value]
    if (toggleResult === undefined) return false
    return canSetPrivacyOption(toggleResult)
  })
  const isSkoletubeUser = computed<boolean>(() => surfaceVuexStore?.state?.isSkoletubeUser as boolean)
  const isLtiConsumable = computed<boolean>(() => {
    return (
      surfaceVuexStore?.state?.tenant?.is_lti_ready === true &&
      surfaceVuexStore?.state?.tenant?.type === 'school' &&
      ['owner', 'admin', 'teacher'].includes(surfaceVuexStore?.state?.user?.role)
    )
  })
  const ltiCallbackUrl = computed<string>(() => {
    return surfaceVuexStore?.state?.constants?.ltiCallbackUrl
  })
  const ltiConsumerKey = computed<string>(() => {
    return surfaceVuexStore?.state?.constants?.ltiConsumer?.key
  })
  const ltiSharedSecret = computed<string>(() => {
    return surfaceVuexStore?.state?.constants?.ltiConsumer?.sharedSecret
  })
  const baseLtiConfigUrl = computed<string>(() => {
    const baseLtiConfigUrl = surfaceVuexStore?.state?.constants?.baseLtiConfigUrl
    if (baseLtiConfigUrl === undefined) return ''

    const domainName = surfaceVuexStore?.getters?.domainName
    if (domainName === undefined) return ''

    const publicKey = surfaceVuexStore?.getters?.publicKey
    if (publicKey === undefined) return ''

    return baseLtiConfigUrl?.replace('_tenantDomainName_', domainName)?.replace('_wallPublicKey_', publicKey)
  })
  const ltiCustomParamsAsText = computed<string>(() => {
    return `public_key=${surfaceVuexStore?.getters?.publicKey as string}`
  })
  const visitorsCanModerate = computed<boolean>(() =>
    [WallAccessRole.Moderator, WallAccessRole.Admin].includes(visitorRole.value),
  )

  // Actions
  async function fetchWallAccessSettings(): Promise<void> {
    try {
      const response = await PadletApi.WallAccessSettings.fetch(surfaceVuexStore?.getters?.wallId, { apiVersion: 8 })
      const accessSettings = (response.data as JsonAPIResource<WallAccessSettings>).attributes
      privacyType.value = accessSettings.privacyType
      visitorRole.value = accessSettings.role ?? WallAccessRole.None
      publicRole.value = accessSettings.publicRole ?? WallAccessRole.None
      libraryRole.value = accessSettings.libraryRole ?? WallAccessRole.None
      tenantRole.value = accessSettings.tenantRole ?? WallAccessRole.None
      password.value = accessSettings.password
      collaborators.value = accessSettings.collaborators
      privacyPolicyId.value = accessSettings?.privacyPolicyId ?? null
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelFetchWallAccessSettings' })
    }
  }

  async function fetchPartialWallAccessSettings(): Promise<void> {
    try {
      const response = await PadletApi.WallAccessSettings.fetch(surfaceVuexStore?.getters?.wallId, { apiVersion: 8 })
      const accessSettings = (response.data as JsonAPIResource<WallAccessSettings>).attributes
      privacyType.value = accessSettings.privacyType
      visitorRole.value = accessSettings.role ?? WallAccessRole.None
      publicRole.value = accessSettings.publicRole ?? WallAccessRole.None
      libraryRole.value = accessSettings.libraryRole ?? WallAccessRole.None
      tenantRole.value = accessSettings.tenantRole ?? WallAccessRole.None
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelFetchPartialWallAccessSettings' })
    }
  }

  async function fetchWallPrivacyOptions(): Promise<void> {
    try {
      const response = await PadletApi.WallPrivacyOptions.fetchWallPrivacyOptions(surfaceVuexStore?.getters?.wallId)
      canSetWallPrivacyOptions.value = (
        response.data as JsonAPIResource<CanSetWallPrivacyOptions>
      ).attributes.privacyOptions.filter((privacyOption) => privacyOption.canSet)
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelFetchWallPrivacyOptions' })
    }
  }

  async function initialize(): Promise<void> {
    isAwaitingSharePanelApiResponse.value = true
    if (surfaceVuexStore?.getters?.canIAdminister as boolean) {
      await Promise.all([
        fetchWallAccessSettings(),
        fetchWallPrivacyOptions(),
        // fetch remake link settings for wall only if admin
        isAppUsing('remakeLink') ? surfaceRemakeLinkStore.fetchRemakeLink() : Promise.resolve(),
        isAppUsing('sandboxRemakeLinks') ? whiteboardRemakeLinkStore.fetchWhiteboardRemakeLink() : Promise.resolve(),
      ])
    } else if (surfaceVuexStore?.getters?.amICollaborator as boolean) {
      // Non-admin collaborator can only fetch partial wall access settings
      await fetchPartialWallAccessSettings()
    }

    isAwaitingSharePanelApiResponse.value = false
  }

  async function updateVisitorRole(
    oldRole: WallAccessRole,
    newRole: WallAccessRole,
    oldPrivacyType: WallAccessPrivacyType,
  ): Promise<void> {
    try {
      const response = await PadletApi.WallAccessSettings.update(
        surfaceVuexStore?.getters?.wallId,
        { role: newRole },
        { apiVersion: 8 },
      )
      const accessSettings = (response.data as JsonAPIResource<WallAccessSettings>).attributes
      privacyType.value = accessSettings.privacyType
      visitorRole.value = accessSettings.role ?? WallAccessRole.None
      publicRole.value = accessSettings.publicRole ?? WallAccessRole.None
      libraryRole.value = accessSettings.libraryRole ?? WallAccessRole.None
      tenantRole.value = accessSettings.tenantRole ?? WallAccessRole.None
      password.value = accessSettings.password
      collaborators.value = accessSettings.collaborators
      privacyPolicyId.value = accessSettings?.privacyPolicyId ?? null
    } catch (e) {
      if (e.status === HttpCode.Unauthorized) {
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.error,
          message: __('Sorry, this option is not allowed. Please reload the page.'),
        })
      } else {
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.error,
          message: __('Sorry, your request could not be completed. Please try again.'),
        })
        captureFetchException(e, { source: 'SurfaceSharePanelUpdateVisitorRole' })
      }

      visitorRole.value = oldRole
      privacyType.value = oldPrivacyType
    }
  }

  async function updatePrivacyType(
    oldPrivacyType: WallAccessPrivacyType,
    newPrivacyType: WallAccessPrivacyType,
  ): Promise<void> {
    try {
      const response = await PadletApi.WallAccessSettings.update(
        surfaceVuexStore?.getters?.wallId,
        { privacyType: newPrivacyType },
        { apiVersion: 8 },
      )
      const accessSettings = (response.data as JsonAPIResource<WallAccessSettings>).attributes
      privacyType.value = accessSettings.privacyType
      visitorRole.value = accessSettings.role ?? WallAccessRole.None
      publicRole.value = accessSettings.publicRole ?? WallAccessRole.None
      libraryRole.value = accessSettings.libraryRole ?? WallAccessRole.None
      tenantRole.value = accessSettings.tenantRole ?? WallAccessRole.None
      password.value = accessSettings.password
    } catch (e) {
      if (e.status === HttpCode.Unauthorized) {
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.error,
          message: __('Sorry, this option is not allowed. Please reload the page.'),
        })
      } else {
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.error,
          message: __('Sorry, your request could not be completed. Please try again.'),
        })
        captureFetchException(e, { source: 'SurfaceSharePanelUpdatePrivacyType' })
      }

      privacyType.value = oldPrivacyType
    }
  }

  async function updatePassword(oldPassword: string | null, newPassword: string | null): Promise<void> {
    try {
      const response = await PadletApi.WallAccessSettings.update(
        surfaceVuexStore?.getters?.wallId,
        { password: newPassword },
        { apiVersion: 8 },
      )
      const accessSettings = (response.data as JsonAPIResource<WallAccessSettings>).attributes
      privacyType.value = accessSettings.privacyType
      visitorRole.value = accessSettings.role ?? WallAccessRole.None
      publicRole.value = accessSettings.publicRole ?? WallAccessRole.None
      libraryRole.value = accessSettings.libraryRole ?? WallAccessRole.None
      tenantRole.value = accessSettings.tenantRole ?? WallAccessRole.None
      password.value = accessSettings.password
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.error,
        message: __('Sorry, your request could not be completed. Please try again.'),
      })
      captureFetchException(e, { source: 'SurfaceSharePanelUpdatePassword' })
      password.value = oldPassword
    }
  }

  async function inviteWallCollaboratorById(
    oldCollaborators: UserWallCollaborator[],
    collaboratorId: UserId,
    role: WallAccessRole | undefined,
  ): Promise<void> {
    try {
      const response = await PadletApi.WallCollaborator.invite({
        wallId: surfaceVuexStore?.getters?.wallId,
        userId: collaboratorId,
        role,
      })
      const returnedCollaborator = (response.data as JsonAPIResource<UserWallCollaborator>).attributes
      const returnedCollaboratorRole = returnedCollaborator.role
      const returnedCollaboratorStatus = returnedCollaborator.status

      let newCollaborator
      const newCollaborators = collaborators.value.map((collaborator) => {
        if (collaborator.id === collaboratorId) {
          newCollaborator = { ...collaborator, role: returnedCollaboratorRole, status: returnedCollaboratorStatus }
          return newCollaborator
        }
        return collaborator
      })

      screenReaderNotificationStore.addScreenReaderMessage(
        __('User %{collaboratorName} has been invited to be a collaborator', {
          collaboratorName: newCollaborator.name,
        }),
      )

      collaborators.value = newCollaborators
    } catch (e) {
      collaborators.value = oldCollaborators

      if (e.status === HttpCode.Unauthorized) {
        // The user might have lost permission to invite collaborator while the invite request was in flight.
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.error,
          message: __('Sorry, this option is not allowed. Please reload the page.'),
        })
        return
      } else if (e.status === HttpCode.NotFound) {
        // The user might have been deleted while the invite request was in flight.
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.error,
          message: __('Sorry, your request could not be completed. Please try again.'),
        })
        return
      } else if (e.status === HttpCode.UnprocessableEntity) {
        try {
          const parsedErrorMessage = JSON.parse(e.message)
          const error = parsedErrorMessage.errors[0]

          // We disallow the user from adding another user as collaborator when that user is already a collaborator.
          // But sometimes there is a race condition whereby that user is added as a collaborator by another user before the frontend disallows it.
          if (error.code === ApiErrorCode.USER_IS_ALREADY_A_COLLABORATOR) {
            // Given that the desired outcome - adding the user as a collaborator - is already achieved, silence the error.
            return
          } else if (error.code === ApiErrorCode.CANNOT_ADD_COLLABORATOR_TO_INACTIVE_PADLET) {
            globalSnackbarStore.setSnackbar({
              notificationType: SnackbarNotificationType.error,
              message: __('Cannot add collaborator to frozen or inactive padlet.'),
            })
            return
          }
        } catch (jsonException) {
          captureException(jsonException)
        }
      }

      // If unexpected error
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, {
        source: `SurfaceSharePanelInviteWallCollaboratorById`,
        collaboratorId,
      })
    }
  }

  async function inviteWallCollaboratorByEmail(
    oldCollaborators: UserWallCollaborator[],
    email: string,
    role: WallAccessRole | undefined,
  ): Promise<void> {
    try {
      const response = await PadletApi.WallCollaborator.invite({
        wallId: surfaceVuexStore?.getters?.wallId,
        email,
        role,
      })
      const returnedCollaborator = (response.data as JsonAPIResource<UserWallCollaborator>).attributes
      const newCollaborators = collaborators.value.map((collaborator) => {
        return collaborator.email === email ? { ...collaborator, ...returnedCollaborator } : collaborator
      })

      collaborators.value = newCollaborators
    } catch (e) {
      collaborators.value = oldCollaborators

      if (e.status === HttpCode.UnprocessableEntity) {
        try {
          const parsedErrorMessage = JSON.parse(e.message)
          const error = parsedErrorMessage.errors[0]

          // We disallow the user from adding another user as collaborator when that user is already a collaborator.
          // But sometimes there is a race condition whereby that user is added as a collaborator by another user before the frontend disallows it.
          if (error.code === ApiErrorCode.USER_IS_ALREADY_A_COLLABORATOR) {
            // Given that the desired outcome - adding the user as a collaborator - is already achieved, silence the error.
            return
          }
        } catch (jsonException) {
          captureException(jsonException)
        }
      }

      // If unexpected error
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: `SurfaceSharePanelInviteWallCollaboratorByEmail` })
    }
  }

  async function inviteUserGroupWallCollaborator(
    oldCollaborators: UserWallCollaborator[],
    collaboratorId: UserGroupId,
    role: WallAccessRole | undefined,
  ): Promise<void> {
    try {
      const response = await PadletApi.UserGroupWallCollaborator.invite({
        wallId: surfaceVuexStore?.getters?.wallId,
        userGroupId: collaboratorId,
        role,
      })
      const returnedCollaborator = (response.data as JsonAPIResource<UserGroupWallCollaborator>).attributes
      const returnedCollaboratorRole = returnedCollaborator.role

      const newCollaborators = collaborators.value.map((collaborator) => {
        return collaborator.id === collaboratorId ? { ...collaborator, role: returnedCollaboratorRole } : collaborator
      })

      collaborators.value = newCollaborators
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: `SurfaceSharePanelInviteUserGroupWallCollaborator` })
      collaborators.value = oldCollaborators
    }
  }

  async function updateWallCollaboratorRole(
    collaboratorId: UserId,
    oldRole: WallAccessRole,
    newRole: WallAccessRole,
  ): Promise<void> {
    try {
      const response = await PadletApi.WallCollaborator.update({
        wallId: surfaceVuexStore?.getters?.wallId,
        userId: collaboratorId,
        role: newRole,
      })
      const returnedCollaborator = (response.data as JsonAPIResource<UserWallCollaborator>).attributes
      const returnedCollaboratorRole = returnedCollaborator.role
      let returnedCollaboratorName = returnedCollaborator.name

      const newCollaborators = collaborators.value.map((collaborator) => {
        if (collaborator.id === collaboratorId) {
          returnedCollaboratorName = collaborator.name
          return { ...collaborator, role: returnedCollaboratorRole }
        }
        return collaborator
      })

      screenReaderNotificationStore.addScreenReaderMessage(
        __('User %{collaboratorName} has been updated to %{newRole} role', {
          collaboratorName: returnedCollaboratorName,
          newRole: allCollaboratorRoleOptions.value[newRole].title,
        }),
      )

      collaborators.value = newCollaborators
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: `SurfaceSharePanelUpdateWallCollaboratorRole` })

      // If update fail, revert the user's role back to the old role
      const oldCollaborators = collaborators.value.map((collaborator) => {
        return collaborator.id === collaboratorId ? { ...collaborator, role: oldRole } : collaborator
      })

      collaborators.value = oldCollaborators
    }
  }

  async function updateUserGroupWallCollaboratorRole(
    collaboratorId: UserGroupId,
    oldRole: WallAccessRole,
    newRole: WallAccessRole,
  ): Promise<void> {
    try {
      const response = await PadletApi.UserGroupWallCollaborator.update({
        wallId: surfaceVuexStore?.getters?.wallId,
        userGroupId: collaboratorId,
        role: newRole,
      })
      const returnedCollaborator = (response.data as JsonAPIResource<UserGroupWallCollaborator>).attributes
      const returnedCollaboratorRole = returnedCollaborator.role
      const returnedCollaboratorName = returnedCollaborator.name

      const newCollaborators = collaborators.value.map((collaborator) => {
        return collaborator.id === collaboratorId ? { ...collaborator, role: returnedCollaboratorRole } : collaborator
      })

      screenReaderNotificationStore.addScreenReaderMessage(
        __('User %{collaboratorName} has been updated to %{newRole} role', {
          collaboratorName: returnedCollaboratorName,
          newRole: allCollaboratorRoleOptions.value[newRole].title,
        }),
      )

      collaborators.value = newCollaborators
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: `SurfaceSharePanelUpdateUserGroupWallCollaboratorRole` })

      // If update fail, revert the user's role back to the old role
      const oldCollaborators = collaborators.value.map((collaborator) => {
        return collaborator.id === collaboratorId ? { ...collaborator, role: oldRole } : collaborator
      })

      collaborators.value = oldCollaborators
    }
  }

  async function removeWallCollaborator(
    oldCollaborators: UserWallCollaborator[],
    collaboratorId: UserId,
  ): Promise<void> {
    try {
      await PadletApi.WallCollaborator.remove({
        wallId: surfaceVuexStore?.getters?.wallId,
        userId: collaboratorId,
      })

      const collaboratorToRemove = oldCollaborators.find((collaborator) => collaborator.id === collaboratorId)
      if (collaboratorToRemove == null) return

      const newCollaborators = oldCollaborators.filter((collaborator) => collaborator.id !== collaboratorId)
      collaborators.value = newCollaborators

      screenReaderNotificationStore.addScreenReaderMessage(
        __('User %{collaboratorName} has been removed as a collaborator', {
          collaboratorName: collaboratorToRemove.name,
        }),
      )
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: `SurfaceSharePanelRemoveWallCollaborator` })
      collaborators.value = oldCollaborators
    }
  }

  async function removeUserGroupWallCollaborator(
    oldCollaborators: UserWallCollaborator[],
    collaboratorId: Id,
  ): Promise<void> {
    try {
      await PadletApi.UserGroupWallCollaborator.remove({
        wallId: surfaceVuexStore?.getters?.wallId,
        userGroupId: collaboratorId,
      })

      const newCollaborators = oldCollaborators.filter((collaborator) => collaborator.id !== collaboratorId)
      collaborators.value = newCollaborators
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: `SurfaceSharePanelRemoveUserGroupWallCollaborator` })
      collaborators.value = oldCollaborators
    }
  }

  async function searchCollaborators(query: string): Promise<void> {
    try {
      isAwaitingCollaboratorSearchApiResponse.value = true
      const response = await PadletApi.WallCollaborator.search({
        searchQuery: encodeURIComponent(query),
        wallId: surfaceVuexStore?.getters?.wallId,
      })

      // Filter group collaborators
      const userGroupWallCollaborators = (response.data as Array<JsonAPIResource<UserGroupWallCollaborator>>)
        .filter(
          (collaborator: JsonAPIResource<UserGroupWallCollaborator>) => collaborator.type === 'userGroupCollaborator',
        )
        .map((collaborator) => collaborator.attributes)

      userGroupWallCollaboratorsSearchResult.value = userGroupWallCollaborators.filter(
        (collaborator) => collaborator.name !== '',
      )

      // Filter user collaborators
      const userWallCollaborators = (response.data as Array<JsonAPIResource<UserWallCollaborator>>)
        .filter((collaborator) => collaborator.type === 'userCollaborator')
        .map((collaborator) => collaborator.attributes)

      userWallCollaboratorsSearchResult.value = userWallCollaborators.filter(
        (collaborator) => collaborator.username !== '',
      )
    } catch (e) {
      captureFetchException(e, { source: 'SurfaceSharePanelSearchCollaborator' })
    } finally {
      isAwaitingCollaboratorSearchApiResponse.value = false
    }
  }

  function clearCollaboratorSearchResults(): void {
    userWallCollaboratorsSearchResult.value = []
    userGroupWallCollaboratorsSearchResult.value = []
  }

  async function resendCollaboratorInvitation(collaboratorId: UserId): Promise<void> {
    if (privacyPolicyId.value === null) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.error,
        message: __('Sorry, your request could not be completed. Please try again.'),
      })
      captureException(new Error('Missing privacyPolicyId'))
    }

    try {
      await PadletApi.WallCollaborator.resendInvitation(privacyPolicyId.value as PrivacyPolicyId, collaboratorId)
      globalSnackbarStore.setSnackbar({
        message: __('Invitation sent!'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      if (e.status === HttpCode.RateLimitError) {
        // Too many requests
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.error,
          message: __('Please wait before trying again.'),
        })
      } else {
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.error,
          message: __('Sorry, your request could not be completed. Please try again.'),
        })
        captureFetchException(e, { source: 'SurfaceSharePanelResendCollaboratorInvitation' })
      }
    }
  }

  function removeCollaboratorRemotely({ user_id: collaboratorId }: { user_id: UserId | null }): void {
    if (collaboratorId == null) return

    // Only remove collaborator from the store.
    const oldCollaborators = collaborators.value
    const newCollaborators = oldCollaborators.filter((collaborator) => collaborator.id !== collaboratorId)
    collaborators.value = newCollaborators

    if (device.app) {
      // Put the user id inside postSurfaceState's transientPayload so it's only sent once.
      // Sending the same id multiple time may break things if the user if re-added after they left the padlet.
      void useNativeAppStore().postSurfaceState({ collaborator_just_left_user_id: collaboratorId })
    }
  }

  async function queueUpdateVisitorRole(newRole: WallAccessRole): Promise<void> {
    const oldRole = visitorRole.value
    if (newRole === oldRole) return

    // Optimistically update the visitor role to the new value on the frontend immediately, before queueing the update request to the backend, for faster response
    const oldPrivacyType = privacyType.value
    visitorRole.value = newRole
    if (newRole === WallAccessRole.None) {
      privacyType.value = WallAccessPrivacyType.Private // Optimistically update the privacy to private if setting role to none
    }

    void queue.enqueue('updateAccessSettings', async () => await updateVisitorRole(oldRole, newRole, oldPrivacyType)) // Pass oldRole and oldPrivacyType so we can roll back to those values if the update request fails
  }

  async function queueUpdatePrivacyType(newPrivacyType: WallAccessPrivacyType): Promise<void> {
    const oldPrivacyType = privacyType.value
    if (newPrivacyType === oldPrivacyType) return

    // Optimistically update the privacy type to the new value on the frontend immediately, before queueing the update request to the backend, for faster response
    privacyType.value = newPrivacyType

    void queue.enqueue('updateAccessSettings', async () => await updatePrivacyType(oldPrivacyType, newPrivacyType)) // Pass oldPrivacyType so we can roll back to it if the update request fails
  }

  async function queueUpdatePassword(newPassword: string): Promise<void> {
    const oldPassword = password.value
    if (newPassword === oldPassword) return

    // Optimistically update the password to the new value on the frontend immediately, before queueing the update request to the backend, for faster response
    password.value = newPassword

    void queue.enqueue('updateAccessSettings', async () => await updatePassword(oldPassword, newPassword)) // Pass oldPassword so we can roll back to it if the update request fails
  }

  async function queueUpdateCollaboratorRole(collaboratorId: UserId, newRole: WallAccessRole): Promise<void> {
    const collaboratorToUpdate = collaborators.value.find((collaborator) => collaborator.id === collaboratorId)
    if (collaboratorToUpdate === undefined) return

    const oldRole = collaboratorToUpdate?.role
    if (oldRole === undefined) return
    if (oldRole === newRole) return

    const newCollaborators = collaborators.value.map((collaborator) => {
      return collaborator.id === collaboratorId ? { ...collaborator, role: newRole } : collaborator
    })

    // Optimistically update the collaborator's role to the new value on the frontend immediately, before queueing the update request to the backend, for faster response
    collaborators.value = newCollaborators

    if (collaboratorToUpdate.type === 'user') {
      void queue.enqueue(
        'updateAccessSettings',
        async () => await updateWallCollaboratorRole(collaboratorId, oldRole, newRole),
      ) // Pass oldRole so we can roll back to it if the update request fails
    } else {
      void queue.enqueue(
        'updateAccessSettings',
        async () => await updateUserGroupWallCollaboratorRole(collaboratorId, oldRole, newRole),
      ) // Pass oldRole so we can roll back to it if the update request fails
    }
  }

  async function queueRemoveCollaborator(collaboratorId: UserId): Promise<void> {
    const collaboratorToRemove = collaborators.value.find((collaborator) => collaborator.id === collaboratorId)
    if (collaboratorToRemove === undefined) return

    const oldCollaborators = collaborators.value
    const newCollaborators = oldCollaborators.filter((collaborator) => collaborator.id !== collaboratorId)

    // Optimistically update the collaborators to the new value on the frontend immediately, before queueing the update request to the backend, for faster response
    collaborators.value = newCollaborators
    globalSnackbarStore.setSnackbar({
      notificationType: SnackbarNotificationType.success,
      message: __('Collaborator removed.'),
    })

    if (collaboratorToRemove.type === 'user') {
      void queue.enqueue(
        'updateAccessSettings',
        async () => await removeWallCollaborator(oldCollaborators, collaboratorId),
      ) // Pass oldCollaborators so we can roll back to it if the update request fails
    } else {
      void queue.enqueue(
        'updateAccessSettings',
        async () => await removeUserGroupWallCollaborator(oldCollaborators, collaboratorId),
      ) // Pass oldCollaborators so we can roll back to it if the update request fails
    }
  }

  async function queueInviteCollaborator(
    collaborator: UserWallCollaborator | UserGroupWallCollaborator,
  ): Promise<void> {
    if (isCollaboratorAlreadyAdded(collaborator.id)) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('%{name} is already added as collaborator.', { name: collaborator.name }),
      })
    }

    const oldCollaborators = collaborators.value
    const newCollaborator = {
      ...collaborator,
      role: visitorRole.value === WallAccessRole.None ? WallAccessRole.Reader : visitorRole.value,
      status: WallCollaboratorStatus.Invited,
    }

    const newCollaborators = oldCollaborators.concat(newCollaborator as UserWallCollaborator)

    // Optimistically update the collaborators to the new value on the frontend immediately, before queueing the update request to the backend, for faster response
    collaborators.value = newCollaborators

    // Optimistically update the collaborator's role to the new value on the frontend immediately, before queueing the update request to the backend, for faster response
    collaborators.value = newCollaborators

    if (collaborator.type === 'user') {
      void queue.enqueue(
        'updateAccessSettings',
        async () => await inviteWallCollaboratorById(oldCollaborators, collaborator.id, collaborator?.role),
      ) // Pass oldCollaborators so we can roll back to it if the update request fails
    } else {
      void queue.enqueue(
        'updateAccessSettings',
        async () => await inviteUserGroupWallCollaborator(oldCollaborators, collaborator.id, collaborator?.role),
      ) // Pass oldRole so we can roll back to it if the update request fails
    }
  }

  async function queueInviteCollaboratorByEmail(email: string): Promise<void> {
    if (isCollaboratorAlreadyAddedByEmail(email)) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('%{name} is already added as collaborator.', { name: email }),
      })
    }

    const cleanedEmail = email.trim().toLowerCase()
    const role = visitorRole.value === WallAccessRole.None ? WallAccessRole.Reader : visitorRole.value
    const oldCollaborators = collaborators.value
    const newCollaborator: Partial<UserWallCollaborator> = {
      email: cleanedEmail,
      role,
      status: WallCollaboratorStatus.Invited,
      type: 'user',
    }

    const newCollaborators = oldCollaborators.concat(newCollaborator as UserWallCollaborator)

    // Optimistically update the collaborators to the new value on the frontend immediately, before queueing the update request to the backend, for faster response
    collaborators.value = newCollaborators

    void queue.enqueue(
      'updateAccessSettings',
      async () => await inviteWallCollaboratorByEmail(oldCollaborators, cleanedEmail, role),
    ) // Pass oldCollaborators so we can roll back to it if the update request fails
  }

  async function toggleDisplayOnDashboard(): Promise<void> {
    await queueUpdatePrivacyType(toggleDisplayOnDashboardPrivacyMap[privacyType.value])
  }

  function canSetPrivacyOption(privacyType: WallAccessPrivacyType): boolean {
    return (
      canSetWallPrivacyOptions.value.find(
        (option: CanSetWallPrivacyOption) => (option.name as string) === (privacyType as string),
      ) !== undefined
    )
  }

  function confirmCollaboratorLeavePadlet(): void {
    const adminOrAdministrator = surfaceStore.isLibraryWall ? 'admin' : 'administrator'
    void useGlobalConfirmationDialogStore().openConfirmationDialog({
      ...WAVING_HAND_EMOJI,
      isCodeProtected: false,
      title: surfaceStore.isWhiteboard ? __('Leave this sandbox?') : __('Leave this board?'),
      body: __('You will have to be invited back by an %{adminOrAdministrator}.', { adminOrAdministrator }),
      confirmButtonText: __('Leave'),
      cancelButtonText: __('Nevermind'),
      afterConfirmActions: [async () => await surfaceVuexStore?.dispatch('leavePadlet')],
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
      xShadow: true,
    })
  }

  const setSnackbarForExport = (
    exportType: ExportMethod,
    message: string,
    notificationType: SnackbarNotificationType,
    persist: boolean,
  ): void => {
    removeProcessingDownloadAttachmentsSnackbar(exportType)
    snackbarUidByExportType.value[exportType] = globalSnackbarStore.setSnackbar({
      message,
      notificationType,
      persist,
      timeout: persist ? undefined : 5000,
    })
  }

  const setConfirmationDialogForExport = (exportType: ExportMethod): void => {
    removeProcessingDownloadAttachmentsSnackbar(exportType)

    let title
    let body
    if (exportType === ExportMethod.Document) {
      title = __('Your PDF will be emailed to you soon')
      body = __(
        'This may take a bit of time so we’ll email you a download link when your PDF is ready. You can navigate away from this page if you’d like.',
      )
    } else if (exportType === ExportMethod.Screenshot || exportType === ExportMethod.ZipWhiteboardPages) {
      title = __('Your images will be emailed to you soon')
      body = __(
        'This may take a bit of time so we’ll email you a download link when your images are ready. You can navigate away from this page if you’d like.',
      )
    } else {
      // else if ZipAttachments
      // There's no copy for Csv, Spreadsheet, Print because they are always downloaded synchronously
      title = __('Your files will be emailed to you soon')
      body = __(
        'This may take a bit of time so we’ll email you a download link when your files are ready. You can navigate away from this page if you’d like.',
      )
    }

    void useGlobalConfirmationDialogStore().openConfirmationDialog({
      ...ROCKET_EMOJI,
      isCodeProtected: false,
      title,
      body,
      confirmButtonText: __('Got it'),
      afterConfirmActions: [],
      buttonScheme: OzConfirmationDialogBoxButtonScheme.ConfirmOnly,
      xShadow: true,
    })
  }

  const removeProcessingDownloadAttachmentsSnackbar = (exportType: ExportMethod): void => {
    const snackbarUid = snackbarUidByExportType.value[exportType]
    if (snackbarUid != null) globalSnackbarStore.removeSnackbar(snackbarUid)
  }

  async function processResponseAndShowSnackbar({
    exportType,
    fetchSource,
    fetchPromise,
    snackbarMessage = __('Processing files. Please don’t leave this page.'),
  }: {
    exportType: ExportMethod
    fetchSource: string
    fetchPromise: Promise<Response>
    snackbarMessage?: string
  }): Promise<void> {
    isDownloadingFilesByExportType.value[exportType] = true

    setSnackbarForExport(exportType, snackbarMessage, SnackbarNotificationType.success, true)

    try {
      const response = await fetchPromise

      if (response.status === HttpCode.Accepted) {
        setConfirmationDialogForExport(exportType)
        return
      }

      if (response.headers.get('Content-Type')?.includes('application/json') === true) {
        const json = await response.json()
        if (response.status === HttpCode.Ok) {
          let downloadUrl = json.download_url

          // Use Elvis Proxy to add download header to the URL for image and PDF files,
          // so the file is downloaded rather than opened by the browser.
          if (json.use_elvis_proxy === true) {
            downloadUrl = makeElvisProxyUrl(json.download_url, {
              expiryInDays: 0,
              download: true,
            })
          }

          triggerDownload(downloadUrl)
          removeProcessingDownloadAttachmentsSnackbar(exportType)
          return
        } else if (response.status === HttpCode.RateLimitError && json.code === ApiErrorCode.STILL_PROCESSING) {
          setSnackbarForExport(
            exportType,
            __('Your request is still processing. Please wait until it is completed.'),
            SnackbarNotificationType.error,
            false,
          )
          return
        }
      } else if (
        response.status === HttpCode.Ok &&
        response.headers.get('Content-Disposition')?.includes('attachment') === true
      ) {
        triggerDownload(response.url)
        removeProcessingDownloadAttachmentsSnackbar(exportType)
        return
      }

      removeProcessingDownloadAttachmentsSnackbar(exportType)
      globalSnackbarStore.genericFetchError()
      captureMessage(`Unexpected status code returned for download ${exportType} request`, {
        level: 'warning',
        context: { response },
      })
    } catch (error) {
      removeProcessingDownloadAttachmentsSnackbar(exportType)
      globalSnackbarStore.genericFetchError()
      captureFetchException(error, { source: fetchSource })
    } finally {
      isDownloadingFilesByExportType.value[exportType] = false
    }
  }

  async function requestZipAttachments(): Promise<void> {
    if (isRegistered(surfaceStore.user)) {
      if (useSurfacePermissionsStore().canIAccessContentDirectly) {
        await processResponseAndShowSnackbar({
          exportType: ExportMethod.ZipAttachments,
          fetchSource: 'SurfaceSharePanelExportDownloadWallAttachmentsZip',
          fetchPromise: PadletApi.WallAttachments.downloadAttachmentsZip(),
        })
      } else {
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.error,
          message: __('Downloading of files is disabled due to content protection settings'),
        })
      }
    } else {
      requestLoginToDownloadAttachmentsDialog()
    }
  }

  async function requestZipWhiteboardPages(): Promise<void> {
    await processResponseAndShowSnackbar({
      exportType: ExportMethod.ZipWhiteboardPages,
      fetchSource: 'SurfaceSharePanelExportDownloadWallWhiteboardPagesZip',
      fetchPromise: PadletApi.WallExports.downloadWhiteboardPagesZip(),
      snackbarMessage: __('Generating images... please don’t leave this page.'),
    })
  }

  async function requestWhiteboardPagesPdf(params?: WhiteboardPdfExportParams): Promise<void> {
    await processResponseAndShowSnackbar({
      exportType: ExportMethod.Document,
      fetchSource: 'SurfaceSharePanelExportDownloadWallWhiteboardPagesPdf',
      fetchPromise: PadletApi.WallExports.downloadWhiteboardPagesPdf(params),
      snackbarMessage: __('Generating PDF... please don’t leave this page.'),
    })
  }

  async function requestSpreadsheet(): Promise<void> {
    if (surfaceStore.wallAttributes?.links?.spreadsheet == null) {
      return
    }
    await processResponseAndShowSnackbar({
      exportType: ExportMethod.Spreadsheet,
      fetchSource: 'SurfaceSharePanelExportDownloadWallSpreadsheet',
      fetchPromise: fetch(buildUrlFromPath(new URL(surfaceStore.wallAttributes.links.spreadsheet).pathname)),
    })
  }

  function requestLoginToDownloadAttachmentsDialog(): void {
    const urlOptions: UrlOptions = {
      searchParams: { download_all_files: 'true' },
    }

    surfaceStore.showPromptToLoginDialog({
      title: __('You must be logged in to download all files'),
      urlTransformParams: urlOptions,
    })
  }

  // This was added so it can be called in the old Vuexstore at services/rails/app/javascript/vuexstore/modules/native_app.ts
  // That old Vuexstore native_app will soon be replaced by a new Pinia native_app at services/rails/app/javascript/pinia/native_app.ts
  // TODO: When that happens, delete this function, given that you can call useSurfaceSharePanelStore.xSurfaceSharePanel = newValue to update xSurfaceSharePanel in the new Pinia native_app
  function hideSharePanel(): void {
    xSurfaceSharePanel.value = false
  }

  return {
    // Constants
    allVisitorRoleOptions,
    mapRoleToNumber,

    // State
    xSurfaceSharePanel,
    isAwaitingSharePanelApiResponse,
    privacyType,
    visitorRole,
    publicRole,
    libraryRole,
    tenantRole,
    password,
    collaborators,
    isAwaitingCollaboratorSearchApiResponse,
    userWallCollaboratorsSearchResult,
    userGroupWallCollaboratorsSearchResult,
    canSetWallPrivacyOptions,
    xQrModal,
    activePanel,
    isDownloadAllFilesProcessing,
    isDownloadWhiteboardPagesProcessing,
    isDownloadDocumentProcessing,
    isDownloadXlsxProcessing,

    // Getters
    surfaceExportsScreenshotStatusPath,
    surfaceExportsDocumentStatusPath,
    xLinkPrivacyRows,
    xPasswordInputRow,
    xDashboardToggleRow,
    hasCollaboratorSearchResults,
    allowedVisitorRoles,
    visitorRoleName,
    publicRoleName,
    libraryRoleName,
    tenantRoleName,
    orderedCollaborators,
    currentUserId,
    iosAppUrl,
    androidAppUrl,
    iTunesBadgeUrl,
    playStoreBadgeUrl,
    padletEmbedCode,
    previewEmbedCode,
    allowedPrivacyOptions,
    orgOnlyWarningTooltip,
    privacyTypeName,
    displayOnDashboardName,
    isDisplayingOnDashboard,
    canToggleDisplayOnDashboard,
    isSkoletubeUser,
    isLtiConsumable,
    ltiCallbackUrl,
    ltiConsumerKey,
    ltiSharedSecret,
    baseLtiConfigUrl,
    ltiCustomParamsAsText,
    visitorsCanModerate,
    allCollaboratorRoleOptions,
    // Actions
    initialize,
    fetchWallAccessSettings,
    searchCollaborators,
    resendCollaboratorInvitation,
    removeCollaboratorRemotely,
    queueUpdateVisitorRole,
    queueUpdatePrivacyType,
    queueUpdatePassword,
    queueUpdateCollaboratorRole,
    queueRemoveCollaborator,
    queueInviteCollaborator,
    queueInviteCollaboratorByEmail,
    toggleDisplayOnDashboard,
    confirmCollaboratorLeavePadlet,
    clearCollaboratorSearchResults,
    findCollaborator,
    hideSharePanel,
    requestZipAttachments,
    requestZipWhiteboardPages,
    requestWhiteboardPagesPdf,
    requestSpreadsheet,
    requestLoginToDownloadAttachmentsDialog,
  }
})
