// @file Store for simulating attribution on surface
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { getSafeStorage } from '@@/bits/safe_storage'
import { defineStore } from 'pinia'
import { ref } from 'vue'

interface SimulatedUser {
  name: string
  avatar: string
}

type HashId = string

export const useSurfaceSimulatedAttributionStore = defineStore('surfaceSimulatedAttributionStore', () => {
  // Initialize the attributions ref with the value from local storage
  const storedAttributions = getSafeStorage().getItem('demoPadletSimulatedAttributions')
  const attributions = ref<Record<HashId, SimulatedUser>>(
    storedAttributions != null && storedAttributions.trim() !== '' ? JSON.parse(storedAttributions) : {},
  )

  // Given a hash ID, set the simulated user for tha hash ID
  function setSimulatedUserForHashId(hashId: HashId, simulatedUser: SimulatedUser): void {
    attributions.value = {
      ...attributions.value,
      [hashId]: simulatedUser,
    }
    getSafeStorage().setItem('demoPadletSimulatedAttributions', asciiSafeStringify(attributions.value))
  }

  function clearAllSimulatedUserForHashIds(): void {
    attributions.value = {}
    getSafeStorage().removeItem('demoPadletSimulatedAttributions')
  }

  return {
    attributions,
    setSimulatedUserForHashId,
    clearAllSimulatedUserForHashIds,
  }
})
