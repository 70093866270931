// @file Surface whiteboard remake link store
// This file was bootstrapped from surface_remake_link_store.ts
import { trackEvent } from '@@/bits/analytics'
import { CONFIRM_TURN_OFF } from '@@/bits/confirmation_dialog'
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import PromiseQueue from '@@/bits/promise_queue'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import PadletApi from '@@/surface/padlet_api'
import type { WallCamelCase, WallWhiteboardRemakeLink, WallWhiteboardRemakeLinkProps } from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum WhiteboardRemakeLinkPanelStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Error = 'Error',
}

export enum WhiteboardRemakeLinkCopyObjectsOptions {
  None = 'none',
  AdminOnly = 'admin_only',
  FrozenOnly = 'frozen_only',
  All = 'all',
}

export const useWhiteboardRemakeLinkStore = defineStore('whiteboardRemakeLinkStore', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()
  const surfaceStore = useSurfaceStore()
  const whiteboardRemakeLinkStatus = ref(WhiteboardRemakeLinkPanelStatus.Completed)

  const isWhiteboardRemakeLinkLoading = computed(
    () => whiteboardRemakeLinkStatus.value === WhiteboardRemakeLinkPanelStatus.Loading,
  )

  // Whiteboard remake link attributes
  const whiteboardRemakeLinkUrl = ref<string | null>(null)
  const whiteboardRemakeLinkCopyObjects = ref<string | null>(null)
  const whiteboardRemakeLinkCopyWallpaper = ref<boolean | null>(null)
  const whiteboardRemakeLinkAddAdmins = ref<boolean | null>(null)
  const whiteboardRemakeLinkLimitOneRemakePerUser = ref<boolean | null>(null)

  const isWhiteboardRemakeLinkEnabled = computed(
    () =>
      whiteboardRemakeLinkCopyObjects.value !== null &&
      whiteboardRemakeLinkAddAdmins.value !== null &&
      whiteboardRemakeLinkCopyWallpaper.value !== null &&
      whiteboardRemakeLinkLimitOneRemakePerUser.value !== null,
  )

  // Walls remade
  const whiteboardWallsRemade = ref<WallCamelCase[]>([])
  const whiteboardWallsRemadeStatus = ref(WhiteboardRemakeLinkPanelStatus.Completed)
  const isWhiteboardWallsRemadeLoading = computed(
    () => whiteboardWallsRemadeStatus.value === WhiteboardRemakeLinkPanelStatus.Loading,
  )

  const hasWhiteboardWallsRemade = computed(() => whiteboardWallsRemade.value.length > 0)

  const queue = new PromiseQueue()

  async function fetchWhiteboardRemakeLink(): Promise<void> {
    try {
      const attributes = await PadletApi.RemakeLink.fetch(surfaceStore.wallId)
      // If we fetch undefined, it means remake link is not enabled
      if (attributes !== undefined) {
        setWhiteboardRemakeLinkAttributes(attributes as WallWhiteboardRemakeLink)
        // Fetch walls remade only if remake link is enabled
        await fetchWhiteboardWallsRemade()
      } else {
        resetWhiteboardRemakeLinkAttributes()
      }
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelWhiteboardRemakeLinkFetch' })
    }
  }

  async function fetchWhiteboardWallsRemade(): Promise<void> {
    whiteboardWallsRemadeStatus.value = WhiteboardRemakeLinkPanelStatus.Loading
    try {
      const wallsRemadeData = await PadletApi.RemakeLink.fetchWallsRemade(surfaceStore.wallId)
      whiteboardWallsRemade.value = wallsRemadeData as WallCamelCase[]
      whiteboardWallsRemadeStatus.value = WhiteboardRemakeLinkPanelStatus.Completed
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelWhiteboardRemakeLinkFetchWallsRemade' })
      whiteboardWallsRemadeStatus.value = WhiteboardRemakeLinkPanelStatus.Error
    }
  }

  async function updateWhiteboardRemakeLink(changedProps: Partial<WallWhiteboardRemakeLinkProps>): Promise<void> {
    // We can only update remake link if it is enabled
    if (!isWhiteboardRemakeLinkEnabled.value) return
    try {
      await PadletApi.WhiteboardRemakeLink.update(surfaceStore.wallId, changedProps)
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelWhiteboardRemakeLinkUpdate' })
      throw e
    }
  }

  async function updateWhiteboardCopyObjectsProp(value: WhiteboardRemakeLinkCopyObjectsOptions): Promise<void> {
    const oldValue = whiteboardRemakeLinkCopyObjects.value
    // Return if value is the same
    if (oldValue === value) return
    // Return if invalid value
    if (!Object.values(WhiteboardRemakeLinkCopyObjectsOptions).includes(value)) return
    whiteboardRemakeLinkCopyObjects.value = value
    void queue.enqueue('updateWhiteboardRemakeLinkProps', async () => {
      try {
        await updateWhiteboardRemakeLink({ copyObjects: value })
        trackEvent('AutoWhiteboardRemakeLink', 'Update Copy Objects', surfaceStore.wallId, value, {
          wall_id: surfaceStore.wallId,
        })
      } catch (e) {
        whiteboardRemakeLinkCopyObjects.value = oldValue
      }
    })
  }

  async function toggleWhiteboardCopyWallpaper(): Promise<void> {
    if (whiteboardRemakeLinkCopyWallpaper.value === null) return
    const oldValue = whiteboardRemakeLinkCopyWallpaper.value
    whiteboardRemakeLinkCopyWallpaper.value = !oldValue
    void queue.enqueue('updateWhiteboardRemakeLinkProps', async () => {
      try {
        await updateWhiteboardRemakeLink({ useOriginalWallpaper: whiteboardRemakeLinkCopyWallpaper.value as boolean })
        trackEvent(
          'AutoWhiteboardRemakeLink',
          'Toggle Copy Wallpaper',
          surfaceStore.wallId,
          whiteboardRemakeLinkCopyWallpaper.value,
          {
            wall_id: surfaceStore.wallId,
          },
        )
      } catch (e) {
        whiteboardRemakeLinkCopyWallpaper.value = oldValue
      }
    })
  }

  async function toggleWhiteboardAddAdminsAsCollaborator(): Promise<void> {
    if (whiteboardRemakeLinkAddAdmins.value === null) return
    const oldValue = whiteboardRemakeLinkAddAdmins.value
    whiteboardRemakeLinkAddAdmins.value = !oldValue
    void queue.enqueue('updateWhiteboardRemakeLinkProps', async () => {
      try {
        await updateWhiteboardRemakeLink({ addAdminsAsCollaborator: whiteboardRemakeLinkAddAdmins.value as boolean })
        trackEvent(
          'AutoWhiteboardRemakeLink',
          'Toggle Add Admins As Collaborator',
          surfaceStore.wallId,
          whiteboardRemakeLinkAddAdmins.value,
          { wall_id: surfaceStore.wallId },
        )
      } catch (e) {
        whiteboardRemakeLinkAddAdmins.value = oldValue
      }
    })
  }

  async function toggleWhiteboardLimitOneRemakePerUser(): Promise<void> {
    if (whiteboardRemakeLinkLimitOneRemakePerUser.value === null) return
    const oldValue = whiteboardRemakeLinkLimitOneRemakePerUser.value
    whiteboardRemakeLinkLimitOneRemakePerUser.value = !oldValue
    void queue.enqueue('updateWhiteboardRemakeLinkProps', async () => {
      try {
        await updateWhiteboardRemakeLink({
          limitToOneRemakePerUser: whiteboardRemakeLinkLimitOneRemakePerUser.value as boolean,
        })
        trackEvent(
          'AutoWhiteboardRemakeLink',
          'Toggle Limit One Remake Per User',
          surfaceStore.wallId,
          whiteboardRemakeLinkLimitOneRemakePerUser.value,
          { wall_id: surfaceStore.wallId },
        )
      } catch (e) {
        whiteboardRemakeLinkLimitOneRemakePerUser.value = oldValue
      }
    })
  }

  async function toggleWhiteboardRemakeLink(): Promise<void> {
    try {
      // Don't allow changes if mid-change
      if (isWhiteboardRemakeLinkLoading.value) return
      if (isWhiteboardRemakeLinkEnabled.value) {
        await askToDisableWhiteboardRemakeLink()
      } else {
        // Only show loading state if toggling to True
        whiteboardRemakeLinkStatus.value = WhiteboardRemakeLinkPanelStatus.Loading
        await enableWhiteboardRemakeLink()
        whiteboardRemakeLinkStatus.value = WhiteboardRemakeLinkPanelStatus.Completed
      }
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelWhiteboardRemakeLinkToggle' })
      whiteboardRemakeLinkStatus.value = WhiteboardRemakeLinkPanelStatus.Error
    }
  }

  function askToDisableWhiteboardRemakeLink(): void {
    void globalConfirmationDialogStore.openConfirmationDialog({
      ...CONFIRM_TURN_OFF,
      title: __('Turn off auto-remake link?'),
      body: __('Current link will no longer work. Turning link back on will create a new link.'),
      afterConfirmActions: [disableWhiteboardRemakeLink],
    })
  }

  function setWhiteboardRemakeLinkAttributes(attributes: WallWhiteboardRemakeLink): void {
    whiteboardRemakeLinkUrl.value = attributes.link
    whiteboardRemakeLinkCopyObjects.value = attributes.copyObjects
    whiteboardRemakeLinkCopyWallpaper.value = attributes.useOriginalWallpaper
    whiteboardRemakeLinkAddAdmins.value = attributes.addAdminsAsCollaborator
    whiteboardRemakeLinkLimitOneRemakePerUser.value = attributes.limitToOneRemakePerUser
  }

  function resetWhiteboardRemakeLinkAttributes(): void {
    whiteboardRemakeLinkUrl.value = null
    whiteboardRemakeLinkCopyObjects.value = null
    whiteboardRemakeLinkCopyWallpaper.value = null
    whiteboardRemakeLinkAddAdmins.value = null
    whiteboardRemakeLinkLimitOneRemakePerUser.value = null
  }

  async function enableWhiteboardRemakeLink(): Promise<void> {
    const linkAttributes = await PadletApi.RemakeLink.enable(surfaceStore.wallId)
    setWhiteboardRemakeLinkAttributes(linkAttributes as WallWhiteboardRemakeLink)
    trackEvent(
      'AutoWhiteboardRemakeLink',
      'Enable Whiteboard Remake Link',
      surfaceStore.wallId,
      whiteboardRemakeLinkUrl.value,
      {
        wall_id: surfaceStore.wallId,
      },
    )
  }

  async function disableWhiteboardRemakeLink(): Promise<void> {
    await PadletApi.RemakeLink.disable(surfaceStore.wallId)
    resetWhiteboardRemakeLinkAttributes()
    whiteboardWallsRemade.value = []
    trackEvent('AutoWhiteboardRemakeLink', 'Disable Whiteboard Remake Link', surfaceStore.wallId, '', {
      wall_id: surfaceStore.wallId,
    })
  }

  return {
    isWhiteboardRemakeLinkEnabled,
    isWhiteboardRemakeLinkLoading,

    whiteboardRemakeLinkUrl,
    whiteboardRemakeLinkCopyObjects,
    whiteboardRemakeLinkCopyWallpaper,
    whiteboardRemakeLinkAddAdmins,
    whiteboardRemakeLinkLimitOneRemakePerUser,

    fetchWhiteboardRemakeLink,
    toggleWhiteboardRemakeLink,
    updateWhiteboardRemakeLink,

    updateWhiteboardCopyObjectsProp,
    toggleWhiteboardCopyWallpaper,
    toggleWhiteboardAddAdminsAsCollaborator,
    toggleWhiteboardLimitOneRemakePerUser,

    isWhiteboardWallsRemadeLoading,
    hasWhiteboardWallsRemade,
    whiteboardWallsRemade,
  }
})
