// @file Comment attachment settings store
import { supportsRecording, supportsVideoRecording } from '@@/bits/av_recorder'
import type { ContentPickerSourceMenuListItem, ContentSource } from '@@/bits/content_picker'
import { ContentPickerSource } from '@@/bits/content_picker'
import device from '@@/bits/device'
import { captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { SearchFilters } from '@@/bits/web_search'
import type { ContentPickerSearchMode } from '@@/pinia/content_picker'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import PadletApi from '@@/surface/padlet_api'
import type { CommentAttachmentSettings } from '@@/types'
import { cloneDeep, isEqual, mapValues } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

const HAS_CAMERA_SUPPORT = !device.zoomApp
const HAS_VIDEO_RECORDING_SUPPORT = !device.zoomApp && (supportsVideoRecording || device.mobile)
const HAS_AUDIO_RECORDING_SUPPORT =
  !device.androidApp && // Disabling for now until we can find a way to accept microphone permissions in android webview
  !device.zoomApp &&
  supportsRecording
const HAS_SCREENSHARE_SUPPORT =
  !device.zoomApp && supportsVideoRecording && !device.mobile && (device.chrome || device.firefox)

export const useCommentAttachmentSettingsStore = defineStore('commentAttachmentSettings', () => {
  const surfaceStore = useSurfaceStore()
  const surfacePermissionsStore = useSurfacePermissionsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  // #region Comment attachment picker sources
  const contentSources = computed<
    Array<
      ContentPickerSourceMenuListItem & {
        type: ContentPickerSource
        icon: string
        activeIcon: string
        color: ContentSource['color']
      }
    >
  >(() => [
    {
      type: ContentPickerSource.FileBrowser,
      text: __('Upload'),
      ariaLabel: __('Upload a file'),
      icon: 'upload_outline',
      activeIcon: 'upload',
      color: 'purple',
      visible: !device.app,
      disabled: false,
      actionType: 'select',
      actionPayload: ContentPickerSource.FileBrowser,
      hiddenInputId: 'content-hidden-media-input',
      testId: 'contentPickerSourceUploadButton',
    },
    {
      type: ContentPickerSource.LinkInput,
      text: __('Link'),
      ariaLabel: __('Attach a link'),
      icon: 'link_outline',
      activeIcon: 'link_filled',
      color: 'purple',
      visible: true,
      disabled: false,
      actionType: 'trigger-link-input',
      testId: 'contentPickerSourceLinkButton',
    },
    {
      type: ContentPickerSource.MediaLibrary,
      text: __('Photo and Video Library'),
      ariaLabel: __('Upload from photo library'),
      icon: 'photo_video_outline',
      activeIcon: 'photo_video',
      color: 'blue',
      visible: device.app,
      disabled: false,
      actionType: 'select',
      actionPayload: ContentPickerSource.MediaLibrary,
      testId: 'contentPickerSourceMediaLibraryButton',
    },
    {
      type: ContentPickerSource.PhotoBooth,
      text: __('Camera'),
      ariaLabel: __('Take a photo'),
      icon: 'camera_outline',
      activeIcon: 'camera',
      color: 'blue',
      visible: HAS_CAMERA_SUPPORT,
      disabled: false,
      actionType: 'select',
      actionPayload: ContentPickerSource.PhotoBooth,
      hiddenInputId: device.mobile ? 'content-hidden-image-input' : undefined, // On mobile devices, trigger a native photo picker instead of our own photobooth component.
      testId: 'contentPickerSourceCameraButton',
    },
    {
      type: ContentPickerSource.VideoRecorder,
      text: __('Video recorder'),
      ariaLabel: __('Record a video'),
      icon: 'video_outline',
      activeIcon: 'video',
      color: 'blue',
      visible: HAS_VIDEO_RECORDING_SUPPORT,
      disabled: false,
      actionType: 'select',
      actionPayload: ContentPickerSource.VideoRecorder,
      hiddenInputId:
        device.mobile && // On mobile devices, trigger a native camera instead of our own camera recorder component
        !isAppUsing('videoRecordingLimits') // New camera features requires that we use our own video recorder component
          ? 'content-hidden-video-input'
          : undefined,
      testId: 'contentPickerSourceVideoButton',
    },
    {
      type: ContentPickerSource.AudioRecorder,
      text: __('Audio recorder'),
      ariaLabel: __('Record an audio'),
      icon: 'microphone_outline',
      activeIcon: 'microphone',
      color: 'blue',
      visible: HAS_AUDIO_RECORDING_SUPPORT,
      disabled: false,
      actionType: 'select',
      actionPayload: ContentPickerSource.AudioRecorder,
      testId: 'contentPickerSourceAudioButton',
    },
    {
      type: ContentPickerSource.ScreenshareRecorder,
      text: __('Screen recorder'),
      ariaLabel: __('Record your screen'),
      icon: 'screen_recorder_outline',
      activeIcon: 'screen_recorder',
      color: 'blue',
      visible: !device.tablet && !device.phone && HAS_SCREENSHARE_SUPPORT,
      disabled: false,
      actionType: 'select',
      actionPayload: ContentPickerSource.ScreenshareRecorder,
      testId: 'contentPickerSourceScreenRecordButton',
    },
    {
      type: ContentPickerSource.DrawingCanvas,
      text: __('Draw'),
      ariaLabel: __('Draw something'),
      icon: 'draw_outline',
      activeIcon: 'draw',
      color: 'blue',
      visible: true,
      disabled: false,
      actionType: 'select',
      actionPayload: ContentPickerSource.DrawingCanvas,
      testId: 'contentPickerSourceDrawButton',
    },
    {
      type: ContentPickerSource.GoogleDrive,
      text: __('Google Drive'),
      ariaLabel: __('Upload from Google Drive'),
      icon: 'google_drive_outline',
      activeIcon: 'google_drive_fill',
      color: 'green',
      isNew: true,
      visible: isAppUsing('googleDrive') && surfacePermissionsStore.amIRegistered,
      disabled: false,
      actionType: 'select',
      actionPayload: ContentPickerSource.GoogleDrive,
      testId: 'contentPickerSourceGoogleDriveButton',
    },
    {
      type: ContentPickerSource.AiImageSearch,
      text: __("I can't draw"),
      ariaLabel: __('Use AI Art Generator'),
      icon: 'sparkles_outline',
      activeIcon: 'sparkles',
      color: 'green',
      isNew: true,
      visible: isAppUsing('aiImageSearch'),
      disabled: false,
      actionType: 'trigger-ai-image-search',
      testId: 'contentPickerSourceAiImageSearchButton',
    },
    {
      type: ContentPickerSource.ImageSearch,
      text: __('Image search'),
      ariaLabel: __('Search for an image'),
      icon: 'image_search_outline',
      activeIcon: 'image_search',
      color: 'orange',
      visible: true,
      disabled: false,
      actionType: 'focus-search-bar',
      actionPayload: SearchFilters.Images,
      testId: 'contentPickerSourceImageSearchButton',
    },
    {
      type: ContentPickerSource.GifSearch,
      text: __('GIF'),
      ariaLabel: __('Search for a GIF'),
      icon: 'gif',
      activeIcon: 'gif',
      color: 'orange',
      visible: true,
      disabled: false,
      actionType: 'focus-search-bar',
      actionPayload: SearchFilters.Gif,
      testId: 'contentPickerSourceGifSearchButton',
    },
    {
      type: ContentPickerSource.VideoSearch,
      text: __('YouTube'),
      ariaLabel: __('Search on YouTube'),
      icon: 'youtube_outline',
      activeIcon: 'youtube',
      color: 'orange',
      visible: true,
      disabled: false,
      actionType: 'focus-search-bar',
      actionPayload: SearchFilters.Videos,
      testId: 'contentPickerSourceVideoSearchButton',
    },
  ])

  const searchModes = computed<Array<ContentPickerSearchMode & { type: ContentPickerSource }>>(() => [
    { key: SearchFilters.Images, type: ContentPickerSource.ImageSearch, text: __('Images'), visible: true },
    { key: SearchFilters.Gif, type: ContentPickerSource.GifSearch, text: __('GIF'), visible: true },
    { key: SearchFilters.Videos, type: ContentPickerSource.VideoSearch, text: __('YouTube'), visible: true },
  ])
  // #endregion

  const defaultCommentAttachmentSettings = computed<CommentAttachmentSettings>(() => {
    return contentSources.value
      .filter((source) => source.visible)
      .reduce((acc, source) => {
        acc[source.type] = true
        return acc
      }, {})
  })

  const commentAttachmentSettings = ref<CommentAttachmentSettings>(defaultCommentAttachmentSettings.value)

  const isPreviewingCommentAttachmentSettings = ref(false)
  const previewCommentAttachmentSettings = ref<CommentAttachmentSettings>({})

  const displayedCommentAttachmentSettings = computed<CommentAttachmentSettings>(() => {
    return isPreviewingCommentAttachmentSettings.value
      ? previewCommentAttachmentSettings.value
      : commentAttachmentSettings.value
  })

  // #region Comment attachment settings getters
  const areAllAttachmentsDisabled = computed<boolean>(() => {
    const defaultKeys = Object.keys(defaultCommentAttachmentSettings.value)
    return defaultKeys.every((key) => !displayedCommentAttachmentSettings.value[key])
  })

  const areAllAttachmentsEnabled = computed<boolean>(() => {
    const defaultKeys = Object.keys(defaultCommentAttachmentSettings.value)
    return defaultKeys.every((key) => displayedCommentAttachmentSettings.value[key])
  })

  const isPreviewCommentAttachmentSettingsDirty = computed<boolean>(() => {
    return (
      isPreviewingCommentAttachmentSettings.value &&
      !isEqual(commentAttachmentSettings.value, previewCommentAttachmentSettings.value)
    )
  })

  const isAttachmentTypeEnabled = (contentSource: ContentPickerSource): boolean => {
    return displayedCommentAttachmentSettings.value[contentSource]
  }
  // #endregion

  // #region Comment attachment settings actions
  const setCommentAttachmentSettings = (settings: CommentAttachmentSettings): void => {
    commentAttachmentSettings.value = {
      ...commentAttachmentSettings.value,
      ...settings,
    }
  }

  const fetchCommentAttachmentSettingsAndSaveToStore = async (wallHashid: string): Promise<void> => {
    try {
      const settings = await PadletApi.CommentAttachmentSettings.fetch(wallHashid)
      setCommentAttachmentSettings(settings)
    } catch (e) {
      captureFetchException(e, { source: 'fetchCommentAttachmentSettingsAndSaveToStore' })
    }
  }

  const setPreviewCommentAttachmentSettings = (settings: CommentAttachmentSettings): void => {
    previewCommentAttachmentSettings.value = {
      ...previewCommentAttachmentSettings.value,
      ...settings,
    }
  }

  const startPreviewingCommentAttachmentSettings = (): void => {
    isPreviewingCommentAttachmentSettings.value = true
    previewCommentAttachmentSettings.value = cloneDeep(commentAttachmentSettings.value)
  }

  const stopPreviewingCommentAttachmentSettings = (): void => {
    isPreviewingCommentAttachmentSettings.value = false
    previewCommentAttachmentSettings.value = {}
  }

  const saveCommentAttachmentSettingsToServer = async (): Promise<void> => {
    try {
      const updatedSettings = await PadletApi.CommentAttachmentSettings.update(
        surfaceStore.wallHashid,
        previewCommentAttachmentSettings.value,
      )
      setCommentAttachmentSettings(updatedSettings)
      setPreviewCommentAttachmentSettings(cloneDeep(updatedSettings))
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'saveCommentAttachmentSettingsToServer' })
    }
  }

  const toggleAttachmentType = (contentSource: ContentPickerSource): void => {
    setPreviewCommentAttachmentSettings({
      [contentSource]: !isAttachmentTypeEnabled(contentSource),
    })
  }

  const disableAllAttachments = (): void => {
    setPreviewCommentAttachmentSettings(mapValues(defaultCommentAttachmentSettings.value, () => false))
  }

  const enableAllAttachments = (): void => {
    setPreviewCommentAttachmentSettings(mapValues(defaultCommentAttachmentSettings.value, () => true))
  }
  // #endregion

  // #region Available content sources and search filters to show in content picker
  const enabledContentSources = computed<typeof contentSources['value']>(() => {
    return contentSources.value.filter((source) => isAttachmentTypeEnabled(source.type))
  })

  const enabledSearchModes = computed<ContentPickerSearchMode[]>(() => {
    return searchModes.value.filter((mode) => isAttachmentTypeEnabled(mode.type))
  })
  // #endregion

  return {
    commentAttachmentSettings,
    previewCommentAttachmentSettings,
    areAllAttachmentsDisabled,
    areAllAttachmentsEnabled,
    contentSources,
    isPreviewCommentAttachmentSettingsDirty,
    enabledContentSources,
    enabledSearchModes,

    isAttachmentTypeEnabled,
    startPreviewingCommentAttachmentSettings,
    stopPreviewingCommentAttachmentSettings,
    toggleAttachmentType,
    disableAllAttachments,
    enableAllAttachments,
    setCommentAttachmentSettings,
    fetchCommentAttachmentSettingsAndSaveToStore,
    saveCommentAttachmentSettingsToServer,
  }
})
