// @file This file contains exports that can be used in rails without
// importing dependecy on `tldraw`.
// When adding new exports, please ensure that file does not import any dependency on `tldraw`.
// Eg We should not import any files from `@@/bits/whiteboard` since it has a dependecy on `tldraw`.
import { isAppCapableOf } from '@@/bits/app_can'
import device from '@@/bits/device'
import { isAppUsing } from '@@/bits/flip'

export const WHITEBOARD_ATTACHMENT_PADDING = 0

export function shouldEnableWhiteboardInPlaceUploadingAnimation(): boolean {
  return (
    (!device.app && isAppUsing('sandboxInPlaceUploadingAnimation')) ||
    isAppCapableOf('whiteboardInPlaceUploadingAnimation')
  )
}
