/**
 * @file Contains helpers to handle surface post properties.
 * */

import type { WallPostProperties } from '@@/types'
import { isEqual } from 'lodash-es'

function getWallPostPropertiesDiff(
  previewWallPostProperties: WallPostProperties,
  savedWallPostProperties: WallPostProperties,
): Partial<WallPostProperties> {
  const diff: Partial<WallPostProperties> = {}

  for (const key in previewWallPostProperties) {
    const typedKey = key as keyof WallPostProperties
    if (!isEqual(previewWallPostProperties[typedKey], savedWallPostProperties[typedKey])) {
      diff[typedKey] = previewWallPostProperties[typedKey]
    }
  }

  return diff
}

export { getWallPostPropertiesDiff }
