/* eslint-disable no-param-reassign */

/**
 * @file Surface state
 */
import { trackEvent } from '@@/bits/analytics'
import { shadeColor } from '@@/bits/color'
import { DELETE_WALL_CONFIRMATION_PARAMS } from '@@/bits/confirmation_dialog'
import { setCookie } from '@@/bits/cookie'
import { WAVING_HAND_EMOJI } from '@@/bits/emoji'
import { captureFetchException, captureNonNetworkFetchError, setScope } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { isValidFolderName, MAX_FOLDER_NAME_LENGTH } from '@@/bits/folder'
import window from '@@/bits/global'
import { isCid, removeHashidPrefix } from '@@/bits/hashid_helper'
import { __ } from '@@/bits/intl'
import type { UrlOptions } from '@@/bits/location'
import {
  buildUrlFromPath,
  clearSearchParam,
  currentHostname,
  currentHostWithProtocol,
  getHostnameFromUrl,
  loginWithReferrerUrl,
  navigateTo,
  reload,
  transformUrl,
} from '@@/bits/location'
import { getNotificationPermission, registerForPushNotifications } from '@@/bits/notifications'
import { numberToHumanSize } from '@@/bits/numbers_helper'
import { safeLocalStorage } from '@@/bits/safe_storage'
import { vSet } from '@@/bits/vue'
import { LibraryType, ParamCommentModerationActionResult, ParamPostModerationActionResult } from '@@/enums'
import anxiousFace from '@@/images/anxious_face.svg'
import { useContentPickerStore } from '@@/pinia/content_picker'
import { useExpandedPostStore } from '@@/pinia/expanded_post'
import { useGlobalAlertDialogStore } from '@@/pinia/global_alert_dialog'
import {
  OzConfirmationDialogBoxButtonScheme,
  useGlobalConfirmationDialogStore,
} from '@@/pinia/global_confirmation_dialog'
import { useGlobalInputDialogStore } from '@@/pinia/global_input_dialog'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { UpgradeStep as LibraryUpgradeStep, useLibraryPlansStore } from '@@/pinia/library_plans'
import { UpgradeSource, UpgradeStep, usePersonalPlansStore } from '@@/pinia/personal_plans_store'
import { useSurfaceActivityPanelStore } from '@@/pinia/surface_activity_panel'
import { useSurfaceCollectionsStore } from '@@/pinia/surface_collections_store'
import { useCommentEditMode } from '@@/pinia/surface_comments'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfaceContributingStatusStore } from '@@/pinia/surface_contributing_status'
import { useSurfaceDetailsPanelStore } from '@@/pinia/surface_details_panel'
import { useSurfaceFilterStore } from '@@/pinia/surface_filter'
import { useSurfaceFreezePadletPanel } from '@@/pinia/surface_freeze_padlet_panel'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfaceMapStore } from '@@/pinia/surface_map'
import { useSurfacePostActionStore } from '@@/pinia/surface_post_action'
import { useSurfacePostConnectionStore } from '@@/pinia/surface_post_connection'
import { useReactionsStore } from '@@/pinia/surface_reactions'
import { useSurfaceRemakePanelStore } from '@@/pinia/surface_remake_panel'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useSurfaceSharePanelStore } from '@@/pinia/surface_share_panel'
import { useSurfaceUserContributorsStore } from '@@/pinia/surface_user_contributors'
import PadletApi from '@@/surface/padlet_api'
import type {
  CanSetWallPrivacyOptions,
  Cid,
  GoogleAppLicensingValidateResponse,
  Id,
  Library,
  Post,
  UserLibrarySettingsApiResponse,
  WallFollowInformation,
} from '@@/types'
import { MobilePage } from '@@/types/dash'
import comment from '@@/vuexstore/modules/comment'
import composerModalAlert from '@@/vuexstore/modules/composer_modal_alert'
import map from '@@/vuexstore/modules/map'
import post from '@@/vuexstore/modules/post'
import realtime from '@@/vuexstore/modules/realtime'
import settings from '@@/vuexstore/modules/surface_settings'
import logger, { shouldLog } from '@@/vuexstore/plugins/logger'
import type { RootState, SurfaceState } from '@@/vuexstore/surface/types'
import type { ContentModeration, JsonAPIResource, User } from '@padlet/arvo'
import { deleteWall, GroupByTypes, SortByTypes } from '@padlet/arvo'
import { captureException } from '@sentry/vue'
import { merge, sortBy } from 'lodash-es'
import type { Plugin, StoreOptions } from 'vuex'

// How to decide what to put in state vs getters
// State should have either:
// 1. things we sync with the server
// 2. things that change
// 3. things that are a function of the device
// Everything else is in getters.

// Keep all lists alphabetical
const plugins: Array<Plugin<RootState>> = [
  // pepinTracker    // Uncomment to track all state changes
]
if (shouldLog) plugins.push(logger)

export const blankState = (): SurfaceState => ({
  /* CONSTANTS */
  arvoConfig: undefined,
  canPostMessage: false,
  constants: {},
  cookiesEnabled: false,
  csrfToken: '',
  defaultPdfPageSize: 'a4',
  deviceId: '',
  dir: '',
  dpr: 1,
  environment: 'production',
  isAndroid: false,
  isApp: false,
  isChrome: false,
  isDesktop: false,
  isElectronApp: false,
  isEdge: false,
  isEmbedded: false,
  isLtiEmbedded: false,
  isWhiteboardEmbedPlayMode: false,
  isFirefox: false,
  isIE: false,
  isIOS: false,
  isIPhone: false,
  isIPhoneX: false,
  isMac: false,
  isMobile: false,
  isMousable: false,
  isNativeMenu: false,
  isOldApp: false,
  isPhone: false,
  isSafari: false,
  isTablet: false,
  isTouchable: false,
  isWindows: false,
  locale: 'en',
  modalPostEdit: false,
  nativePostEdit: false,
  release: '',
  uid: '',
  xHeader: false,
  homeUrl: currentHostWithProtocol(),

  /* OBJECTS */
  tenant: {},
  user: {},
  wall: {},

  /* OTHERS */
  commentsPanelPostId: null,
  editorHeight: 0,
  fileThatExceededSize: null,
  headerHeight: 128,
  isOnline: true,
  wallLastSyncTime: null,
  wishListMounted: false,
  xWallQuotaExceeded: false,
  xEditBookmarksDialog: false,
  editBookmarksDialogPopoverAnchor: null,
  isAPostDragging: false,
  isSlideshow: false,
  isGeneratingPDF: false,
  breakoutSectionId: null,
  sectionBreakoutIdentifier: null,
  isSubmissionRequest: false,
  isFirstView: false,
  isMagicWall: false,
  magicWallType: null,
  reportedPostId: null,
  isUsedInEducationalContext: false,
  xFilterProfanitySetting: false,

  /* PANELS */
  xCommentsPanel: false,
  xPermitNotification: false,
  xReloadNotification: false,
  xRemakePanelOld: false,
  xToolMenu: false,
  xReportPanel: false,
  xDevelopersPanel: false,
  xActionBarMoreMenu: false,
  isSkoletubeUser: false,

  /* PERMISSIONS */
  amIOwner: false,
  amILibraryOwner: false,
  canIAdminister: false,
  canIEdit: false,
  canIMakeWalls: false,
  canIModerate: false,
  canIRemake: false,
  canIComment: false,
  canIReact: false,
  canIWrite: false,
  canIAccessContentDirectly: true,
  isSubscriptionPaused: false,
  isReadOnly: true,
  isScreenshotMode: false,
  isSimplifiedView: false,
  xAuthorInScreenshotMode: true,
  xCommentsInScreenshotMode: true,
  xReactionsInScreenshotMode: true,
  amICollaborator: false,
  blockedContentProviders: new Set<string>(),
  canIMakePrivateWalls: false,
  canIMakeOrgWideDashboardWalls: false,
  canIMakeOrgWideHiddenWalls: false,
  canIMakePasswordWalls: false,
  canIMakeSecretWalls: false,
  canIMakeLibraryWideDashboardWalls: false,
  canIMakeLibraryWideHiddenWalls: false,
  canIMakeSecretLoggedInVisitorWalls: false,
  canIMakePublicWalls: false,
  canUseLibraryAsMember: false,
  canMakeInTenant: false,

  /* LIBRARIES */
  library: {},
  creatableLibraries: [], // Libraries where user can create
  viewableLibraries: [], // Libraries where user can view
  userLibrarySettings: {},

  /* EMBED */
  loginAndRedirectToWallUrl: '',
  loginAndRedirectToBuilderProfileUrl: '',
  loginAndRedirectUrlExpireDuration: 0,

  /* FOLLOWS */
  wall_follows: {
    current_user_follows_wall: false,
    wall_follow_id: null,
    wall_follows_count: 0,
  },

  shouldFallbackWsDataFetchToRest: false,

  /* TRANSFER WALL */
  isWallTransferable: false,
})

export const storeStructure: StoreOptions<RootState> = {
  plugins,
  modules: {
    comment,
    post,
    settings,
    map,
    realtime,
    composerModalAlert,
  },
  state: blankState() as RootState,
  getters: {
    // constants
    sectionableLayouts: (state) => state.constants.sectionableLayouts,
    // wall
    wall: (state) => state.wall,
    displayAttrs: (state, getters) =>
      getters['settings/isPreviewing'] ? getters['settings/previewAttributes'] : state.wall,
    wallId: (state) => state.wall.id,
    wallHashid: (state) => state.wall.hashid,
    wallUpdatedAt: (state) => state.wall.updated_at,
    wallCreatedAt: (state) => state.wall.created_at,
    originalBackground: (state) => state.wall.background,
    title: (state, getters) => getters.displayAttrs.title,
    description: (state, getters) => getters.displayAttrs.description,
    background: (state, getters) => getters.displayAttrs.background,
    portrait: (state, getters) => getters.displayAttrs.portrait,
    viz: (state, getters) => getters.displayAttrs.viz, // reflects true WallViz. (for styling, use format)
    contentModerationSetting: (state, getters): ContentModeration => getters.displayAttrs.content_moderation,
    postSize: (state, getters) => getters.displayAttrs.post_size,
    user: (state) => state.user,
    newPostLocation: (state, getters) => getters.displayAttrs.new_wish_loc,
    canConfigNewPostLocation: (state) => state.constants.newPostLocationConfigurableLayouts.includes(state.wall.viz),
    filterProfanity: (state, getters) => getters.displayAttrs.filter_profanity,
    requireApproval: (state, getters) => getters.displayAttrs.require_approval,
    isUsedInEducationalContext: (state) => state.isUsedInEducationalContext,
    xFilterProfanitySetting: (state) => state.xFilterProfanitySetting ?? false,
    isFrozen: (state) => state.wall.frozen === true,
    // Background/skin, the skin colors are based on the background for now but they may change in the future
    backgroundColor: (state, getters) =>
      getters.displayAttrs.background.primary_color_as_rgb ?? getters.displayAttrs.background.dominant_color_as_rgb,
    headerColor: (state, getters) =>
      getters.isMap
        ? !getters.backgroundColor
          ? getters.colorScheme === 'light'
            ? '#FFFFFF'
            : '#000000'
          : getters.backgroundColor
        : getters.isTitleBarTransparent
        ? 'transparent'
        : getters.backgroundColor,
    backgroundLuminance: (state, getters) => getters.displayAttrs.background.luminance_name,
    isBackgroundBlurred: (state, getters) =>
      Array.isArray(getters.background.effects)
        ? getters.background.effects.includes('blur')
        : getters.background.effect === 'blur',
    skinPrimaryColorAsRGB: (state, getters) => getters.displayAttrs.background.primary_color_as_rgb,
    skinPrimaryVariantColorAsRGB: (_, getters) => {
      if (getters.skinPrimaryColorAsRGB != null) {
        return getters.skinPrimaryColorAsRGB
      }
      const originalColor = getters.displayAttrs.background.dominant_color_as_rgb
      if (getters.displayAttrs.background.type === 'solid_color' && originalColor != null) {
        // 25% ligher for light backgrounds, 10% darker for dark backgrounds
        return shadeColor(originalColor, getters.backgroundLuminance === 'light' ? 25 : -10)
      }
      return originalColor
    },
    skinAccentColorAsRGB: (state, getters) => getters.displayAttrs.background.accent_color_as_rgb,
    skinPrimaryTextColor: (state, getters) => getters.displayAttrs.background.primary_text_color,
    skinAccentTextColor: (state, getters) => getters.displayAttrs.background.accent_text_color,

    colorScheme: (state, getters) => getters.displayAttrs.color_scheme,
    themeId: (state, getters) => getters.displayAttrs.theme_id, // used for map
    fontId: (state, getters) => getters.displayAttrs.font_id,
    isCommentable: (state, getters) => getters.displayAttrs.is_commentable,
    isReactable: (state, getters) => getters.displayAttrs.is_reactable,
    isRemakeable: (_, getters) => getters.displayAttrs.is_remakeable,
    reactionData: (state, getters) => getters.displayAttrs.reaction_data,
    freezeBannerInformation: (state, getters) => getters.displayAttrs.freeze_banner_information,
    name: (state, getters) => getters.displayAttrs.name,
    xAuthor: (state, getters) => getters.displayAttrs.show_author,
    isTitleBarTransparent: (state, getters) => Boolean(getters.displayAttrs.is_title_bar_transparent),
    isSlideshow: (state, getters) => state.isSlideshow,
    breakoutSectionId: (state, getters) => state.breakoutSectionId,
    isGeneratingPDF: (state, getters) => state.isGeneratingPDF,
    isSectionBreakout: (state, getters) => state.breakoutSectionId !== null,
    sectionBreakoutIdentifier: (state, getters) => state.sectionBreakoutIdentifier,
    isSubmissionRequest: (state, getters) => state.isSubmissionRequest,

    builder: (state) => state.wall.builder,
    namespace: (state) => state.wall.namespace,
    isOrgWall: (state) => state.wall.tenant_id > 1,
    coverPostId: (state) => state.wall.cover_wish_id,
    publicKey: (state) => state.wall.public_key,
    address: (state) => state.wall.address,
    domainName: (state) => state.tenant.domain_name,
    tenantName: (state) => state.tenant.name,
    isTemplate: (state) => state.wall.is_template,

    // Permissions
    canIAdminister: (state) => state.canIAdminister,
    canIModerate: (state) => state.canIModerate,
    canIComment: (state) => state.canIComment,
    canIReact: (state) => state.canIReact,
    canIWrite: (state) => state.canIWrite,
    canIEdit: (state) => state.canIEdit,
    canIMakeWalls: (state) => state.canIMakeWalls,
    canIRemake: (state) => state.canIRemake,
    canIBookmarkWall: (state, getters) => getters.amIRegistered,
    canIAccessContentDirectly: (state, getters) => state.canIAccessContentDirectly,
    canIChangeCoverPost: (state) => state.canIAdminister,
    amIOwner: (state) => state.amIOwner,
    amILibraryOwner: (state) => state.amILibraryOwner,
    amICollaborator: (state) => state.amICollaborator,
    amIRegistered: (state) => !!state.user.registered_at,
    isSubscriptionPaused: (state) => state.isSubscriptionPaused,
    canICreateWall: (state) => state.user.quota.can_make,
    canIPostWithoutWaitingForApproval: (state, getters) =>
      getters.canIWrite && (getters.canIModerate || !getters.requireApproval),
    blockedContentProviders: (state) => state.blockedContentProviders,
    canIMakePrivateWalls: (state) => state.canIMakePrivateWalls,
    canIMakeOrgWideDashboardWalls: (state) => state.canIMakeOrgWideDashboardWalls,
    canIMakeOrgWideHiddenWalls: (state) => state.canIMakeOrgWideHiddenWalls,
    canIMakePasswordWalls: (state) => state.canIMakePasswordWalls,
    canIMakeSecretWalls: (state) => state.canIMakeSecretWalls,
    canIMakeLibraryWideDashboardWalls: (state) => state.canIMakeLibraryWideDashboardWalls,
    canIMakeLibraryWideHiddenWalls: (state) => state.canIMakeLibraryWideHiddenWalls,
    canIMakeSecretLoggedInVisitorWalls: (state) => state.canIMakeSecretLoggedInVisitorWalls,
    canIMakePublicWalls: (state) => state.canIMakePublicWalls,
    shouldPostsBeAutoModerated: (state, getters) => !getters.canIEdit && getters.contentModerationSetting === 'auto',
    canUseLibraryAsMember: (state) => state.canUseLibraryAsMember,

    isApp: (state) => state.isApp,
    isPhone: (state) => state.isPhone,
    isDesktop: (state) => state.isDesktop,
    isMousable: (state) => state.isMousable,
    dir: (state) => state.dir,
    isReadOnly: (state) => state.isReadOnly,
    isScreenshotMode: (state) => state.isScreenshotMode,
    isSimplifiedView: (state) => state.isSimplifiedView,
    canIPost: (state, getters) =>
      (state.canIWrite && !state.isReadOnly && !(getters.isFrozen as boolean)) || state.isSubmissionRequest,
    format: (state, getters) => {
      // reflects layout format for display styling (for true wall format, use viz)
      if (getters.isSectionBreakout === true) return getters.displayAttrs.viz === 'map' ? 'map' : 'grid'
      return getters.displayAttrs.viz
    },
    isCanvas: (state, getters) => getters.format === 'free',
    isMap: (state, getters) => getters.format === 'map',
    isShelf: (state, getters) => getters.format === 'shelf',
    isStream: (state, getters) => getters.format === 'stream',
    isGrid: (state, getters) => getters.format === 'grid',
    isTable: (state, getters) => getters.format === 'table',
    isTimeline: (state, getters) => getters.format === 'timeline' || getters.format === 'timeline_v2',
    isTimelineV1: (state, getters) => getters.format === 'timeline',
    isTimelineV2: (state, getters) => getters.format === 'timeline_v2',
    isMatrix: (state, getters) => getters.format === 'matrix',
    isSectioned: (_state, getters) => (getters.isShelf as boolean) || getters.wishGroupBy === GroupByTypes.Section,
    isFirstView: (state) => state.isFirstView,
    isMagicWall: (state) => state.magicWallType !== null,
    magicWallType: (state) => state.magicWallType,
    canHaveConnections: (state, getters) => getters.isCanvas || getters.isMap,
    xUnpublishedPostPosition: (state, getters) => getters.isTable,
    uploadLimit: (state) => state.wall.upload_limit,
    advertizedUploadLimit: (state) => state.wall.advertized_upload_limit,
    isPremiumWall: (state) => state.wall.is_premium, // wall made by a pro user
    links: (state) => state.wall.links,
    xSettingsPanel: (state, getters) => getters['settings/isOpen'],
    isOverlayVisible: (state, getters) => {
      return (
        // Side panels
        useSurfaceDetailsPanelStore().xSurfaceDetailsPanel || // About this padlet panel
        state.xRemakePanelOld ||
        getters.xReportPanel ||
        getters.xDevelopersPanel ||
        getters.xSettingsPanel ||
        useSurfaceSharePanelStore().xSurfaceSharePanel ||
        useSurfacePostActionStore().xTransferPostPanel ||
        useSurfacePostActionStore().xCopyPostPanel ||
        useSurfaceActivityPanelStore().xActivityPanel ||
        useSurfaceRemakePanelStore().xRemakePanel ||
        useSurfaceFreezePadletPanel().xSurfaceFreezePanel ||
        useSurfaceMapStore().isPickingLocation ||
        // Modals
        useCommentEditMode().shouldEditCommentsInModal.value || // Edit Comment modal on mobile
        useReactionsStore().postIdBeingReactedTo || // Reaction modal on mobile
        useSurfaceUserContributorsStore().xContributorsList ||
        state.xWallQuotaExceeded ||
        getters.xEditBookmarksDialog ||
        useContentPickerStore().xContentPickerPanel ||
        useLibraryPlansStore().xUpgradeModal ||
        useLibraryPlansStore().plans.gold.annual.currency?.toLowerCase() ||
        usePersonalPlansStore().xUpgradeModal ||
        useSurfacePostActionStore().xPostAction ||
        useSurfaceFilterStore().xFilterModal ||
        useSurfaceSectionsStore().xSectionRenameModal ||
        // Menus
        state.comment.actionMenuCommentId ||
        getters.xActionBarMoreMenu ||
        useSurfaceSectionsStore().sectionUnderActionId ||
        // Dialogs
        useGlobalAlertDialogStore().isOpen ||
        useGlobalConfirmationDialogStore().isOpen ||
        // Legacy
        state.xCommentsPanel ||
        state.xToolMenu ||
        getters['section/sectionUnderActionId']
      )
    },
    xHeader: (state) => state.xHeader,
    headerHeight: (state) => state.headerHeight,
    isWallSyncNeeded: (state, getters) => {
      const lastSycnedTime = state.wallLastSyncTime
      if (!lastSycnedTime) return true
      return getters['realtime/isRealtimeDownSinceLastSync'](lastSycnedTime)
    },
    xEditBookmarksDialog: (state) => state.xEditBookmarksDialog,
    editBookmarksDialogPopoverAnchor: (state) => state.editBookmarksDialogPopoverAnchor,

    /* POST MODIFIERS */
    xTallAttachmentPickerBox: (state, getters) => getters.isMap || getters.isTimeline || getters.isTable,
    // pagination - only posts for now
    isInitialPaginatedDataLoadDone: (state, getters) => getters['post/isInitialPaginatedDataLoadDone'],
    nativePostEdit: (state) => state.nativePostEdit,
    modalPostEdit: (state) => state.modalPostEdit,
    xNewComposerModalPanel: (_, getters) => {
      // show composer modal panel even if canIWrite = false
      // Allow users to see their unpublished drafts, even if their write permissions have been revoked.
      return !getters.nativePostEdit && !getters.isReadOnly
    },

    /* Surface Layout */
    xActionBarMoreMenu: (state): boolean => state.xActionBarMoreMenu,
    isDesktopLayout: (_, getters) =>
      !getters.isApp && !useSurfaceContainerSizeStore().isContainerSmallerThanTabletLandscape,
    isMobileLayout: (_, getters) =>
      !getters.isApp && useSurfaceContainerSizeStore().isContainerSmallerThanTabletLandscape,
    canFilterPosts: (_, getters) => !getters.isApp,
    canUseSections: (_, getters) => getters.sectionableLayouts.includes(getters.format),
    defaultWishSortBy: (state, getters) => {
      // matches default sort_by in wall_wish_arrangements.rb
      if (getters.isTimeline || getters.isShelf) return SortByTypes.ManualNewPostsLast
      return SortByTypes.ManualNewPostsFirst
    },
    wishGroupBy: (state, getters) => {
      if (!getters.canUseSections) return GroupByTypes.None
      return state.wall.wish_arrangement.group_by ?? GroupByTypes.None
    },
    wishSortBy: (state, getters) => {
      return state.wall.wish_arrangement?.sort_by
    },
    isSectionable: (state) => state.wall.wish_arrangement.is_sectionable,

    isAPostDragging: (state) => state.isAPostDragging,
    /* User Oauth Token */
    oauthToken: (state) => state.arvoConfig != null && state.arvoConfig.token && state.arvoConfig.token.oauthToken,

    // Libraries
    isLibraryWall: (state) => Boolean(state.wall.library_id),
    wallLibraryId: (state) => state.wall.library_id,
    creatableLibraries: (state) => sortBy(state.creatableLibraries, 'joinedAt'),
    currentVisibleAndCreatableLibraries: (_, getters) => {
      // A library is considered visible if it is the current wall's library or has visibility as true
      return getters.creatableLibraries.filter(
        (creatableLibrary: Library) =>
          creatableLibrary.visible === true || creatableLibrary.id === getters.wallLibraryId,
      )
    },
    creatableLibraryById: (_, getters) => (libraryId) =>
      getters.creatableLibraries.find((creatableLibrary) => creatableLibrary?.id == libraryId),
    viewableLibraries: (state) => sortBy(state.viewableLibraries, 'joinedAt'),
    wallInViewableLibraries: (state, getters) =>
      getters.viewableLibraries.map((library) => parseInt(library.id)).includes(state.wall.library_id),
    currentVisibleAndViewableLibraries: (_, getters) => {
      // A library is considered visible if it is the current wall's library or has visibility as true
      return getters.viewableLibraries.filter(
        (viewableLibrary: Library) => viewableLibrary.visible === true || viewableLibrary.id === getters.wallLibraryId,
      )
    },
    wallInCreatableLibraries: (state, getters) =>
      getters.creatableLibraries.map((library) => parseInt(library.id)).includes(state.wall.library_id),
    library: (state) => state.library,
    libraryName: (state) => state.library?.name,
    librarySlug: (state) => state.library?.slug,
    libraryDashboardUrl: (state) => state.library?.dashboard_url,
    libraryMakersCount: (state) => state.library?.makers_count,
    libraryType: (state) => state.library?.libraryType,
    isSchoolLibrary: (state) => state.library?.libraryType === LibraryType.School,
    isClassroomLibrary: (state) => state.library?.libraryType === LibraryType.Classroom,
    libraryWallsUsed: (state) => state.library?.walls_used,
    organizationName: (_, getters) => (getters.isLibraryWall ? getters.libraryName : getters.tenantName),
    defaultLibrary: (state) => state.userLibrarySettings?.defaultLibrary,
    librarySettings: (state) => state.userLibrarySettings?.librarySettings,
    isPersonalLibraryVisible: (_, getters) =>
      getters.librarySettings?.find((librarySetting) => librarySetting.libraryType === LibraryType.Personal)?.visible,
    xPersonalLibrary: (_, getters) => {
      // Show personal library if the user owns the wall and it is a non-library wall
      if (getters.isBuilderCurrentUser === true && getters.isLibraryWall === false) {
        return true
      }

      // We show the personal library for non-native wall
      if (getters.isOrgWall === true) return true

      return getters.isPersonalLibraryVisible === true
    },
    isLibraryDeprecatedFreeTier: (state) => state.library.isDeprecatedFreeTier,
    isBuilderCurrentUser: (_, getters) => getters.user.id === getters.builder.id,
    defaultLibraryId: (_, getters) => {
      if (getters.wallInCreatableLibraries === true) {
        return getters.wall.library_id
      }

      // Set personal library as the default if the user owns the wall
      if (getters.isBuilderCurrentUser === true) {
        return null
      }

      if (getters.defaultLibrary?.libraryId !== undefined) {
        const PERSONAL_LIBRARY_ID = ''

        // Return the user's personal library is selected as the default
        // null represents the user's personal library
        if (getters.defaultLibrary.libraryId === PERSONAL_LIBRARY_ID) {
          return null
        }

        return getters.defaultLibrary.libraryId
      }

      return null
    },
    // Embed
    loginAndRedirectToWallUrl: (state) => state.loginAndRedirectToWallUrl,
    loginAndRedirectToBuilderProfileUrl: (state) => state.loginAndRedirectToBuilderProfileUrl,
    loginAndRedirectUrlExpireDuration: (state) => state.loginAndRedirectUrlExpireDuration,
    homeUrl: (state, getters) => {
      const referrer = document.referrer
      // If there is a referrer and the referrer is an internal link
      // we return the referrer as the home url
      if (referrer !== '' && getHostnameFromUrl(referrer) === currentHostname() && referrer.includes('/dashboard')) {
        return referrer
      }
      if (!getters.isLibraryWall && getters.isBuilderCurrentUser) {
        const dashboardUrl = buildUrlFromPath('dashboard')
        return transformUrl(dashboardUrl, {
          searchParams: {
            filter: 'made',
            mobile_page: 'Collection',
          },
        })
      }
      if (getters.isLibraryWall && getters.canUseLibraryAsMember) {
        return transformUrl(getters.library.dashboard_url, {
          searchParams: {
            filter: 'all',
            mobile_page: 'Collection',
          },
        })
      }
      return state.homeUrl
    },
    // Follows
    wallFollowId: (state): Id | null => state.wall_follows.wall_follow_id,
    wallFollowsCount: (state): number => state.wall_follows.wall_follows_count,
    amIFollowingWall: (state): boolean => state.wall_follows.current_user_follows_wall,
    // Abuse Report
    xReportPanel: (state): boolean => state.xReportPanel,
    xDevelopersPanel: (state): boolean => state.xDevelopersPanel,
    reportedPostId: (state): Id | null => state.reportedPostId,
  },
  mutations: {
    INITIALIZE_STATE(state, data) {
      Object.assign(state, merge({}, state, data))
    },
    CHANGE_CONNECTIVITY(state, data) {
      state.isOnline = data.isOnline
    },
    CHANGE_DRAGGING(state, { isAPostDragging }) {
      state.isAPostDragging = isAPostDragging
    },
    UPDATE_FILE_THAT_EXCEEDED_SIZE(state, file) {
      state.fileThatExceededSize = file
    },
    HIDE_WALL_QUOTA_EXCEEDED(state) {
      state.xWallQuotaExceeded = false
    },
    HIDE_COMMENTS_PANEL(state) {
      state.xCommentsPanel = false
      state.commentsPanelPostId = null
    },
    HIDE_PERMIT_NOTIFICATION(state) {
      state.xPermitNotification = false
    },
    SHOW_RELOAD_NOTIFICATION(state) {
      state.xReloadNotification = true
    },
    HIDE_RELOAD_NOTIFICATION(state) {
      state.xReloadNotification = false
    },
    SHOW_WALL_QUOTA_EXCEEDED(state) {
      state.xWallQuotaExceeded = true
    },
    SHOW_COMMENTS_PANEL(state, postId) {
      state.xCommentsPanel = true
      state.commentsPanelPostId = postId
    },
    SHOW_PERMIT_NOTIFICATION(state) {
      state.xPermitNotification = true
    },
    RESIZE_EDITOR(state, height) {
      state.editorHeight = height
    },
    RESIZE_HEADER(state, height) {
      state.headerHeight = height
    },
    SHOW_TOOL_MENU(state) {
      state.xToolMenu = true
    },
    HIDE_TOOL_MENU(state) {
      state.xToolMenu = false
    },
    SHOW_REMAKE_PANEL(state) {
      state.xRemakePanelOld = true
    },
    HIDE_REMAKE_PANEL(state) {
      state.xRemakePanelOld = false
    },
    SHOW_EDIT_BOOKMARKS_DIALOG(state, anchor = null) {
      if (anchor) state.editBookmarksDialogPopoverAnchor = anchor
      state.xEditBookmarksDialog = true
    },
    HIDE_EDIT_BOOKMARKS_DIALOG(state) {
      state.xEditBookmarksDialog = false
    },

    UPDATE_WALL(state, changes) {
      // Do this so that the whole wall object will not be reactively updated
      // but rather just each of the individual properties will be.
      Object.keys(changes).forEach((c) => {
        vSet(state.wall, c, changes[c])
      })
    },
    ANNOUNCE_WISH_LIST_MOUNTED(state) {
      state.wishListMounted = true
    },
    REFRESH_DONE(state) {
      state.wallLastSyncTime = Date.now()
    },
    SET_CREATABLE_LIBRARIES(state, { libraries }) {
      state.creatableLibraries = libraries
    },
    SET_USER_LIBRARY_SETTINGS(state, userLibrarySettings: UserLibrarySettingsApiResponse) {
      state.userLibrarySettings = userLibrarySettings
    },
    SET_VIEWABLE_LIBRARIES(state, { libraries }) {
      state.viewableLibraries = libraries
    },
    UPDATE_WALL_FOLLOWS(state, wallFollows: Partial<WallFollowInformation>): void {
      state.wall_follows = Object.assign(state.wall_follows, wallFollows)
    },
    SHOW_REPORT_PANEL(state, postId?: Id): void {
      state.xReportPanel = true
      state.reportedPostId = postId ?? null
    },
    HIDE_REPORT_PANEL(state): void {
      state.xReportPanel = false
      state.reportedPostId = null
    },
    SHOW_DEVELOPERS_PANEL(state): void {
      state.xDevelopersPanel = true
    },
    HIDE_DEVELOPERS_PANEL(state): void {
      state.xDevelopersPanel = false
    },
    SHOW_ACTION_BAR_MORE_MENU(state): void {
      state.xActionBarMoreMenu = true
    },
    HIDE_ACTION_BAR_MORE_MENU(state): void {
      state.xActionBarMoreMenu = false
    },
    MARK_WALL_AS_TEMPLATE(state): void {
      vSet(state.wall, 'is_template', true)
    },
    UNMARK_WALL_AS_TEMPLATE(state): void {
      vSet(state.wall, 'is_template', false)
    },
    SET_PRIVACY_OPTION_PERMISSIONS(state, privacyOptionsPermissions): void {
      const privacyOptionToStateNameHash = {
        private: 'canIMakePrivateWalls',
        password_protected: 'canIMakePasswordWalls',
        logged_in_users_only: 'canIMakeSecretLoggedInVisitorWalls',
        secret: 'canIMakeSecretWalls',
        public: 'canIMakePublicWalls',
        org_wide_unlisted: 'canIMakeOrgWideHiddenWalls',
        org_wide_listed: 'canIMakeOrgWideDashboardWalls',
        library_wide_unlisted: 'canIMakeLibraryWideHiddenWalls',
        library_wide_listed: 'canIMakeLibraryWideDashboardWalls',
      }

      privacyOptionsPermissions.forEach((privacyOptionPermission) => {
        state[privacyOptionToStateNameHash[privacyOptionPermission.name]] = privacyOptionPermission.canSet
      })
    },
    SET_SHOULD_USE_REST_API(state, shouldFallbackWsDataFetchToRest: boolean): void {
      state.shouldFallbackWsDataFetchToRest = shouldFallbackWsDataFetchToRest
    },
    UPDATE_USER(state, user: User): void {
      state.user = merge(state.user, user)
    },
  },
  actions: {
    initializeState({ commit, dispatch, getters }, data) {
      const {
        postAction: { expandedPostCidOrHashid },
        brahmsToken,
        deviceId,
        firstViewAfterInviteAccepted,
        paramPostModerationActionResult,
        paramCommentModerationActionResult,
        ...rootStoreState
      } = data

      const userId = rootStoreState.user.id
      const wallId = rootStoreState.wall.id

      commit('INITIALIZE_STATE', rootStoreState)
      // Log that the user has created a padlet. We are doing this to see user journeys.
      if (getters.isFirstView) {
        trackEvent('Surface', 'Created wall', rootStoreState.wall.title, null, { wallId })
      }

      void dispatch('realtime/initializeState', {
        brahmsToken,
        deviceId,
        userId,
      })
      if (expandedPostCidOrHashid != null) {
        // Systemic Authorization:
        // we now use hashid for expanded posts, but old links using id should still work
        if (isCid(expandedPostCidOrHashid)) {
          // We can preemptively expand post, since all wish data will be fetched when a surface loads
          // Enqueue in event loop to ensure that it runs only after the pinia store is already created
          // https://stackoverflow.com/a/779785
          setTimeout(() => {
            useExpandedPostStore().expandPost({ postCid: expandedPostCidOrHashid, xTextPanel: true })
          }, 0)
        } else {
          const wishHashid = expandedPostCidOrHashid
          dispatch('post/fetchSinglePostWithHashid', { wishHashid })
            .then((wish) => {
              if (wish != null) {
                // Use result to expand target post
                useExpandedPostStore().expandPost({ postCid: `c${wish.id as Id}`, xTextPanel: true })
              }
            })
            .catch((error) => {
              // If this call fails, if postEntities are available, search post matching hashid and expand
              const postEntities: Record<Cid, Post> = getters['post/postEntitiesByCid']
              if (postEntities != null) {
                const matchingPost = Object.values(postEntities).find(
                  (p: Post) => p.hashid != null && removeHashidPrefix(p.hashid) === wishHashid,
                )
                if (matchingPost != null) {
                  useExpandedPostStore().expandPost({ postCid: matchingPost.cid, xTextPanel: true })
                }
              }
              captureException(error)
            })
        }
      }

      if (getters.amIRegistered) {
        // We add a setTimeout since we want to delay the execution of the code below until pinia is available
        setTimeout(() => {
          const surfaceCollectionsStore = useSurfaceCollectionsStore()
          surfaceCollectionsStore.setActiveUser(userId)
          surfaceCollectionsStore.switchAccountKey({ type: 'user', id: userId })
          surfaceCollectionsStore.setActiveWall(wallId)
          void surfaceCollectionsStore.fetchBookmarks({ wallId })
          void surfaceCollectionsStore.fetchFolders()

          const surfaceGuestStore = useSurfaceGuestStore()
          if (surfaceGuestStore.shouldEnableAnonymousAttribution && rootStoreState.user.just_registered === true) {
            surfaceGuestStore.showSnackbarAfterSigningUp()
          }
        }, 0)
      }

      if (getters.isSectionable) {
        const defaultSectionId = rootStoreState.wall?.wish_arrangement?.default_section_id
        if (defaultSectionId != null) {
          setTimeout(() => {
            useSurfaceSectionsStore().setDefaultSectionAndRecentlyTouchedSection(defaultSectionId)
          }, 0)
        }
      }

      // Show invite accepted toast if first view after invite accepted.
      if (firstViewAfterInviteAccepted) {
        setTimeout(() => {
          useGlobalSnackbarStore().setSnackbar({
            notificationType: SnackbarNotificationType.success,
            message: __('Invite accepted'),
            timeout: 3000,
          })
        })
      }

      void dispatch('handleSlideshowQrCodeParams')
      setTimeout(() => {
        void dispatch('handlePostModerationParams', paramPostModerationActionResult)
        void dispatch('handleCommentModerationParams', paramCommentModerationActionResult)
      })
      if (isAppUsing('googleAppLicensing') && getters.isOrgWall === true) {
        void dispatch('validateGoogleAppLicense')
      }
    },
    refreshPadletStartingState({ commit }, payload) {
      commit('INITIALIZE_STATE', payload)
    },
    reloadPage() {
      reload()
    },
    changeConnectivity({ commit }, data) {
      commit('CHANGE_CONNECTIVITY', data)
    },
    changeDragging({ commit }, { isAPostDragging }) {
      commit('CHANGE_DRAGGING', { isAPostDragging })
    },
    handleCommentModerationParams(
      { dispatch },
      paramCommentModerationActionResult: ParamCommentModerationActionResult,
    ) {
      switch (paramCommentModerationActionResult) {
        case ParamCommentModerationActionResult.APPROVE_SUCCESS:
          useGlobalSnackbarStore().setSnackbar({
            notificationType: SnackbarNotificationType.success,
            message: __('Comment approved'),
            timeout: 3000,
          })
          break
        case ParamCommentModerationActionResult.REJECT_SUCCESS:
          useGlobalSnackbarStore().setSnackbar({
            notificationType: SnackbarNotificationType.error,
            message: __('Comment rejected'),
            timeout: 3000,
          })
          break
        case ParamCommentModerationActionResult.LOGIN_REQUIRED:
          useGlobalSnackbarStore().setSnackbar({
            notificationType: SnackbarNotificationType.error,
            message: __('Please log in to your account'),
            timeout: 3000,
          })
          break
        case ParamCommentModerationActionResult.MODERATE_FAIL:
          useGlobalSnackbarStore().setSnackbar({
            notificationType: SnackbarNotificationType.error,
            message: __('Unable to moderate comment'),
            timeout: 3000,
          })
          break
      }
      clearSearchParam('moderate_comment_id', false)
      clearSearchParam('moderate_comment_action', false)
    },
    handlePostModerationParams({ dispatch }, paramPostModerationActionResult: ParamPostModerationActionResult) {
      // Show result of post reject/approve from query param
      switch (paramPostModerationActionResult) {
        case ParamPostModerationActionResult.APPROVE_SUCCESS:
          useGlobalSnackbarStore().setSnackbar({
            notificationType: SnackbarNotificationType.success,
            message: __('Post approved'),
            timeout: 3000,
          })
          break
        case ParamPostModerationActionResult.REJECT_SUCCESS:
          useGlobalSnackbarStore().setSnackbar({
            notificationType: SnackbarNotificationType.success,
            message: __('Post rejected'),
            timeout: 3000,
          })
          break
        case ParamPostModerationActionResult.LOGIN_REQUIRED:
          useGlobalSnackbarStore().setSnackbar({
            notificationType: SnackbarNotificationType.error,
            message: __('Please log in to your account'),
            timeout: 3000,
          })
          break
        case ParamPostModerationActionResult.MODERATE_FAIL:
          useGlobalSnackbarStore().setSnackbar({
            notificationType: SnackbarNotificationType.error,
            message: __('Unable to moderate post'),
            timeout: 3000,
          })
          break
      }
      clearSearchParam('moderate_post_id', false)
      clearSearchParam('moderate_post_action', false)
    },
    handleSlideshowQrCodeParams(): void {
      clearSearchParam('utm_source', false)
      clearSearchParam('utm_medium', false)
    },

    updateUser({ commit }, user) {
      commit('UPDATE_USER', user)
    },

    // Check App License to see if user needs to be logged out.
    async validateGoogleAppLicense() {
      const validationResponse = await PadletApi.GoogleAppLicensingSettings.checkLicense()
      const licenseStatus = (validationResponse?.data as JsonAPIResource<GoogleAppLicensingValidateResponse>).attributes
      if (!licenseStatus.invalidLicense) return
      navigateTo(licenseStatus.nextUrl as string, { method: 'post' })
    },

    /* ------------
     * PANEL
     * ------------ */
    hideContributorsList({ commit }) {
      commit('HIDE_CONTRIBUTORS_LIST')
    },
    hideWallQuotaExceeded({ commit }) {
      commit('HIDE_WALL_QUOTA_EXCEEDED')
    },
    hideCommentsPanel({ commit }) {
      commit('HIDE_COMMENTS_PANEL')
    },
    hideToolMenu({ commit }) {
      commit('HIDE_TOOL_MENU')
    },
    hidePermitNotification({ commit }) {
      commit('HIDE_PERMIT_NOTIFICATION')
    },
    showReloadNotification({ commit }) {
      commit('SHOW_RELOAD_NOTIFICATION')
    },
    hideReloadNotification({ commit }) {
      commit('HIDE_RELOAD_NOTIFICATION')
    },
    showContributorsList({ commit }) {
      commit('SHOW_CONTRIBUTORS_LIST')
    },
    showOverFileSizeModal({ commit, dispatch, getters }, { file }) {
      dispatch('post/rejectPostsWithFileSizeExceeded', { file })
      commit('UPDATE_FILE_THAT_EXCEEDED_SIZE', file)
      if (getters.isLibraryWall) {
        const MAX_UPLOAD_BYTES = 1200000000 // 1.2GB
        if (!getters.amILibraryOwner) {
          dispatch('triggerOverFileSizeAlertDialog')
        } else {
          if (file.size > MAX_UPLOAD_BYTES) {
            dispatch('triggerOverFileSizeAlertDialog')
          } else {
            void useLibraryPlansStore().fetchPlansAndShowUpgradeModal({
              upgradeStep: LibraryUpgradeStep.ChooseTierFileSizeQuota,
              library: getters.library,
            })
          }
        }
      } else {
        const MAX_UPLOAD_BYTES = 600000000 // 600MB
        if (!getters.amIOwner) {
          dispatch('triggerOverFileSizeAlertDialog')
        } else {
          if (file.size > MAX_UPLOAD_BYTES) {
            dispatch('triggerOverFileSizeAlertDialog')
          } else {
            usePersonalPlansStore().showPlanUpgradeModalCheckingScheduledChanges({
              step: UpgradeStep.ChooseTierFileSizeQuota,
              membershipTier: getters.user.membership_tier,
              paymentSchedule: getters.user.period,
              upgradeSource: UpgradeSource.FileSizeLimit,
            })
          }
        }
      }
    },
    triggerOverFileSizeAlertDialog({ getters, dispatch }) {
      useGlobalAlertDialogStore().openAlertDialog({
        iconSrc: anxiousFace,
        iconAlt: __('Anxious face'),
        title: __('File too large!'),
        body: __('This padlet has a file size limit of %{num}.', {
          num: numberToHumanSize(getters.advertizedUploadLimit, { precision: 2, strip_insignificant_zeros: true }),
        }),
        xShadow: true,
      })
    },
    showWallQuotaExceeded({ commit }) {
      commit('SHOW_WALL_QUOTA_EXCEEDED')
    },
    showCommentsPanel({ commit }, { postId }) {
      commit('SHOW_COMMENTS_PANEL', postId)
    },
    showDeleteDialog({ dispatch, getters }) {
      void useGlobalConfirmationDialogStore().openConfirmationDialog({
        ...DELETE_WALL_CONFIRMATION_PARAMS,
        afterConfirmActions: [async () => await dispatch('deleteWall')],
        xShadow: true,
      })
    },
    showClearAllDialog({ dispatch, getters }) {
      void useGlobalConfirmationDialogStore().openConfirmationDialog({
        isCodeProtected: true,
        buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
        title: __('Delete all posts?'),
        body: __('This action cannot be undone.'),
        confirmButtonText: __('Delete'),
        cancelButtonText: __('Nevermind'),
        afterConfirmActions: [async () => await dispatch('post/clearAllPosts', null, { root: true })],
        xShadow: true,
        shouldFadeIn: false,
      })
    },
    async checkAndSubscribeToPushNotifications({ dispatch }) {
      const permission = await getNotificationPermission()
      if (permission === 'granted') {
        await dispatch('registerForPushNotifications')
      } else if (permission === 'default') {
        useGlobalSnackbarStore().setSnackbar({
          message: __('Turn on notifications for this browser?'),
          actionText: __('OK'),
          actionTextActions: [async () => await dispatch('registerForPushNotifications')],
          timeout: 6000,
        })
      }
      // Respect the user's decision if denied
      // else if (permission === 'denied') {
      //   // Displays unblocking instructions
      //   dispatch('registerForPushNotifications')
      // }
    },
    registerForPushNotifications({ commit, state }) {
      registerForPushNotifications(state.user).catch((e) => {
        console.warn(`No push`, e)
        commit('SHOW_PERMIT_NOTIFICATION')
      })
    },
    async requestGoogleDriveAuthorizationFromSurface({ commit, getters }, { tenantId }) {
      // Dynamically import Google drive modules since we only need these modules when the user selects
      // Google drive from the content picker
      const [googleDriveComposableModule, googleDrivePickerStoreModule] = await Promise.all([
        import('@@/vuecomposables/google_drive'),
        import('@@/pinia/google_drive_picker'),
      ])
      const { useGoogleDrive } = googleDriveComposableModule
      const result = await useGoogleDrive().requestGoogleDriveAuthorization({ tenantId })
      trackEvent('GoogleDrive', 'Connection completed on surface')
      // Set Google Drive Email in frontend for use in UI
      const { useGoogleDrivePickerStore } = googleDrivePickerStoreModule
      useGoogleDrivePickerStore().setGoogleDriveEmail(result.email)
      useGoogleDrivePickerStore().setGoogleDriveAccessToken(result.token)
    },
    showRemakePanelOld({ commit }) {
      commit('SHOW_REMAKE_PANEL')
    },
    hideRemakePanel({ commit }) {
      commit('HIDE_REMAKE_PANEL')
    },
    toggleToolMenu({ commit, state }) {
      if (state.xToolMenu) {
        commit('HIDE_TOOL_MENU')
      } else {
        commit('SHOW_TOOL_MENU')
      }
    },
    showReportPanel({ commit }, { postId }: { postId?: Id } = {}) {
      commit('SHOW_REPORT_PANEL', postId)
    },
    hideReportPanel({ commit }) {
      commit('HIDE_REPORT_PANEL')
    },
    showDevelopersPanel({ commit }) {
      commit('SHOW_DEVELOPERS_PANEL')
    },
    hideDevelopersPanel({ commit }) {
      commit('HIDE_DEVELOPERS_PANEL')
    },
    showActionBarMoreMenu({ commit }) {
      commit('SHOW_ACTION_BAR_MORE_MENU')
    },
    hideActionBarMoreMenu({ commit }) {
      commit('HIDE_ACTION_BAR_MORE_MENU')
    },

    /* ------------
     * POST
     * ------------ */
    deletePostRemote({ dispatch, state }, post) {
      if (state.commentsPanelPostId == post.id) {
        dispatch('hideCommentsPanel')
      }
      // postAction/deletePostRemote must come before post/deletePostRemote
      // the post action panels(e.g.post action menu, copy / transfer panels) hold their own cid value and will try to access getPostByCid(cid)
      // if we delete the post first, it will cause some undefined object errors
      useSurfacePostActionStore().deletePostRemote(post)
      dispatch('post/deletePostRemote', post)
    },
    removePost({ dispatch }, { id, cid }) {
      dispatch('post/removePost', { id, cid })
    },
    addPost({ dispatch }, payload) {
      const postAttributes = payload.post
      dispatch('post/addPost', postAttributes)
    },
    /* ------------
     * ATTACHMENT
     * ------------ */
    resizeEditor({ commit }, { height }) {
      commit('RESIZE_EDITOR', height)
    },

    /* ------------
     * HEADER
     * ------------ */
    resizeHeader({ commit }, { height }) {
      commit('RESIZE_HEADER', height)
    },

    /* ------------
     * WALL
     * ------------ */
    updateWall({ commit }, changes) {
      commit('UPDATE_WALL', changes)
    },
    async setCoverPost({ commit, dispatch, state }, { postId }) {
      const wall = await PadletApi.Wall.update({ ...state.wall, cover_wish_id: postId })
      commit('UPDATE_WALL', wall)
      useGlobalSnackbarStore().setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Cover image updated'),
      })
    },
    async unsetCoverPost({ commit, dispatch, state }) {
      const wall = await PadletApi.Wall.update({ ...state.wall, cover_wish_id: null })
      commit('UPDATE_WALL', wall)
      useGlobalSnackbarStore().setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Cover image updated'),
      })
    },
    async archiveWall({ getters }) {
      await PadletApi.Wall.archive(getters.wallId)
      safeLocalStorage.setItem('activePadletFilter', 'archived')
      navigateTo('/')
    },
    async deleteWall({ getters }) {
      await deleteWall(getters.wallId)
      navigateTo('/')
    },
    async trashWall({ getters }) {
      await PadletApi.Wall.trash(getters.wallId)
      setCookie('surface_wall_trash', 'true')
      if (getters.libraryDashboardUrl !== undefined) {
        const libraryUrl = transformUrl(getters.libraryDashboardUrl, {
          searchParams: {
            mobile_page: MobilePage.Collection,
            filter: 'all',
          },
        })
        navigateTo(libraryUrl)
        return
      }
      const userDashboardUrl = transformUrl(buildUrlFromPath('/dashboard'), {
        searchParams: {
          mobile_page: MobilePage.Collection,
          filter: 'all',
        },
      })
      navigateTo(userDashboardUrl)
    },
    async markWallAsTemplate({ dispatch, commit, getters }) {
      commit('MARK_WALL_AS_TEMPLATE')
      try {
        await PadletApi.Wall.markAsTemplate(getters.wallId, true)
      } catch (error) {
        void dispatch('genericFetchError', { error })
        commit('UNMARK_WALL_AS_TEMPLATE')
      }
    },
    async unmarkWallAsTemplate({ dispatch, commit, getters }) {
      commit('UNMARK_WALL_AS_TEMPLATE')
      try {
        await PadletApi.Wall.markAsTemplate(getters.wallId, false)
      } catch (error) {
        void dispatch('genericFetchError', { error })
        commit('MARK_WALL_AS_TEMPLATE')
      }
    },

    /*****************
     * BOOKMARKS
     *****************/

    async bookmarkWallAndNotify({ dispatch, getters }) {
      const wallId = getters.wallId

      try {
        await useSurfaceCollectionsStore().addBookmark({ wallId })
        useGlobalSnackbarStore().setSnackbar({
          notificationType: SnackbarNotificationType.success,
          message: __('Bookmark added'),
          actionText: __('Edit'),
          actionTextActions: [async () => await dispatch('openEditBookmarksDialog')],
        })
      } catch (error) {
        void dispatch('genericFetchError', { error })
      }
    },
    async addToFavorites({ dispatch, getters }) {
      const wallId = getters.wallId
      try {
        await useSurfaceCollectionsStore().addBookmark({ wallId })
      } catch (error) {
        void dispatch('genericFetchError', { error })
      }
    },
    async removeFromFavorites({ dispatch, getters }) {
      const wallId = getters.wallId

      try {
        await useSurfaceCollectionsStore().removeBookmark({ wallId })
      } catch (error) {
        void dispatch('genericFetchError', { error })
      }
    },
    openEditBookmarksDialog({ commit }, anchor = null) {
      commit('SHOW_EDIT_BOOKMARKS_DIALOG', anchor)
    },
    closeEditBookmarksDialog({ commit }) {
      commit('HIDE_EDIT_BOOKMARKS_DIALOG')
    },
    promptNameForNewFolder({ dispatch }) {
      useGlobalInputDialogStore().openInputDialog({
        title: __('New folder'),
        inputPlaceholder: __('Folder name'),
        inputMaxLength: MAX_FOLDER_NAME_LENGTH,
        inputAriaLabel: __('Enter the folder name'),
        validationActions: [
          ({ inputValue }) => {
            void dispatch('validateFolderName', { inputValue })
          },
        ],
        submitActions: [
          ({ inputValue }) => {
            void dispatch('createFolder', { inputValue })
          },
        ],
      })
    },
    async createFolder({ dispatch }, { inputValue }) {
      await useSurfaceCollectionsStore().createFolderForActiveUser({ name: inputValue })
    },
    validateFolderName({ dispatch }, { inputValue, disallowedNames }) {
      const disable = !isValidFolderName(inputValue) || (disallowedNames || []).includes(inputValue)
      useGlobalInputDialogStore().setSubmitButtonDisable(disable)
    },

    // SCROLL
    announceWishListMounted({ commit }) {
      commit('ANNOUNCE_WISH_LIST_MOUNTED')
    },
    /* ------------
     * POST CONNECTION
     * ------------ */
    removeConnectionsForPost({ dispatch }, { postId }) {
      useSurfacePostConnectionStore().removeConnectionsForPost({ postId })
    },

    fallbackToRestApi({ dispatch, commit }) {
      commit('SET_SHOULD_USE_REST_API', true)
      setScope({ fetchingMode: 'REST' })
      // We add a setTimeout since we want to delay the execution of the code below until pinia is available
      setTimeout(() => {
        void dispatch('refresh', { isAutoRefresh: false })
      }, 0)
    },

    // REFRESH
    refresh({ commit, dispatch, state, getters }, { isAutoRefresh }) {
      if (!state.isOnline) return
      if (!getters.isWallSyncNeeded) return

      // `refresh` will apply all the pending realtime updates.
      // This is not immune to race conditions, but since it is harmless to omit this is the first place,
      // this is sufficient to reduce user annoyance due to outdated notifications.
      dispatch('realtime/clearDispatchQueue')

      const mapWasFittingMarkerBounds = useSurfaceMapStore().mapDoesFitMarkersBounds
      void useSurfaceMapStore().disableMapFittingMarkersBounds()

      // We are not updating the vuexstore on auto refresh, the network response will update service worker cache
      // and if the cache is update, it will send a notification to update the store

      const updateOrderAndMapIfNecessary = () => {
        if (mapWasFittingMarkerBounds) {
          void useSurfaceMapStore().enableMapFittingMarkersBounds()
        }
      }

      const shouldResetState = !isAutoRefresh

      if (isAppUsing('fullClientSurface')) {
        // For FCS, we use the preloaded wishes api to fetch the first page of wishes
        void dispatch('post/fetchPosts', { shouldResetState }).then(updateOrderAndMapIfNecessary)
      }

      if (state.shouldFallbackWsDataFetchToRest || !isAppUsing('realtimeFetching')) {
        void useReactionsStore().fetchAccumulatedReactions()

        if (!isAppUsing('surfaceCommentsPerPost')) {
          void dispatch('comment/fetchComments', { shouldResetState })
        }

        if (!isAppUsing('fullClientSurface')) {
          void dispatch('post/fetchPosts', { shouldResetState }).then(updateOrderAndMapIfNecessary)
        }

        if (isAppUsing('layoutFormatSettingV2') || getters.canUseSections) {
          void useSurfaceSectionsStore().fetchSections()
        }
      }

      if (getters.canHaveConnections === true) {
        void useSurfacePostConnectionStore().fetchConnections()
      }

      useSurfaceContributingStatusStore().ping()

      // reactions, comments, wall sections, ... only do 1 request
      // but wishes have pagination and may make multiple requests
      // => for first page load, wait for it to be done before marking things as done
      if (getters.isInitialPaginatedDataLoadDone) {
        commit('REFRESH_DONE')
      } else {
        const unwatch = this.watch(
          (state, getters) => getters.isInitialPaginatedDataLoadDone,
          (newValue, oldValue) => {
            if (newValue) {
              commit('REFRESH_DONE')
              unwatch()
            }
          },
        )
      }
    },

    // User setting
    async fetchUserLibrarySettings({ commit }) {
      try {
        const response = await PadletApi.User.fetchUserLibrarySettings()
        const userLibrarySettings = (response?.data as JsonAPIResource<UserLibrarySettingsApiResponse>).attributes
        commit('SET_USER_LIBRARY_SETTINGS', userLibrarySettings)
      } catch (e) {
        captureFetchException(e, { source: 'SurfaceFetchUserLibrarySettings' })
      }
    },
    // LIBRARIES
    async fetchCreatableLibraries({ commit }, { userId }) {
      const response = await PadletApi.Library.fetchCreatableLibraries({ userId })
      const librariesData = response.data as Array<JsonAPIResource<Library>>
      commit('SET_CREATABLE_LIBRARIES', { libraries: librariesData.map((library) => library.attributes) })
    },
    async fetchViewableLibraries({ commit }, { userId }) {
      const response = await PadletApi.Library.fetchViewableLibraries({ userId })
      const librariesData = response.data as Array<JsonAPIResource<Library>>
      commit('SET_VIEWABLE_LIBRARIES', { libraries: librariesData.map((library) => library.attributes) })
    },

    // SNACKBAR
    snackbar(_, snackbarPayload) {
      useGlobalSnackbarStore().setSnackbar(snackbarPayload)
    },
    genericFetchError(_, { error }) {
      captureNonNetworkFetchError(error)
      useGlobalSnackbarStore().genericFetchError()
    },

    loginAndRedirectTo(_, link) {
      navigateTo(loginWithReferrerUrl(link))
    },

    showPromptToLoginDialog(
      { dispatch, state },
      payload: {
        title: string
        urlTransformParams?: UrlOptions
      },
    ): void {
      const defaultPromptToLoginDialogPayload = {
        body: __('Please log in to your Padlet account or sign up for one.'),
        confirmButtonText: __('Log in'),
        cancelButtonText: __('Nevermind'),
        afterConfirmActions: [
          async () => {
            let redirectUrl = state.wall.links.permanent
            if (payload.urlTransformParams != null) {
              redirectUrl = transformUrl(redirectUrl, payload.urlTransformParams)
            }
            await dispatch('loginAndRedirectTo', redirectUrl, { root: true })
          },
        ],
      }
      void useGlobalConfirmationDialogStore().openConfirmationDialog({
        ...defaultPromptToLoginDialogPayload,
        ...payload,
      })
    },

    // FOLLOWS
    updateWallFollows(
      { commit, getters },
      { wallFollowId, followsWall }: { wallFollowId: Id; followsWall: boolean },
    ): void {
      commit('UPDATE_WALL_FOLLOWS', {
        current_user_follows_wall: followsWall,
        wall_follow_id: wallFollowId,
        wall_follows_count: followsWall ? getters.wallFollowsCount + 1 : getters.wallFollowsCount - 1,
      })
    },
    remoteUpdateWallFollows({ commit }, { wall_follow_id, follows }: { wall_follow_id: Id; follows: boolean }): void {
      commit('UPDATE_WALL_FOLLOWS', {
        current_user_follows_wall: follows,
        wall_follow_id,
      })
    },
    remoteUpdateWallFollowsCount({ commit, getters }, { follows }: { follows: boolean }): void {
      commit('UPDATE_WALL_FOLLOWS', {
        wall_follows_count: follows ? getters.wallFollowsCount + 1 : getters.wallFollowsCount - 1,
      })
    },

    // PRIVACY OPTIONS
    async fetchWallPrivacyOptions({ commit, getters }): Promise<void> {
      try {
        const response = await PadletApi.WallPrivacyOptions.fetchWallPrivacyOptions(getters.wallId)
        const wallPrivacyOptions = response.data as JsonAPIResource<CanSetWallPrivacyOptions>
        const privacyOptionsPermissions = wallPrivacyOptions.attributes.privacyOptions
        commit('SET_PRIVACY_OPTION_PERMISSIONS', privacyOptionsPermissions)
      } catch (e) {
        captureFetchException(e, { source: 'SurfaceFetchWallPrivacyOptions' })
      }
    },
    // This is being called in services/rails/app/javascript/pinia/surface_share_panel.ts
    // TODO: delete this once services/rails/app/javascript/pinia/global_confirmation_dialog.ts is ready
    async leavePadlet({ dispatch, rootGetters }): Promise<void> {
      try {
        await PadletApi.collaboratorLeavePadlet(rootGetters.wallId)
        const dashboardUrl = buildUrlFromPath('dashboard')
        navigateTo(dashboardUrl)
      } catch (e) {
        void useGlobalConfirmationDialogStore().openConfirmationDialog({
          ...WAVING_HAND_EMOJI,
          isCodeProtected: false,
          title: __('Oops!'),
          body: __('Something went wrong. We were unable to process your leave request.'),
          confirmButtonText: __('Try again'),
          cancelButtonText: __('Nevermind'),
          afterConfirmActions: [async () => await dispatch('leavePadlet')],
          buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
        })
      }
    },
  },
}

// Define a local type for now
interface BrowserWindow {
  ww?: any
}
const thisWindow = window as unknown as BrowserWindow

/**
 * The old dispatch function signature is different from the vuex store dispatch function signature
 * We write the dispatch function in ww.xstore so that any method signature will work.
 * ```js
 * ww.xstore.dispatch({type: 'START_EDITING_POST', payload: {postCid: id}})
 * ww.xstore.dispatch('START_EDITING_POST', {postCid: id})
 * ww.xstore.dispatch(function (dispatch) => {dispatch({type: 'START_EDITING_POST', payload: {postCid: id}})}
 * ```
 */

export function setupNativeBridgeXstore(store): void {
  const oldDispatch = (obj) => store.dispatch(obj.type, obj)

  if (!thisWindow.ww) {
    thisWindow.ww = {}
  }
  thisWindow.ww.xstore = {
    store,
    state: {},
    getters: {},
    dispatch: (obj, payload) => {
      if (typeof obj === 'string') {
        store.dispatch(obj, payload)
      } else if (obj.type) {
        oldDispatch(obj)
      } else if (typeof obj === 'function') {
        obj(oldDispatch)
      }
    },
  }

  store.subscribe(() => {
    thisWindow.ww.xstore.state = store.state
    thisWindow.ww.xstore.getters = store.getters
  })
}
