// @file Surface map store
import device from '@@/bits/device'
import { isAppUsing } from '@@/bits/flip'
import type { LatLng, OverlappingMarkerSpiderfier } from '@@/bits/map'
import { getVuexStore } from '@@/bits/pinia'
import { useReactiveSet } from '@@/bits/reactivity'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { usePostComposerModalStore } from '@@/pinia/post_composer_modal_store'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import type { Cid, DraftPost, Id, LocationMenuPlaceInfo, PinDropSuggestion } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import type { JsonAPIResource, JsonAPIResponse, WallTheme } from '@padlet/arvo'
import { fetchMapThemes as arvoFetchMapThemes } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

interface PostLocation {
  postCid: Cid | null
  latLng: LatLng | null
  name: string
  sectionId?: Id
}

export interface MapPopupOffsetStyle {
  top: number
  bottom: number
  left: number
  right: number
}

export const useSurfaceMapStore = defineStore('surfaceMap', () => {
  const surfaceStore = useSurfaceStore()
  const surfaceContainerSizeStore = useSurfaceContainerSizeStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfaceDraftsStore = useSurfaceDraftsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  /* --------------------------------------------- */
  /* FETCH & SHOW MAP THEME                        */
  /* --------------------------------------------- */
  const map = computed<google.maps.Map | null | undefined>(() => getVuexStore<RootState>()?.getters['map/map'])
  const mapThemes = ref<Record<number, WallTheme>>()
  const isFetchingMapThemes = ref(false)
  const currentThemeId = computed<number>(() => getVuexStore<RootState>()?.getters.themeId)
  const currentMapTheme = computed(() =>
    mapThemes.value !== undefined ? mapThemes.value[currentThemeId.value] : undefined,
  )
  const isMapThemeChanged = computed<boolean>(() => currentThemeId.value !== surfaceStore.mapThemeId)

  const setMapBeingUsed = ({ map: updatedMap }: { map: google.maps.Map }): void => {
    void getVuexStore<RootState>()?.dispatch('map/setMapBeingUsed', { map: updatedMap })
  }

  async function fetchMapThemes(): Promise<void> {
    isFetchingMapThemes.value = true
    const themesResponse: JsonAPIResponse<WallTheme> = await arvoFetchMapThemes()
    if (themesResponse.data != null) {
      const mapThemeDictionary = {}
      const allThemes = themesResponse.data as Array<JsonAPIResource<WallTheme>>
      allThemes.forEach((mapTheme: JsonAPIResource<WallTheme>): void => {
        const details = mapTheme.attributes
        mapThemeDictionary[details.id] = details
      })
      mapThemes.value = mapThemeDictionary
    }
    isFetchingMapThemes.value = false
  }

  /* --------------------------------------------- */
  /* PICKING LOCATIONS                             */
  /* --------------------------------------------- */
  const isPickingLocation = computed<boolean>(() => getVuexStore<RootState>()?.getters['map/isPickingLocation'])
  const postWithLocationBeingEditedCid = computed<Cid | null>(
    () => getVuexStore<RootState>()?.getters['map/postWithLocationBeingEditedCid'],
  )
  const sectionIdOfPostBeingAdded = computed<Id | null>(
    () => getVuexStore<RootState>()?.getters['map/sectionIdOfPostBeingAdded'],
  )

  const hidePickLocationPanel = (): void => {
    void getVuexStore<RootState>()?.dispatch('map/hidePickLocationPanel')
  }

  function startPickingLocation(payload?: { postCid?: Cid; sectionId?: Id }): void {
    // Show the post composer if user is using locationSelectorV2
    if (!device.app && isAppUsing('locationSelectorV2')) {
      void surfacePostsStore.startNewPost({ attributes: { wall_section_id: payload?.sectionId } })
    } else {
      void getVuexStore<RootState>()?.dispatch('map/startPickingLocation', payload)
    }
  }

  function startPickingNewLocation(payload: { postCid: Cid }): void {
    void getVuexStore<RootState>()?.dispatch('map/startPickingNewLocation', payload)
  }
  function stopPickingNewLocation(): void {
    void getVuexStore<RootState>()?.dispatch('map/stopPickingNewLocation')
  }

  const stopPickingLocation = (): void => {
    void getVuexStore<RootState>()?.dispatch('map/stopPickingLocation')
  }

  const pickLocation = (payload: Partial<PostLocation>): void => {
    if (isAppUsing('locationSelectorV2') && !device.app) {
      if (payload.latLng == null) {
        globalSnackbarStore.genericFetchError()
        throw new Error("Location's coordinates not found")
      }
      if (postWithLocationBeingEditedCid.value !== null) {
        pickLocationForPostBeingEdited(payload.latLng, payload.name ?? '')
      } else if (!isPlacingPin.value) {
        pickLocationForDraftBeingEdited(payload.latLng, payload.name ?? '')
      } else {
        pickLocationForDraftBeingEditedWithPlacedPin(payload.latLng, payload.name ?? '')
      }
    } else {
      void getVuexStore<RootState>()?.dispatch('map/pickLocation', payload)
    }
  }

  function pickLocationForPostBeingEdited(latLng: LatLng, name: string): void {
    if (postWithLocationBeingEditedCid.value === null) {
      globalSnackbarStore.genericFetchError()
      throw new Error('No post found')
    }
    surfacePostsStore.startEditingPost({ postCid: postWithLocationBeingEditedCid.value }) // Reopen the post that was being edited
    stopPickingNewLocation() // // Set postWithLocationBeingEditedCid to null
    if (surfaceDraftsStore.activeDraftCid == null) {
      globalSnackbarStore.genericFetchError()
      throw new Error('No active draft found')
    }
    updateDraftLocation(surfaceDraftsStore.activeDraftCid, latLng, name)
    usePostComposerModalStore().restoreComposerModalState(surfaceDraftsStore.activeDraftCid ?? '')
  }

  function pickLocationForDraftBeingEdited(latLng: LatLng, name: string): void {
    if (surfaceDraftsStore.activeDraftCid == null) {
      globalSnackbarStore.genericFetchError()
      throw new Error('No active draft found')
    }
    updateDraftLocation(surfaceDraftsStore.activeDraftCid, latLng, name)
    usePostComposerModalStore().restoreComposerModalState(surfaceDraftsStore.activeDraftCid ?? '')
  }

  function pickLocationForDraftBeingEditedWithPlacedPin(latLng: LatLng, name: string): void {
    showDraftBeingEdited()
    draftWithLocationBeingEditedCid.value = null
    if (surfaceDraftsStore.activeDraftCid == null) {
      globalSnackbarStore.genericFetchError()
      throw new Error('No active draft found')
    }
    updateDraftLocation(surfaceDraftsStore.activeDraftCid, latLng, name)
    isPlacingPin.value = false
  }

  function showDraftBeingEdited(): void {
    if (draftWithLocationBeingEditedCid.value == null) {
      globalSnackbarStore.genericFetchError()
      throw new Error('No draft found')
    }
    if (surfaceContainerSizeStore.isPhone || surfacePostsStore.isExistingPost(draftWithLocationBeingEditedCid.value)) {
      // On phone, set activeDraftCid to draftWithLocationBeingEditedCid to open the draft's composer modal
      // If the draft is an existing post, setting activeDraftCide to draftWithLocationBeingEditedCid will open the post's composer modal
      surfaceDraftsStore.startEditingDraft(draftWithLocationBeingEditedCid.value)
    } else {
      // On tablet and other touchable devices, expandComposerModal to open the draft's composer modal
      usePostComposerModalStore().expandComposerModal(draftWithLocationBeingEditedCid.value)
    }
  }

  function updateDraftLocation(cid: Cid, latLng: LatLng, name: string): void {
    const updateData: Partial<DraftPost> = {
      cid,
      location_point: {
        longitude: latLng?.lng(),
        latitude: latLng?.lat(),
      },
      location_name: name,
    }

    const isSubjectWrittenByUser: boolean =
      surfaceDraftsStore.activeDraft?.subject !== surfaceDraftsStore.activeDraft?.location_name &&
      surfaceDraftsStore.activeDraft?.subject !== ''
    if (surfaceStore.isMap && !isSubjectWrittenByUser) {
      updateData.subject = name
    }

    surfaceDraftsStore.updateDraft(updateData)
  }

  const pickLocationAndCreateNewPost = (payload: Omit<PostLocation, 'postCid'>): void => {
    void getVuexStore<RootState>()?.dispatch('map/pickLocationAndCreateNewPost', payload)
  }

  const pickLocationAndUpdateLocation = (payload: PostLocation): void => {
    void getVuexStore<RootState>()?.dispatch('map/pickLocationAndUpdateLocation', payload)
  }

  /* --------------------------------------------- */
  /* SHOW MAP POST, MAKRER AND PREVIEW PANEL       */
  /* --------------------------------------------- */
  const mapDoesFitMarkersBounds = computed<boolean>(
    () => getVuexStore<RootState>()?.getters['map/mapDoesFitMarkersBounds'],
  )
  const pinDropSuggestions = computed<PinDropSuggestion[] | null>(
    () => getVuexStore<RootState>()?.getters['map/pinDropSuggestions'],
  )
  const pinDropLocation = computed<google.maps.LatLng | null>(
    () => getVuexStore<RootState>()?.getters['map/pinDropLocation'],
  )
  const postWithPopupBeingOpenedCid = computed<Cid | null>(
    () => getVuexStore<RootState>()?.getters['map/postWithPopupBeingOpenedCid'],
  )
  const postWithMarkerHighlightedCid = computed<Cid | null>(
    () => getVuexStore<RootState>()?.getters['map/postWithMarkerHighlightedCid'],
  )
  const overlappingMarkerSpiderfier = computed<OverlappingMarkerSpiderfier | null>(
    () => getVuexStore<RootState>()?.getters['map/overlappingMarkerSpiderfier'],
  )
  const isPreviewPanelShown = computed<boolean>(() => getVuexStore<RootState>()?.getters['map/isPreviewPanelShown'])

  const setMarkerOverlayLayer = (payload: { spiderfier: OverlappingMarkerSpiderfier }): void => {
    void getVuexStore<RootState>()?.dispatch('map/setMarkerOverlayLayer', payload)
  }

  function openPostPopup(payload: { postCid: Cid | null }): void {
    void getVuexStore<RootState>()?.dispatch('map/openPostPopup', payload)
  }

  const closePostPopup = (): void => {
    void getVuexStore<RootState>()?.dispatch('map/closePostPopup')
  }

  const highlightPostMarker = (payload: { postCid: Cid | null }): void => {
    void getVuexStore<RootState>()?.dispatch('map/highlightPostMarker', payload)
  }

  const toggleMapPreviewPanel = (): void => {
    void getVuexStore<RootState>()?.dispatch('map/toggleMapPreviewPanel')
  }

  const enableMapFittingMarkersBounds = (): void => {
    void getVuexStore<RootState>()?.dispatch('map/enableMapFittingMarkersBounds', true)
  }

  const disableMapFittingMarkersBounds = (): void => {
    void getVuexStore<RootState>()?.dispatch('map/disableMapFittingMarkersBounds', false)
  }

  const setMapPinDropSuggestions = ({ suggestions, location }): void => {
    void getVuexStore<RootState>()?.dispatch('map/setMapPinDropSuggestions', { suggestions, location })
  }

  const clearMapPinDropSuggestions = (): void => {
    void getVuexStore<RootState>()?.dispatch('map/clearMapPinDropSuggestions')
  }

  /* --------------------------------------------- */
  /* MAP RELATED POSITIONING                       */
  /* --------------------------------------------- */
  const offsetForMapPopup = computed<MapPopupOffsetStyle>(
    () => getVuexStore<RootState>()?.getters['map/offsetForMapPopup'],
  )

  const postListPosition = computed<'side' | 'bottom'>(() =>
    surfaceContainerSizeStore.isContainerSmallerThanTabletLandscape || device.app || surfaceStore.isEmbedded
      ? 'bottom'
      : 'side',
  )
  /* --------------------------------------------- */
  /* SAFE STORAGE BASED RECENT SEARCHES            */
  /* --------------------------------------------- */
  const recentLocationMenuSearches = computed<LocationMenuPlaceInfo[]>(() =>
    getVuexStore<RootState>()?.getters['map/recentLocationMenuSearches'].reverse(),
  )

  const saveRecentlySearchedLocations = (payload: { newPlace: LocationMenuPlaceInfo }): void => {
    void getVuexStore<RootState>()?.dispatch('map/saveRecentlySearchedLocations', payload)
  }

  const syncRecentlySearchedLocationsFromStorage = (): void => {
    void getVuexStore<RootState>()?.dispatch('map/syncRecentlySearchedLocationsFromStorage')
  }

  /* --------------------------------------------- */
  /* DRAG & DROP                                   */
  /* --------------------------------------------- */
  const isDraggingPosts = computed<boolean>(() => getVuexStore<RootState>()?.getters['map/isDraggingPosts'])
  const isDraggingLocationPickerPin = computed<boolean>(
    () => getVuexStore<RootState>()?.getters['map/isDraggingLocationPickerPin'],
  )

  function startDraggingPosts(): void {
    void getVuexStore<RootState>()?.dispatch('map/startDraggingPosts')
  }

  function stopDraggingPosts(): void {
    void getVuexStore<RootState>()?.dispatch('map/stopDraggingPosts')
  }

  const startDraggingLocationPickerPin = (): void => {
    void getVuexStore<RootState>()?.dispatch('map/startDraggingLocationPickerPin')
  }

  const stopDraggingLocationPickerPin = (): void => {
    void getVuexStore<RootState>()?.dispatch('map/stopDraggingLocationPickerPin')
  }

  /* --------------------------------------------- */
  /* MAP LOCATION SELECTOR                         */
  /* --------------------------------------------- */

  const xMapLocationSelector = computed<boolean>(
    () =>
      surfaceStore.isMap &&
      isAppUsing('locationSelectorV2') &&
      surfaceDraftsStore.activeDraft != null &&
      surfaceDraftsStore.activeDraft.location_point == null &&
      !hasDraftSkippedLocationSelector(surfaceDraftsStore.activeDraftCid as Cid),
  )

  const draftsThatHaveSkippedLocationSelector = useReactiveSet<Cid>()
  function skipLocationSelectorForDraft(draftCid: Cid): void {
    draftsThatHaveSkippedLocationSelector.value.add(draftCid)
  }
  function hasDraftSkippedLocationSelector(draftCid: Cid): boolean {
    return draftsThatHaveSkippedLocationSelector.value.has(draftCid)
  }

  /* --------------------------------------------- */
  /* PLACING PIN                                   */
  /* --------------------------------------------- */

  const isPlacingPin = ref<boolean>(false)
  const setIsPlacingPin = (value: boolean): void => {
    isPlacingPin.value = value
  }

  const placedPinSuggestions = ref<PinDropSuggestion[] | null>(null)
  function setPlacedPinSuggestions(suggestions: PinDropSuggestion[], location: LatLng): void {
    placedPinSuggestions.value = suggestions
  }

  function clearPlacedPinSuggestions(): void {
    placedPinSuggestions.value = null
  }

  // A draft's location can be edited only when a pin is being placed to set its location on mobile
  const draftWithLocationBeingEditedCid = ref<Cid | null>(null)
  function setDraftWithLocationBeingEditedCid(cid: Cid | null): void {
    draftWithLocationBeingEditedCid.value = cid
  }
  return {
    // state
    mapThemes,
    isFetchingMapThemes,
    currentThemeId,
    isMapThemeChanged,
    currentMapTheme,
    postListPosition,
    mapDoesFitMarkersBounds,
    map,
    pinDropSuggestions,
    pinDropLocation,
    isPickingLocation,
    postWithPopupBeingOpenedCid,
    postWithLocationBeingEditedCid,
    postWithMarkerHighlightedCid,
    sectionIdOfPostBeingAdded,
    overlappingMarkerSpiderfier,
    offsetForMapPopup,
    isPreviewPanelShown,
    recentLocationMenuSearches,
    isDraggingLocationPickerPin,
    isDraggingPosts,
    xMapLocationSelector,
    isPlacingPin,
    placedPinSuggestions,
    draftWithLocationBeingEditedCid,

    // actions
    fetchMapThemes,
    startDraggingPosts,
    stopDraggingPosts,
    enableMapFittingMarkersBounds,
    disableMapFittingMarkersBounds,
    setMapBeingUsed,
    setMapPinDropSuggestions,
    clearMapPinDropSuggestions,
    hidePickLocationPanel,
    startPickingLocation,
    stopPickingLocation,
    pickLocation,
    pickLocationAndCreateNewPost,
    pickLocationAndUpdateLocation,
    setMarkerOverlayLayer,
    openPostPopup,
    closePostPopup,
    highlightPostMarker,
    toggleMapPreviewPanel,
    saveRecentlySearchedLocations,
    syncRecentlySearchedLocationsFromStorage,
    startDraggingLocationPickerPin,
    stopDraggingLocationPickerPin,
    startPickingNewLocation,
    stopPickingNewLocation,
    skipLocationSelectorForDraft,
    setIsPlacingPin,
    setPlacedPinSuggestions,
    clearPlacedPinSuggestions,
    setDraftWithLocationBeingEditedCid,
  }
})
