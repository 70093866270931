// @file Content picker helper.
import { supportsRecording, supportsVideoRecording } from '@@/bits/av_recorder'
import device from '@@/bits/device'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { SearchFilters } from '@@/bits/web_search'
import { groupBy } from 'lodash-es'

interface ContentSource {
  type: ContentPickerSource
  text: string
  ariaLabel: string
  icon: string
  activeIcon: string
  color: 'purple' | 'blue' | 'green' | 'orange'
  visible: boolean
}

interface ContentPickerSourceMenuListItem {
  type: string
  text: string
  ariaLabel?: string
  icon?: string
  activeIcon?: string
  color: string
  hiddenInputId?: string
  visible: boolean
  disabled: boolean
  isNew?: boolean
  actionType?: string
  actionPayload?: string | object
  avatarUrl?: string
  avatarAltText?: string
  testId?: string
}

enum ContentPickerSource {
  FileBrowser = 'file',
  LinkInput = 'link',
  WebBrowser = 'web_search',
  PhotoBooth = 'photo_booth',
  VideoRecorder = 'video_recorder',
  AudioRecorder = 'audio_recorder',
  DrawingCanvas = 'drawing',
  PadletBrowser = 'padlet',
  MapBrowser = 'location_search',
  ScreenshareRecorder = 'screen_recorder',
  AiImageSearch = 'ai_image_search',
  ImageSearch = 'image_search',
  StockImageSearch = 'stock_image_search',
  VideoSearch = 'video_search',
  AudioSearch = 'audio_search',
  GifSearch = 'gif_search',
  Poll = 'poll',
  GoogleDrive = 'google_drive',
  AiTalk = 'ai_talk',
  MediaLibrary = 'media_library',
}

type NoSearchFilterError = 'NO_SEARCH_FILTER'

const CONTENT_PICKER_SOURCE_ARIA_LABEL_MAP_FOR_SETTINGS = {
  [ContentPickerSource.PadletBrowser]: __('Toggle padlet attachment'),
  [ContentPickerSource.FileBrowser]: __('Toggle upload attachment'),
  [ContentPickerSource.LinkInput]: __('Toggle link attachment'),
  [ContentPickerSource.PhotoBooth]: __('Toggle photo attachment'),
  [ContentPickerSource.VideoRecorder]: __('Toggle video recording attachment'),
  [ContentPickerSource.AudioRecorder]: __('Toggle audio recording attachment'),
  [ContentPickerSource.ScreenshareRecorder]: __('Toggle screen recording attachment'),
  [ContentPickerSource.DrawingCanvas]: __('Toggle drawing attachment'),
  [ContentPickerSource.Poll]: __('Toggle poll attachment'),
  [ContentPickerSource.GoogleDrive]: __('Toggle Google Drive attachment'),
  [ContentPickerSource.AiImageSearch]: __('Toggle AI Art Generator attachment'),
  [ContentPickerSource.AiTalk]: __('Toggle AI Speech Generator attachment'),
  [ContentPickerSource.ImageSearch]: __('Toggle image search'),
  [ContentPickerSource.GifSearch]: __('Toggle GIF attachment'),
  [ContentPickerSource.VideoSearch]: __('Toggle video search'),
  [ContentPickerSource.AudioSearch]: __('Toggle audio search'),
  [ContentPickerSource.WebBrowser]: __('Toggle web search'),
  [ContentPickerSource.MapBrowser]: __('Toggle location attachment'),
}

const getContentSources = (): ContentSource[] =>
  [
    {
      type: ContentPickerSource.PadletBrowser,
      text: __('Padlet'),
      ariaLabel: __('Attach another padlet'),
      icon: 'padlet_outline',
      activeIcon: 'padlet',
      color: 'purple',
      visible: true,
    },
    {
      type: ContentPickerSource.FileBrowser,
      text: __('Upload'),
      ariaLabel: __('Upload a file'),
      icon: 'upload_outline',
      activeIcon: 'upload',
      color: 'purple',
      visible: true,
    },
    {
      type: ContentPickerSource.LinkInput,
      text: __('Link'),
      ariaLabel: __('Attach a link'),
      icon: 'link',
      activeIcon: 'link_filled',
      color: 'purple',
      visible: true,
    },
    {
      type: ContentPickerSource.MediaLibrary,
      text: __('Photo and Video Library'),
      ariaLabel: __('Upload from photo library'),
      icon: 'photo_video_outline',
      activeIcon: 'photo_video',
      color: 'blue',
      visible: device.app,
    },
    {
      type: ContentPickerSource.PhotoBooth,
      text: __('Camera'),
      ariaLabel: __('Take a photo'),
      icon: 'camera_outline',
      activeIcon: 'camera',
      color: 'blue',
      visible: !device.zoomApp,
    },
    {
      type: ContentPickerSource.VideoRecorder,
      text: __('Video recorder'),
      ariaLabel: __('Record a video'),
      icon: 'video_outline',
      activeIcon: 'video',
      color: 'blue',
      visible: !device.zoomApp && (supportsVideoRecording || device.mobile),
    },
    {
      type: ContentPickerSource.AudioRecorder,
      text: __('Audio recorder'),
      ariaLabel: __('Record an audio'),
      icon: 'microphone_outline',
      activeIcon: 'microphone',
      color: 'blue',
      visible: !device.zoomApp && supportsRecording,
    },
    {
      type: ContentPickerSource.ScreenshareRecorder,
      text: __('Screen recorder'),
      ariaLabel: __('Record your screen'),
      icon: 'screen_recorder_outline',
      activeIcon: 'screen_recorder',
      color: 'blue',
      visible:
        !device.tablet &&
        !device.phone &&
        !device.mobile &&
        !device.zoomApp &&
        supportsVideoRecording &&
        (device.chrome || device.firefox),
    },
    {
      type: ContentPickerSource.DrawingCanvas,
      text: __('Draw'),
      ariaLabel: __('Draw something'),
      icon: 'draw_outline',
      activeIcon: 'draw',
      color: 'blue',
      visible: true,
    },
    {
      type: ContentPickerSource.Poll,
      text: __('Poll'),
      ariaLabel: __('Make a poll'),
      icon: 'poll_outline',
      activeIcon: 'poll',
      color: 'green',
      visible: true,
    },
    {
      type: ContentPickerSource.GoogleDrive,
      text: __('Google Drive'),
      ariaLabel: __('Upload from Google Drive'),
      icon: 'google_drive_outline',
      activeIcon: 'google_drive_fill',
      color: 'green',
      visible: isAppUsing('googleDrive') && !device.app,
    },
    {
      type: ContentPickerSource.AiImageSearch,
      text: __("I can't draw"),
      ariaLabel: __('Use AI Art Generator'),
      icon: 'sparkles_outline',
      activeIcon: 'sparkles',
      color: 'green',
      visible: isAppUsing('aiImageSearch'),
    },
    {
      type: ContentPickerSource.AiTalk,
      text: __('Talk for me'),
      ariaLabel: __('Use AI Speech Generator'),
      icon: 'communicate',
      activeIcon: 'communicate_filled',
      color: 'green',
      visible: isAppUsing('aiTalk') && !device.app,
    },
    {
      type: ContentPickerSource.ImageSearch,
      text: __('Image search'),
      ariaLabel: __('Search for an image'),
      icon: 'image_search_outline',
      activeIcon: 'image_search',
      color: 'orange',
      visible: true,
    },
    {
      type: ContentPickerSource.GifSearch,
      text: __('GIF'),
      ariaLabel: __('Search for a GIF'),
      icon: 'gif',
      activeIcon: 'gif',
      color: 'orange',
      visible: true,
    },
    {
      type: ContentPickerSource.VideoSearch,
      text: __('YouTube'),
      ariaLabel: __('Search on YouTube'),
      icon: 'youtube_outline',
      activeIcon: 'youtube',
      color: 'orange',
      visible: true,
    },
    {
      type: ContentPickerSource.AudioSearch,
      text: __('Spotify'),
      ariaLabel: __('Search on Spotify'),
      icon: 'spotify_outline',
      activeIcon: 'spotify',
      color: 'orange',
      visible: true,
    },
    {
      type: ContentPickerSource.WebBrowser,
      text: __('Web search'),
      ariaLabel: __('Search on the web'),
      icon: 'web_search_outline',
      activeIcon: 'web_search',
      color: 'orange',
      visible: true,
    },
    {
      type: ContentPickerSource.MapBrowser,
      text: __('Location'),
      ariaLabel: __('Add a location'),
      icon: 'location_outline',
      activeIcon: 'location',
      color: 'orange',
      visible: true,
    },
  ].filter((source): source is ContentSource => source.visible)

const getContentSourcesByColor = (): Record<string, ContentSource[]> => groupBy(getContentSources(), 'color')

const SEARCH_MODE_BY_CONTENT_SOURCE = {
  [ContentPickerSource.ImageSearch]: SearchFilters.Images,
  [ContentPickerSource.GifSearch]: SearchFilters.Gif,
  [ContentPickerSource.VideoSearch]: SearchFilters.Videos,
  [ContentPickerSource.AudioSearch]: SearchFilters.Audio,
  [ContentPickerSource.WebBrowser]: SearchFilters.Web,
} as const

export {
  CONTENT_PICKER_SOURCE_ARIA_LABEL_MAP_FOR_SETTINGS,
  ContentPickerSource,
  SEARCH_MODE_BY_CONTENT_SOURCE,
  getContentSources,
  getContentSourcesByColor,
}
export type { ContentPickerSourceMenuListItem, ContentSource, NoSearchFilterError }
