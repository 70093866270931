// @file surface_automations

import { formatDate } from '@@/bits/date'
import { p__, __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'

export type AutomationActionType = 'freeze_wall' | 'unfreeze_wall'
export const ALL_AUTOMATION_ACTIONS: AutomationActionType[] = ['freeze_wall', 'unfreeze_wall']
export type AutomationTriggerType = 'schedule'
export interface AutomationAction {
  action_type: AutomationActionType
  action_props?: any // in future we will expand this
}
export interface AutomationBase {
  trigger_event: AutomationTriggerType
  actions: AutomationAction[]
  id?: string
}

export interface ScheduledAutomation extends AutomationBase {
  trigger_event: 'schedule'
  trigger_props: {
    scheduled_at: string // ISO datetime string
  }
}

export type Automation = ScheduledAutomation

export const getActionText = (actionType: AutomationActionType): string => {
  switch (actionType) {
    case 'freeze_wall':
      return __('Freeze')
    case 'unfreeze_wall':
      return __('Unfreeze')
    default:
      return actionType
  }
}

export const getActionIcon = (actionType: AutomationActionType): string => {
  switch (actionType) {
    case 'freeze_wall':
      return 'freeze'
    case 'unfreeze_wall':
      return 'unfreeze'
    default:
      return ''
  }
}

export const getScheduledAutomationDisplayName = (actionType: AutomationActionType, scheduledAt: string): string => {
  const formattedAction = getActionText(actionType)
  const formattedDate = formatDate(scheduledAt, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })

  return p__(
    'Describes the action to be performed by the automation on a specified date at a specified time.',
    '{formattedAction} on {formattedDate}',
    {
      formattedAction,
      formattedDate,
    },
  )
}

export enum Panel {
  MAIN = 'main',
  SETTINGS = 'settings',
}

/**
 * Checks if an automation is valid for creation based on its scheduled datetime and existing automations.
 * If more validations are necessary, add it to this method.
 * @param automation - The automation to be checked.
 * @param existingAutomations - An array of existing automations to compare against.
 * @returns An object containing the validity status and validation message.
 */
export const isAutomationValid = (
  automation: Automation,
  existingAutomations: Automation[],
): { isValid: boolean; validationMessage: string } => {
  // An automation conflicts if
  // 1. timestamp is the same and
  // 2. if actions are the same (eg both freeze padlet) or conflict (1 is freeze padlet, 1 to unfreeze padlet)
  const automationWithSameDatetime = existingAutomations.find(
    (a) => a.trigger_props.scheduled_at === automation.trigger_props.scheduled_at,
  )
  const isValid = automationWithSameDatetime == null
  const validationMessage = isValid
    ? ''
    : automation.actions[0].action_type === automationWithSameDatetime.actions[0].action_type
    ? __('Duplicate of %{automation}', {
        automation: getScheduledAutomationDisplayName(
          automationWithSameDatetime.actions[0].action_type,
          automationWithSameDatetime.trigger_props.scheduled_at,
        ),
      })
    : __('Conflicting with %{automation}', {
        automation: getScheduledAutomationDisplayName(
          automationWithSameDatetime.actions[0].action_type,
          automationWithSameDatetime.trigger_props.scheduled_at,
        ),
      })
  return { isValid, validationMessage }
}

/**
 * Compares two objects and returns the attributes that have changed.
 * @param newObj - The new object.
 * @param oldObj - The old object.
 * @returns An object containing the changed attributes and their new values.
 */
export const getChangedAttributes = <T extends Record<string, any>>(newObj: T, oldObj: T): Partial<T> => {
  const changedAttributes: Partial<T> = {}
  Object.keys(newObj).forEach((key) => {
    if (asciiSafeStringify(newObj[key]) !== asciiSafeStringify(oldObj[key])) {
      changedAttributes[key as keyof T] = newObj[key]
    }
  })
  return changedAttributes
}
