// @file Surface store
import window from '@@/bits/global'
import { removeHashidPrefix } from '@@/bits/hashid_helper'
import type { UrlOptions } from '@@/bits/location'
import type { ContentModerationMode } from '@@/enums'
import { WallVizEnum } from '@@/enums'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import type { ConfirmationDialogPayload } from '@@/pinia/global_confirmation_dialog'
import { addressListener } from '@@/pinia/plugin/address_listener'
import { useSurfaceAIChatStore } from '@@/pinia/surface_ai_chat_store'
import { useSurfaceGradingPanelStore } from '@@/pinia/surface_grading_panel'
import { useSurfaceOnboardingPanelStore } from '@@/pinia/surface_onboarding_panel'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { useSurfaceStartingStateStore } from '@@/pinia/surface_starting_state'
import type { HashId, Id, Library, PopoverAnchor, User, WallBackground, WallLayoutAttributes, WallViz } from '@@/types'
import { PostSize } from '@@/types'
import { useFreezePadlet } from '@@/vuecomposables/freeze_padlet_store'
import { useSurfaceFetchInitialState } from '@@/vuecomposables/surface_fetch_initial_state'
import type { Wall } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const SEARCH_BAR_DEFAULT_TOP = 16

export const useSurfaceStore = defineStore('surface', () => {
  // Initialize the addressListener plugin
  window.app?.$pinia.use(addressListener)

  const surfaceVuexStore = window.app?.$store // For gradual conversion to pinia

  const surfaceGradingPanelStore = useSurfaceGradingPanelStore()
  const onboardingPanelStore = useSurfaceOnboardingPanelStore()
  const surfaceAIChatStore = useSurfaceAIChatStore()
  const darkModeStore = useDarkModeStore()
  const surfaceSettingsStore = useSurfaceSettingsStore()

  // State
  const xSearchBar = ref(false)
  const isSearchBarInEditMode = ref(false)
  const searchBarTop = ref(SEARCH_BAR_DEFAULT_TOP)
  const hasAddPostButtonBeenClicked = ref(false)
  const xExpandedCommentImagePanel = ref(false)

  // Getters
  const isTemplate = computed<boolean>(() => surfaceVuexStore?.getters.isTemplate)
  const isMobileLayout = computed<boolean>(() => surfaceVuexStore?.getters.isMobileLayout)
  const isDesktopLayout = computed<boolean>(() => surfaceVuexStore?.getters.isDesktopLayout)
  const isScreenshotMode = computed<boolean>(() => surfaceVuexStore?.getters.isScreenshotMode)
  const xAuthorInScreenshotMode = computed<boolean>(() => surfaceVuexStore?.state.xAuthorInScreenshotMode)
  const xCommentsInScreenshotMode = computed<boolean>(() => surfaceVuexStore?.state.xCommentsInScreenshotMode)
  const xReactionsInScreenshotMode = computed<boolean>(() => surfaceVuexStore?.state.xReactionsInScreenshotMode)
  const isGeneratingPDF = computed<boolean>(() => surfaceVuexStore?.getters.isGeneratingPDF)
  const isOnline = computed(() => surfaceVuexStore?.state.isOnline)
  const isOverlayVisible = computed<boolean>(() => surfaceVuexStore?.getters.isOverlayVisible ?? false)
  const wallId = computed(() => surfaceVuexStore?.state.wall.id)
  const wallHashid = computed<HashId>(() => surfaceVuexStore?.state.wall.hashid)
  const wallHashidWithoutPrefix = computed<HashId>(() => removeHashidPrefix(wallHashid.value))
  const isUserHostingZoomCollaboration = computed<boolean>(() => surfaceVuexStore?.state.isUserHostingZoomCollaboration)
  const background = computed<WallBackground>(() => surfaceVuexStore?.getters.background)
  const postSize = computed<PostSize>(() => surfaceVuexStore?.getters.postSize)
  const isPostSizeWide = computed<boolean>(() => postSize.value === PostSize.WIDE)
  const isPostSizeStandard = computed<boolean>(() => postSize.value === PostSize.STANDARD)
  const isWhiteboard = computed<boolean>(() => viz.value === WallVizEnum.Whiteboard)
  const isGrid = computed<boolean>(() => surfaceVuexStore?.getters.isGrid)
  const isMap = computed<boolean>(() => surfaceVuexStore?.getters.isMap)
  const isShelf = computed<boolean>(() => surfaceVuexStore?.getters.isShelf)
  const isStream = computed<boolean>(() => surfaceVuexStore?.getters.isStream)
  const isSectioned = computed<boolean>(() => surfaceVuexStore?.getters.isSectioned)
  const isTimeline = computed<boolean>(() => surfaceVuexStore?.getters.isTimeline)
  const isTimelineV1 = computed<boolean>(() => surfaceVuexStore?.getters.isTimelineV1)
  const isTimelineV2 = computed<boolean>(() => surfaceVuexStore?.getters.isTimelineV2)
  const isCanvas = computed<boolean>(() => surfaceVuexStore?.getters.isCanvas)
  const isMatrix = computed<boolean>(() => surfaceVuexStore?.getters.isMatrix)
  const isTable = computed<boolean>(() => surfaceVuexStore?.getters.isTable)
  const isCustomBackground = computed<boolean>(() => surfaceVuexStore?.getters.background.is_custom)
  const isBackgroundBlurred = computed<boolean>(() => surfaceVuexStore?.getters.isBackgroundBlurred)
  const isTitleBarTransparent = computed<boolean>(() => surfaceVuexStore?.getters.isTitleBarTransparent)
  const backgroundColor = computed(() => surfaceVuexStore?.getters.backgroundColor)
  const backgroundLuminance = computed<'light' | 'dark'>(() => surfaceVuexStore?.getters.backgroundLuminance)
  const colorScheme = computed<'light' | 'dark'>(() => surfaceVuexStore?.getters.colorScheme)
  const skinAccentColorAsRGB = computed(() => surfaceVuexStore?.getters.skinAccentColorAsRGB)
  const skinPrimaryColorAsRGB = computed(() => surfaceVuexStore?.getters.skinPrimaryColorAsRGB)
  const skinPrimaryVariantColorAsRGB = computed(() => surfaceVuexStore?.getters.skinPrimaryVariantColorAsRGB)
  const skinPrimaryTextColor = computed(() => surfaceVuexStore?.getters.skinPrimaryTextColor)
  const skinAccentTextColor = computed(() => surfaceVuexStore?.getters.skinAccentTextColor)
  const isSkinColorAvailable = computed<boolean>(() => {
    return (
      !!skinPrimaryTextColor.value &&
      !!skinPrimaryVariantColorAsRGB.value &&
      !!skinAccentColorAsRGB.value &&
      !!skinAccentColorAsRGB.value
    )
  })
  const useSkinColors = computed(() => !!skinAccentColorAsRGB.value && !!skinAccentTextColor.value)
  const onboardingDemoButtonStyle = computed(() => {
    if (surfaceSettingsStore.isUsingSolidPurpleLightWallpaper) {
      return { backgroundColor: '#AE56E4', color: '#FFFFFF' }
    }

    if (useSkinColors.value) {
      return {
        backgroundColor: skinAccentColorAsRGB.value,
        color: skinAccentTextColor.value,
      }
    }

    return {}
  })
  const buttonDarkMode = computed<boolean | 'auto'>(() => {
    if (!isSkinColorAvailable.value) {
      return darkModeStore.isBrowserDarkMode
    }
    // skinPrimaryTextColor is the color for text/button icon when shown on top of the skin's primary color
    // darkMode means dark background and light icons
    // => buttonDarkMode  should be true when we want white text color
    return skinPrimaryTextColor.value === 'white'
  })
  const isReadOnly = computed(() => surfaceVuexStore?.getters.isReadOnly)
  const isSimplifiedView = computed(() => surfaceVuexStore?.getters.isSimplifiedView)
  const isEmbedded = computed<boolean>(() => surfaceVuexStore?.state.isEmbedded)
  const isLtiEmbedded = computed<boolean>(() => surfaceVuexStore?.state.isLtiEmbedded)
  const isWhiteboardEmbedPlayMode = computed<boolean>(() => surfaceVuexStore?.state.isWhiteboardEmbedPlayMode)
  const isNonLtiEmbedded = computed(() => isEmbedded.value && !isLtiEmbedded.value)
  const isElectronApp = computed<boolean>(() => Boolean(surfaceVuexStore?.state.isElectronApp))
  const defaultPdfPageSize = computed<string>(() => surfaceVuexStore?.state.defaultPdfPageSize)
  const dir = computed<'ltr' | 'rtl'>(() => surfaceVuexStore?.getters.dir)
  const fonts = computed(() => {
    // in SettingsV2, we want the fonts to be in a different order
    const fontIdOrder = [3, 2, 4, 1]
    const resultFonts = fontIdOrder.map((id) =>
      surfaceVuexStore?.state.constants.allFonts.find((font) => font.id === id),
    )
    return resultFonts
  })
  const fontId = computed(() => surfaceVuexStore?.getters.fontId)
  const viz = computed<WallViz>(() => surfaceVuexStore?.getters.viz)
  const format = computed<WallViz>(() => surfaceVuexStore?.getters.format)
  const newPostLocation = computed<'top' | 'bottom'>(() => surfaceVuexStore?.getters.newPostLocation)
  const address = computed<string>(() => surfaceVuexStore?.getters.address)
  const builder = computed<User>(() => surfaceVuexStore?.getters.builder)
  const title = computed<string>(() => surfaceVuexStore?.getters.title)
  const description = computed<string | undefined>(() => surfaceVuexStore?.getters.description)
  const portrait = computed<string>(() => surfaceVuexStore?.getters.portrait)
  const wallCreatedAt = computed<string>(() => surfaceVuexStore?.getters.wallCreatedAt)
  const wallUpdatedAt = computed<string>(() => surfaceVuexStore?.getters.wallUpdatedAt)
  const wallAttributes = computed<Partial<Wall>>(() => surfaceVuexStore?.state.wall)
  const mapThemeId = computed<number>(() => surfaceVuexStore?.state.wall.theme_id)
  const contentModeration = computed<ContentModerationMode>(() => surfaceVuexStore?.state.wall.content_moderation)
  const isRefreshPending = computed<boolean>(() => surfaceVuexStore?.state.isRefreshPending)
  const filterProfanity = computed<boolean>(() => surfaceVuexStore?.getters.filterProfanity)
  const domainName = computed<string>(() => surfaceVuexStore?.getters.domainName)
  const namespace = computed<string>(() => surfaceVuexStore?.getters.namespace)
  const publicKey = computed<string>(() => surfaceVuexStore?.getters.publicKey)
  const permalink = computed<string>(() => surfaceVuexStore?.getters.name)
  const links = computed<Record<string, string>>(() => surfaceVuexStore?.getters.links)
  const xReportPanel = computed<boolean>(() => surfaceVuexStore?.getters.xReportPanel)
  const reportedPostId = computed<Id | null>(() => surfaceVuexStore?.getters.reportedPostId)
  const coverPostId = computed<Id | null>(() => surfaceVuexStore?.getters.coverPostId)
  const originalBackground = computed<WallBackground>(() => surfaceVuexStore?.getters.originalBackground)
  const xAuthor = computed<boolean>(() => {
    if (isScreenshotMode.value && !xAuthorInScreenshotMode.value) return false
    return surfaceVuexStore?.getters.xAuthor
  })
  const xCommentsUnderPosts = computed<boolean>(() => {
    if (isScreenshotMode.value && !xCommentsInScreenshotMode.value) return false
    return (
      surfaceVuexStore?.getters.isMap === false &&
      surfaceVuexStore?.getters.isTable === false &&
      surfaceVuexStore?.getters.isCanvas === false &&
      surfaceVuexStore?.getters.isTimeline === false
    )
  })
  const uploadLimit = computed(() => wallAttributes.value?.upload_limit)
  const whiteboardFrameLimit = computed<number>(
    () => (wallAttributes.value?.whiteboard_frame_limit as number | undefined) ?? 20,
  )
  const isBuilderCurrentUser = computed((): boolean => surfaceVuexStore?.getters.isBuilderCurrentUser)

  const canUseSections = computed<boolean>(() => surfaceVuexStore?.getters.sectionableLayouts.includes(viz.value))
  const canHaveConnections = computed<boolean>(() => surfaceVuexStore?.getters.canHaveConnections)
  const isUsedInEducationalContext = computed<boolean>(() => surfaceVuexStore?.getters.isUsedInEducationalContext)
  const user = computed<User>(() => surfaceVuexStore?.getters.user)
  const xFilterProfanitySetting = computed<boolean>(() => surfaceVuexStore?.getters.xFilterProfanitySetting ?? false)
  const allLayouts = computed(() => surfaceVuexStore?.state.constants.allLayouts)
  const allLayoutsByGroup = computed<{
    simple: WallLayoutAttributes[]
    advanced: WallLayoutAttributes[]
  }>(() => surfaceVuexStore?.state.constants.allLayoutsByGroup)
  const breakoutSectionId = computed(() => surfaceVuexStore?.state.breakoutSectionId)
  const isSectionBreakout = computed<boolean>(() => breakoutSectionId.value !== null)
  const isSubmissionRequest = computed<boolean>(() => surfaceVuexStore?.state.isSubmissionRequest)
  const isSlideshow = computed<boolean>(() => surfaceVuexStore?.getters.isSlideshow)
  const blockedContentProviders = computed<Set<string>>(() => surfaceVuexStore?.getters.blockedContentProviders)
  const homeUrl = computed<string>(() => surfaceVuexStore?.getters.homeUrl)
  const xEditBookmarksDialog = computed<boolean>(() => surfaceVuexStore?.getters.xEditBookmarksDialog)
  const editBookmarksDialogPopoverAnchor = computed<PopoverAnchor | null>(
    () => surfaceVuexStore?.getters.editBookmarksDialogPopoverAnchor,
  )
  const loginAndRedirectToWallUrl = computed<string>(() => surfaceVuexStore?.getters.loginAndRedirectToWallUrl)
  const loginAndRedirectUrlExpireDuration = computed<string>(
    () => surfaceVuexStore?.getters.loginAndRedirectUrlExpireDuration,
  )
  const xActionBarMoreMenu = computed<boolean>(() => surfaceVuexStore?.getters.xActionBarMoreMenu)
  const xWallQuotaExceeded = computed<boolean>(() => surfaceVuexStore?.state.xWallQuotaExceeded)
  const fileThatExceededSize = computed(() => surfaceVuexStore?.state.fileThatExceededSize)
  const xRemakePanelOld = computed<boolean>(() => surfaceVuexStore?.state.xRemakePanelOld)
  const isNativeMenu = computed(() => surfaceVuexStore?.state.isNativeMenu)
  const xPermitNotification = computed<boolean>(() => surfaceVuexStore?.state.xPermitNotification)
  const xReloadNotification = computed<boolean>(() => surfaceVuexStore?.state.xReloadNotification)
  const isFirstView = computed<boolean>(() => surfaceVuexStore?.getters.isFirstView)
  const isMagicWall = computed<boolean>(() => surfaceVuexStore?.getters.isMagicWall)
  const magicWallType = computed<string>(() => surfaceVuexStore?.getters.magicWallType)
  const xNewComposerModalPanel = computed<boolean>(() => surfaceVuexStore?.getters.xNewComposerModalPanel)
  const xDevelopersPanel = computed<boolean>(() => surfaceVuexStore?.getters.xDevelopersPanel)
  const xSettingsPanel = computed<boolean>(() => surfaceVuexStore?.getters.xSettingsPanel)
  const isSubscriptionPaused = computed<boolean>(() => surfaceVuexStore?.getters.isSubscriptionPaused)

  // Actions

  /**
   * Open the search bar.
   * To use where updating xSearchBar directly is not possible (e.g. in a Vue 2 component).
   */
  function openSearchBar(): void {
    xSearchBar.value = true
  }

  function updateSearchBarTop(top: number): void {
    searchBarTop.value = top
  }

  function refresh({ isAutoRefresh }: { isAutoRefresh?: boolean }): void {
    void surfaceVuexStore?.dispatch('refresh', { isAutoRefresh })
  }

  function changeConnectivity(isOnline: boolean): void {
    void surfaceVuexStore?.dispatch('changeConnectivity', { isOnline })
  }

  function hideReportPanel(): void {
    void surfaceVuexStore?.dispatch('hideReportPanel')
  }

  function hideDevelopersPanel(): void {
    void surfaceVuexStore?.dispatch('hideDevelopersPanel')
  }

  function showOverFileSizeModal({ file }: { file: File }): void {
    void surfaceVuexStore?.dispatch('showOverFileSizeModal', { file })
  }

  function showPromptToLoginDialog(
    payload: Partial<ConfirmationDialogPayload> & { urlTransformParams?: UrlOptions },
  ): void {
    void surfaceVuexStore?.dispatch('showPromptToLoginDialog', payload)
  }

  // Header
  const xHeader = computed(() => surfaceVuexStore?.getters.xHeader)
  const headerHeight = computed<number>(() => surfaceVuexStore?.getters.headerHeight)
  const headerColor = computed<string>(() => surfaceVuexStore?.getters.headerColor)
  function resizeHeader({ height }: { height: number }): void {
    void surfaceVuexStore?.dispatch('resizeHeader', { height })
  }

  // libraries
  const library = computed<
    Partial<Library> & Partial<{ walls_used: number; makers_count: number; dashboard_url: string }>
  >(() => surfaceVuexStore?.getters.library)
  const isLibraryWall = computed<boolean>(() => surfaceVuexStore?.getters.isLibraryWall)
  const libraryName = computed<string>(() => surfaceVuexStore?.getters.libraryName)
  const libraryId = computed<number | undefined>(() => surfaceVuexStore?.getters.wallLibraryId)

  // tenants
  const isOrgWall = computed<boolean>(() => surfaceVuexStore?.getters.isOrgWall)
  const orgName = computed<string>(() => surfaceVuexStore?.getters.tenantName)
  const tenantId = computed<number | undefined>(() => wallAttributes.value?.tenant_id)

  const isNativeTenant = computed<boolean>(() => {
    return wallAttributes.value?.tenant_id === 1
  })

  // frozen state
  const { isFrozen, toggleFreezePadlet, handlePadletFrozen, handlePadletUnfrozen } = useFreezePadlet(
    wallAttributes,
    surfaceVuexStore,
  )

  // padlets in a personal library that's over quota are always frozen
  // similar logic to wall.rb#is_frozen?
  const isFrozenDueToOverQuota = computed(() => {
    // Whiteboards created before 1 Oct 2024 are not frozen
    if (!isLibraryWall.value && isWhiteboard.value && Date.now() < Date.UTC(2024, 9, 1)) return false
    return isNativeTenant.value && !isLibraryWall.value && builder.value.days_before_freeze === 0
  })

  // templates
  function markWallAsTemplate(): void {
    void surfaceVuexStore?.dispatch('markWallAsTemplate')
  }

  function setWallAsTemplate(): void {
    void surfaceVuexStore?.commit('MARK_WALL_AS_TEMPLATE')
  }

  function unmarkWallAsTemplate(): void {
    void surfaceVuexStore?.dispatch('unmarkWallAsTemplate')
  }

  function unsetWallAsTemplate(): void {
    void surfaceVuexStore?.commit('UNMARK_WALL_AS_TEMPLATE')
  }

  // Side panel
  const hasSidePanelOutsideSurface = computed(
    () =>
      onboardingPanelStore.xOnboardingPanel ||
      surfaceGradingPanelStore.xSurfaceGradingPanel ||
      surfaceAIChatStore.xSurfaceAIChatPanel,
  )

  const isSidePanelRounded = computed(
    () => onboardingPanelStore.xOnboardingPanel || surfaceGradingPanelStore.xSurfaceGradingPanel,
  )

  // Transfer Wall
  const xTransferWallDialog = ref<boolean>(false)

  function openTransferWallDialog(): void {
    xTransferWallDialog.value = true
  }

  function closeTransferWallDialog(): void {
    xTransferWallDialog.value = false
  }

  function checkAndSubscribeToPushNotifications(): void {
    void surfaceVuexStore?.dispatch('checkAndSubscribeToPushNotifications')
  }

  function promptNameForNewFolder(): void {
    void surfaceVuexStore?.dispatch('promptNameForNewFolder')
  }

  function closeEditBookmarksDialog(): void {
    void surfaceVuexStore?.dispatch('closeEditBookmarksDialog')
  }

  async function bookmarkWallAndNotify(): Promise<void> {
    await surfaceVuexStore?.dispatch('bookmarkWallAndNotify')
  }

  function openEditBookmarksDialog(anchor: PopoverAnchor | null = null): void {
    void surfaceVuexStore?.dispatch('openEditBookmarksDialog', anchor)
  }

  function showRemakePanelOld(): void {
    void surfaceVuexStore?.dispatch('showRemakePanelOld')
  }

  function showClearAllDialog(): void {
    void surfaceVuexStore?.dispatch('showClearAllDialog')
  }

  function archiveWall(): void {
    void surfaceVuexStore?.dispatch('archiveWall')
  }

  function showDeleteDialog(): void {
    void surfaceVuexStore?.dispatch('showDeleteDialog')
  }

  function trashWall(): void {
    void surfaceVuexStore?.dispatch('trashWall')
  }

  function showReportPanel({ postId }: { postId?: Id } = {}): void {
    void surfaceVuexStore?.dispatch('showReportPanel', { postId })
  }

  function showDevelopersPanel(): void {
    void surfaceVuexStore?.dispatch('showDevelopersPanel')
  }

  function showCommentsPanel(postId): void {
    void surfaceVuexStore?.dispatch('showCommentsPanel', { postId })
  }

  function showActionBarMoreMenu(): void {
    void surfaceVuexStore?.dispatch('showActionBarMoreMenu')
  }

  function hideActionBarMoreMenu(): void {
    void surfaceVuexStore?.dispatch('hideActionBarMoreMenu')
  }

  function hideWallQuotaExceeded(): void {
    void surfaceVuexStore?.dispatch('hideWallQuotaExceeded')
  }

  function hidePermitNotification(): void {
    void surfaceVuexStore?.dispatch('hidePermitNotification')
  }

  function hideReloadNotification(): void {
    void surfaceVuexStore?.dispatch('hideReloadNotification')
  }

  function updateUser(user: User): void {
    void surfaceVuexStore?.dispatch('updateUser', user)
  }

  function setAddPostButtonClickStatus(status: boolean): void {
    hasAddPostButtonBeenClicked.value = status
  }

  async function refetchStartingStateAndCreateNewBrahmsConnection(): Promise<void> {
    const surfaceStartingStateStore = useSurfaceStartingStateStore()
    await surfaceStartingStateStore.fetchStartingState()
    useSurfaceFetchInitialState().fetchInitialState()
    void surfaceVuexStore?.dispatch('refreshPadletStartingState', surfaceStartingStateStore.startingState)
    void surfaceVuexStore?.dispatch('realtime/createNewBrahmsConnectionForUser', {
      userId: surfaceStartingStateStore.startingState.user.id,
      brahmsToken: surfaceStartingStateStore.startingState.brahmsToken,
    })
  }

  return {
    // State
    xSearchBar,
    isSearchBarInEditMode,
    searchBarTop,
    isRefreshPending,
    xTransferWallDialog,
    hasSidePanelOutsideSurface,
    isSidePanelRounded,
    hasAddPostButtonBeenClicked,
    xExpandedCommentImagePanel,

    // Getters
    isTemplate,
    isMobileLayout,
    isDesktopLayout,
    isScreenshotMode,
    xCommentsInScreenshotMode,
    xReactionsInScreenshotMode,
    isGeneratingPDF,
    isOverlayVisible,
    isUserHostingZoomCollaboration,
    wallId,
    wallHashid,
    wallHashidWithoutPrefix,
    xHeader,
    headerHeight,
    headerColor,
    background,
    isGrid,
    isMap,
    isShelf,
    isSectioned,
    isStream,
    isTimeline,
    isTimelineV1,
    isTimelineV2,
    isCanvas,
    isMatrix,
    isTable,
    isWhiteboard,
    isCustomBackground,
    isBackgroundBlurred,
    isTitleBarTransparent,
    backgroundColor,
    skinAccentColorAsRGB,
    skinPrimaryColorAsRGB,
    skinPrimaryVariantColorAsRGB,
    skinPrimaryTextColor,
    skinAccentTextColor,
    isSkinColorAvailable,
    buttonDarkMode,
    backgroundLuminance,
    colorScheme,
    isReadOnly,
    isSimplifiedView,
    isFrozen,
    isFrozenDueToOverQuota,
    isEmbedded,
    isLtiEmbedded,
    isWhiteboardEmbedPlayMode,
    isNonLtiEmbedded,
    isElectronApp,
    defaultPdfPageSize,
    dir,
    fonts,
    fontId,
    viz,
    format,
    newPostLocation,
    address,
    builder,
    title,
    description,
    portrait,
    wallAttributes,
    mapThemeId,
    filterProfanity,
    domainName,
    namespace,
    publicKey,
    permalink,
    links,
    canUseSections,
    canHaveConnections,
    isOnline,
    xReportPanel,
    reportedPostId,
    coverPostId,
    originalBackground,
    xAuthor,
    xCommentsUnderPosts,
    isUsedInEducationalContext,
    wallCreatedAt,
    wallUpdatedAt,
    user,
    xFilterProfanitySetting,
    allLayouts,
    allLayoutsByGroup,
    postSize,
    isPostSizeWide,
    isPostSizeStandard,
    breakoutSectionId,
    isSectionBreakout,
    isSubmissionRequest,
    isSlideshow,
    blockedContentProviders,
    homeUrl,
    uploadLimit,
    library,
    isLibraryWall,
    isOrgWall,
    libraryName,
    orgName,
    xEditBookmarksDialog,
    editBookmarksDialogPopoverAnchor,
    tenantId,
    libraryId,
    loginAndRedirectToWallUrl,
    loginAndRedirectUrlExpireDuration,
    xActionBarMoreMenu,
    xWallQuotaExceeded,
    fileThatExceededSize,
    xRemakePanelOld,
    isNativeMenu,
    xPermitNotification,
    xReloadNotification,
    isFirstView,
    isMagicWall,
    magicWallType,
    xNewComposerModalPanel,
    xDevelopersPanel,
    xSettingsPanel,
    isSubscriptionPaused,
    isNativeTenant,
    contentModeration,
    whiteboardFrameLimit,
    isBuilderCurrentUser,
    onboardingDemoButtonStyle,

    // Actions
    checkAndSubscribeToPushNotifications,
    openSearchBar,
    updateSearchBarTop,
    refresh,
    changeConnectivity,
    handlePadletFrozen,
    handlePadletUnfrozen,
    hideReportPanel,
    hideDevelopersPanel,
    showOverFileSizeModal,
    showPromptToLoginDialog,
    toggleFreezePadlet,
    markWallAsTemplate,
    setWallAsTemplate,
    unmarkWallAsTemplate,
    unsetWallAsTemplate,
    openTransferWallDialog,
    closeTransferWallDialog,
    resizeHeader,
    promptNameForNewFolder,
    closeEditBookmarksDialog,
    bookmarkWallAndNotify,
    openEditBookmarksDialog,
    showRemakePanelOld,
    showClearAllDialog,
    archiveWall,
    showDeleteDialog,
    trashWall,
    showReportPanel,
    showDevelopersPanel,
    showCommentsPanel,
    showActionBarMoreMenu,
    hideActionBarMoreMenu,
    hideWallQuotaExceeded,
    hidePermitNotification,
    hideReloadNotification,
    updateUser,
    setAddPostButtonClickStatus,
    refetchStartingStateAndCreateNewBrahmsConnection,
  }
})
