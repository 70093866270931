// @file Fetch initial state for the surface page.
import { captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { useCommentAttachmentSettingsStore } from '@@/pinia/comment_attachment_settings'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostPropertiesStore } from '@@/pinia/surface_post_properties'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { useSurfaceShareLinksStore } from '@@/pinia/surface_share_links'
import PadletApi from '@@/surface/padlet_api'
import { storeToRefs } from 'pinia'

interface InitialStateFetchOptions {
  postDrafts?: boolean
  wallPostProperties?: boolean
  wallCustomPostProperties?: boolean
  commentAttachmentSettings?: boolean
  submissionRequestLink?: boolean
}

export const useSurfaceFetchInitialState = (): {
  fetchInitialState: (payload?: InitialStateFetchOptions) => void
} => {
  const { wallId, wallHashid } = storeToRefs(useSurfaceStore())
  const { isCommentable } = storeToRefs(useSurfaceSettingsStore())
  const { amIRegistered } = storeToRefs(useSurfacePermissionsStore())
  const surfacePostDraftsStore = useSurfaceDraftsStore()
  const surfaceGuestStore = useSurfaceGuestStore()
  const { hasFetchedInitialPostDrafts } = storeToRefs(surfacePostDraftsStore)

  const { fetchSubmissionRequestLink } = useSurfaceShareLinksStore()
  const { setWallPostProperties, setCustomPostProperties } = useSurfacePostPropertiesStore()
  const { fetchCommentAttachmentSettingsAndSaveToStore } = useCommentAttachmentSettingsStore()

  /** Define */
  const fetchWallPostPropertiesAndSaveToStore = async (): Promise<void> => {
    try {
      const postProperties = await PadletApi.WallPostProperties.fetchWallPostProperties(wallId.value)
      setWallPostProperties(postProperties)
    } catch (e) {
      captureFetchException(e, { source: 'useSurfaceFetchInitialState' })
    }
  }

  const fetchWallCustomPostPropertiesAndSaveToStore = async (): Promise<void> => {
    try {
      const customPostProperties = await PadletApi.WallCustomPostProperties.fetchWallCustomPostProperties(wallId.value)
      setCustomPostProperties(customPostProperties)
    } catch (e) {
      captureFetchException(e, { source: 'useSurfaceFetchInitialState' })
    }
  }

  const fetchPostDraftsAndPublishDraftPost = async (): Promise<void> => {
    await surfacePostDraftsStore.fetchPostDrafts()

    // Publish the post draft created when the user is not logged in
    if (surfaceGuestStore.shouldEnableAnonymousAttribution) {
      void surfaceGuestStore.publishDraftPost()
    }
  }

  const fetchInitialState = ({
    postDrafts = true,
    wallPostProperties = true,
    wallCustomPostProperties = true,
    commentAttachmentSettings = true,
    submissionRequestLink = true,
  }: InitialStateFetchOptions = {}): void => {
    /** Register */
    const promises: Array<Promise<void>> = []
    if (postDrafts) {
      // TODO: replace the block below with `promises.push(fetchPostDraftsAndPublishDraftPost())` after the launch
      if (surfaceGuestStore.shouldEnableAnonymousAttribution || amIRegistered.value) {
        promises.push(fetchPostDraftsAndPublishDraftPost())
      } else {
        hasFetchedInitialPostDrafts.value = true
      }
    } else {
      // There are some places that rely on this state to be `true` (e.g. `SurfaceComposerModalList`).
      // To simplify things, we always populate it even if the user can't create drafts (hence we don't
      // fetch drafts for them).
      hasFetchedInitialPostDrafts.value = true
    }

    if (wallPostProperties) {
      promises.push(fetchWallPostPropertiesAndSaveToStore())
    }

    if (wallCustomPostProperties) {
      promises.push(fetchWallCustomPostPropertiesAndSaveToStore())
    }

    if (isAppUsing('commentAttachmentSettings') && commentAttachmentSettings && isCommentable.value) {
      promises.push(fetchCommentAttachmentSettingsAndSaveToStore(wallHashid.value))
    }

    if (submissionRequestLink) promises.push(fetchSubmissionRequestLink())

    /** Execute */
    void Promise.all(promises)
  }

  return { fetchInitialState }
}
